import SparkleImg from "@assets/raffle/sparkles-entry.svg";
import { Box, breakpoints, Loader, RaffleEntry, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GrayContainer } from "../../../CommunityPage/Challenges/common";
import EmptyEntries from "./EmptyEntries";

const colors = ["#8CCE5A", "#A9DCE6", "#E2BC5D", "#8DCF9F", "#ECB2AE", "#3B5277"];

const EntriesContainer = ({ raffle_id, season_id, ...props }) => {
  const { getRaffleEntries } = useStoreActions((actions) => actions.raffle);
  const { raffleEntries } = useStoreState((state) => state.raffle);
  const [loading, setLoading] = useState(true);
  const id = season_id ? { season_id } : { raffle_id };

  useEffect(() => {
    getRaffleEntries(id).finally(() => setLoading(false));
  }, [raffle_id]);

  return <Page data={raffleEntries} loading={loading} {...props} />;
};

const Page = ({ children, data, onRaffleDetails, raffleCompleted, loading }) => {
  const navigate = useNavigate();
  let content;

  const onKeepContributing = () => navigate(`/`);

  if (loading) {
    content = (
      <Box width="100%" spacing="m-a p-9+6" flex alignItems="center" justifyContent="center">
        <Loader />
      </Box>
    );
  } else {
    if (data.length === 0) {
      content = <EmptyEntries onRaffleDetails={onRaffleDetails} onKeepContributing={onKeepContributing} />;
    } else {
      const headingText = `You received ${data.length} entries for the next raffle!`;
      content = (
        <>
          <Box
            flex
            justifyContent={"space-between"}
            alignItems="center"
            spacing="mb-8"
            md-spacing="mx-6 px-0"
            sm-spacing="mb-6 px-4"
          >
            <Box direction="column" gap="16px" flex>
              <Text bold typography="heading/small" color="01Primary700" fontFamily="Outfit" sm-spacing="mr-6">
                {headingText}
              </Text>
              <Text maxWidth="929px" typography="body/medium" color="01Primary700">
                Every 5 $THRIVE you earn through your contributions during the Season earns you 1 Raffle Entry. During
                the Raffle, one entry will be randomly selected for each prize. Below is the list of your Raffle
                Entries.
              </Text>
            </Box>
          </Box>
          <WrapperTickets>
            {data.map((item, index) => {
              const colorIndex = index % colors.length;
              return (
                <RaffleEntry
                  key={item.serial_number}
                  opacity={raffleCompleted && !item.is_winning_ticket && "0.5"}
                  bgColor={colors[colorIndex]}
                  sparkle={item.is_winning_ticket && SparkleImg}
                  onRaffleDetails={onRaffleDetails}
                  {...item}
                />
              );
            })}
          </WrapperTickets>
        </>
      );
    }
  }

  return (
    <GrayContainer>
      <Wrapper color="01Primary700" sm-spacing="pt-6">
        {children}
        {content}
      </Wrapper>
    </GrayContainer>
  );
};

const Wrapper = styled(Box)`
  position: relative;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors["01Surface"]};
  padding: 60px 76px 60px 59px;
  margin: 0 auto;
  box-sizing: border-box;
  width: min(1402px, 100% - 64px);
  @media (min-width: 1400px) {
    max-width: 1402px;
  }
  @media ${breakpoints.tablet} {
    width: 100%;
    padding: 42px 24px 24px;
  }
  @media ${breakpoints.mobile} {
    border-radius: 0;
    padding: 1.5rem 0;
    width: 100%;
  }
`;

const WrapperTickets = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 85px;
  row-gap: 39px;
  padding: 0 16px;
  @media ${breakpoints.tablet} {
    padding: 0 60px;
  }
  @media ${breakpoints.mobile} {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
`;

export default EntriesContainer;

export const ICONS = ["thrive", "bankless-dao", "ape", "op-collective"];
export const NETWORKICONS = [
  "https://thrivecoin-assets.s3.amazonaws.com/images/twitter.png",
  "https://thrivecoin-assets.s3.amazonaws.com/images/discord.png",
  "https://thrivecoin-assets.s3.amazonaws.com/images/discourse.png",
  "https://thrivecoin-assets.s3.amazonaws.com/images/bankless_academy_icon.png",
];
export const categories = (source, property = "icon") => [
  {
    value: "ThriveCoin",
    label: "ThriveCoin",
    [property]: source[0],
  },
  {
    value: "BanklessDAO",
    label: "BanklessDAO",
    [property]: source[1],
  },
  {
    value: "ApeCoin",
    label: "ApeCoin",
    [property]: source[2],
  },
  {
    value: "Optimism Collective",
    label: "Optimism Collective",
    [property]: source[3],
  },
  {
    value: 5,
    label: "All Communities",
  },
];

export const networks = (source, property = "icon") => [
  {
    value: "twitter_app",
    label: "Twitter",
    [property]: source[0],
  },
  {
    value: "discord_app",
    label: "Discord",
    [property]: source[1],
  },
  {
    value: "discourse_app",
    label: "Discourse",
    [property]: source[2],
  },
  {
    value: "banklessdao_discourse_app",
    label: "Bankless DAO",
    [property]: source[3],
  },
  {
    value: 5,
    label: "All Networks",
  },
];

import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import BaseConnector from "./BaseConnector";

const KEYS_TO_REMOVE = [
  "-walletlink:https://www.walletlink.org:session:id",
  "-walletlink:https://www.walletlink.org:session:secret",
  "-walletlink:https://www.walletlink.org:session:linked",
  "-walletlink:https://www.walletlink.org:Addresses",
];

export default class Connector extends BaseConnector {
  async connect() {
    let provider;
    try {
      const walletSDK = new CoinbaseWalletSDK(this.options);
      provider = walletSDK.makeWeb3Provider(this.options.ethJsonrpcUrl, this.options.chainId);
      await provider.enable();
    } catch (e) {
      console.error(e);
      return;
    }
    // provider.connectorName = "walletlink";
    return provider;
  }

  logout() {
    if (localStorage) {
      KEYS_TO_REMOVE.forEach((key) => localStorage.removeItem(key));
    }
    return;
  }
}

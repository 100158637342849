import { Box, Button, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import { useState } from "react";
import styled from "styled-components";

const NewTagBox = ({ close, createTag }) => {
  const [tag, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    setLoading(true);
    createTag(tag)
      .then(close)
      .finally(() => setLoading(false));
  };

  const onChange = (event) => {
    event.preventDefault();
    setState(event.target.value);
  };

  return (
    <Container onChange={onChange}>
      <Box flex justifyContent="space-between" spacing="mb-4">
        <Text typography="body/medium" bold>
          Add New Tag
        </Text>
        <IconSvg icon="x" onClick={close} size="16px" />
      </Box>
      <Box flex gap="1rem">
        <Input name="tag" required width="250px" />
        <Button
          size="sm"
          typography="other/buttons"
          borderRadius="40px"
          variant="01Primary700"
          kind="outline"
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          typography="other/buttons"
          borderRadius="40px"
          onClick={onClick}
          color="01Primary0"
          disabled={!tag}
          loading={loading}
        >
          Save
        </Button>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0px 5px 45px 0px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.colors["01Primary50"]};

  ${IconSvg} {
    cursor: pointer;
  }
`;

export default NewTagBox;

import { action, computed, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";
import { denormalizeListing, denormalizeListings, normalizeListings } from "../normalizerSchema";

const requester = new HttpWrapper("listings");
const activityAppRequester = new HttpWrapper("activity_app");

const listResolverGenerator = (field) => (state, storeState) => ({ ids: state[field], entities: storeState.entities });

const ownedResolver = listResolverGenerator("ownedIDs");
const listResolver = listResolverGenerator("listIDs");
const activeListResolver = listResolverGenerator("activeIDs");
const showResolver = (state, storeState) => ({ id: state.showID, entities: storeState.entities });
const denormalizeList = ({ ids, entities }) => denormalizeListings(ids, entities) || [];
const denormalizeSingle = ({ id, entities }) => denormalizeListing(id, entities) || {};

const attributes = {
  triggerContribution: false,
  activeIDs: [],
  editID: undefined,
  source: {
    group_id: undefined,
    sourceType: undefined,
    path: undefined,
  },
  showID: undefined,
  listings: computed([listResolver], denormalizeList),
  owned: computed([ownedResolver], denormalizeList),
  listing: computed([showResolver], denormalizeSingle),
  activeListings: computed([activeListResolver], denormalizeList),
};

const thunksWithLoading = {
  makeContributionClaimRequest: thunk((actions, { id, message = null, submission_urls = [] }) => {
    const url = `activities/${id}/create_contribution_claim`;

    return activityAppRequester.post(url, { contribution_claim: { message, submission_urls } });
  }),
};

const actions = {
  setTriggerContribution: action((state, value) => {
    state.triggerContribution = value;
  }),

  cancelListingRequest: thunk((actions, id) => {
    debugger;
  }),
  findRequest: thunk(({ normalize, setProperty }, id) => {
    const url = `activities/${id}`;

    return activityAppRequester.get(url).then(({ activity }) => {
      normalize([activity]);
      setProperty({ property: "showID", value: id });
    });
  }),
  setProperty: action((state, { property, value }) => {
    state[property] = value;
  }),
  reset: action((state) => {
    state.source = {};
  }),
  getListRequest: thunk(({ normalize, setListIDs }, keyword) => {
    return requester.post("search", keyword).then((response) => {
      const ids = normalize(response.listings);
      setListIDs(ids);
    });
  }),
  getOwnedList: thunk(({ normalize, setOwnedIDs }, keyword) => {
    return requester.post("search_owned", { keyword }).then((response) => {
      const ids = normalize(response.listings);
      setOwnedIDs(ids);
    });
  }),
  setListIDs: action((state, ids) => {
    state.listIDs = ids;
  }),
  setOwnedIDs: action((state, ids) => {
    state.ownedIDs = ids;
  }),
  normalize: thunk((actions, objects, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const result = normalizeListings(objects);

    setEntities(result);

    return result.result;
  }),
  setSource: action((state, { sourceType, group_id, path }) => {
    state.source = { sourceType, group_id, path };
  }),
  createUserActivity: thunk((actions, activity) => {
    const url = "users/create_activity";
    return activityAppRequester.post(url, { activity }).then((data) => data.activity);
  }),
  createGroupActivity: thunk((actions, { group_id: id, ...activity }) => {
    const url = `groups/${id}/create_activity`;
    return activityAppRequester.post(url, { activity }).then((data) => data.activity);
  }),

  approveListing: thunk((actions, { id, txId, fromAddress, toAddress }) => {
    const url = `${id}/approve`;
    const payload = {
      tx_id: txId,
      from_address: fromAddress,
      to_address: toAddress,
    };

    return requester.post(url, payload);
  }),
  getActiveForCommunity: thunk(({ normalize, setActiveIDs }, id) => {
    const url = `community_active/${id}`;

    return requester.get(url).then(({ listings }) => {
      const ids = normalize(listings);
      setActiveIDs(ids);
    });
  }),
  setActiveIDs: action((state, ids) => {
    state.activeIDs = ids;
  }),
};

const listings = {
  ...attributes,
  ...actions,
  ...thunksWithLoading,
};

export default listings;

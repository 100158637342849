import { action } from "easy-peasy";

const attributes = {
  customTheme: false,
};

const actions = {
  setCustomTheme: action((state, value) => {
    state.customTheme = value;
  }),
};

const theme = {
  ...attributes,
  ...actions,
};

export default theme;

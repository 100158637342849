import { Box, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import debounce from "@utils/debounce";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import PendingContributionList from "./PendingContributionList";

const OPTIONS = [
  { label: "Oldest (Default)", value: "oldest" },
  { label: "Newest", value: "new" },
  { label: "Challenge Step", value: "step" },
  { label: "Username", value: "user" },
];
const DEBOUNCE_TIME = 1000;
const PendingContributions = () => {
  const { pendingContributions } = useStoreState((state) => state.pendingContributions);
  const { getPendingContributions } = useStoreActions((actions) => actions.pendingContributions);
  const { community_id } = useParams();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const goBack = () => navigate(-1);

  const debouncedSearch = useMemo(() => debounce(getPendingContributions, DEBOUNCE_TIME), []);
  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSearchInput(value);
    debouncedSearch({ community_id, searchInput: value });
  };

  useEffect(() => {
    getPendingContributions({ community_id });
  }, []);

  return (
    <Wrapper spacing="px-5" bgColor="01Primary0">
      <Box bgColor="tertiary700" spacing="p-5 mb-6" borderRadius="10px">
        <Box flex alignItems="center" pointer spacing="mb-4+1 " onClick={goBack}>
          <IconSvg icon="arrow-left" />
          <Text spacing="ml-3" bold>
            Back to Previous Page
          </Text>
        </Box>
        <Box flex={1} justifyContent="space-between">
          <Text type="h4" bold fontFamily="Outfit" color="02Primary700">
            Pending contributions
          </Text>
          <Box flex={1}>
            <Input
              placeholder="Search..."
              icon="search"
              minWidth="270px"
              spacing="mr-4"
              onChange={handleChange}
            ></Input>
            {/*{Temporary should be hidden }*/}
            {/*<SelectV2
              options={OPTIONS}
              width="159px"
              placeholder="Sort By"
            ></SelectV2>*/}
          </Box>
        </Box>
      </Box>
      <PendingContributionList searchInput={searchInput} contributions={pendingContributions} />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  margin-top: 74px;
`;

export default PendingContributions;

import { IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";

const CollapsibleIcon = ({ isActive, hideIcon }) => {
  const iconElement = hideIcon ? null : <IconSvg color="03Primary500" width="20px" icon="chevron-down" />;
  return <IconWrapper isActive={isActive}>{iconElement}</IconWrapper>;
};

const IconWrapper = styled.div`
  min-width: 31px;
  height: 31px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors["03Primary500"]};

  ${({ isActive }) =>
    isActive &&
    `
      transform: rotate(180deg);
    `}
`;

export default CollapsibleIcon;

import { action } from "easy-peasy";

const attributes = {
  hasGoogle: false,
};

const actions = {
  setGoogle: action((state, value) => {
    state.hasGoogle = value;
  }),
};

const members = {
  ...attributes,
  ...actions,
};

export default members;

import { Box, breakpoints, Button, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const TextList = ({ name, placeholder, required, value, disabled, setState, fieldName }) => {
  const list = value || [];

  const onInputChange = (e) => {
    const { value, dataset } = e.target;
    const { index } = dataset;
    const newList = [...list];

    newList[index].placeholder = value;
    setState((state) => ({ ...state, [name]: newList }));
  };

  const toggleRequired = (e) => {
    const index = +e.target.dataset.index;
    const newList = [...list];

    newList[index].required = !newList[index].required;
    setState((state) => ({ ...state, [name]: newList }));
  };

  const addItem = () => {
    const newList = [...list];
    newList.push({ key: new Date().getTime() });
    setState((state) => ({ ...state, [name]: newList }));
  };

  const removeItem = (e) => {
    const index = +e.target.dataset.index;
    const newList = [...list];

    newList.splice(index, 1);
    setState((state) => ({ ...state, [name]: newList }));
  };

  return (
    <Box flex direction="column">
      {list.map((item, index) => (
        <Wrapper key={item.id || index}>
          <Box flex spacing="mb-3">
            <RemoveBox icon="x" width="16px" data-index={index} onClick={removeItem} />
          </Box>

          <Input
            value={list[index].placeholder}
            placeholder={placeholder}
            onChange={onInputChange}
            disabled={disabled}
            data-index={index}
          />
          <Text typography="body/medium" spacing="mt-3">
            <input
              type="checkbox"
              defaultChecked={required || list[index].required}
              data-index={index}
              onChange={toggleRequired}
            />
            Make this field required
          </Text>
        </Wrapper>
      ))}
      {list.length < 4 && (
        <Button rightIcon="plus" onClick={addItem} kind="outline" borderRadius="40px" sm-spacing="mx-4">
          Add {fieldName}(s)
        </Button>
      )}
    </Box>
  );
};

const Wrapper = styled(Box)`
  position: relative;
  padding: 1rem 1rem;
  margin: 0 -1rem 1.5rem;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary200"]};

  input[type="checkbox"] {
    margin-right: 8px;
  }

  @media ${breakpoints.mobile} {
    margin: 0 1rem 1.5rem;
  }
`;

const RemoveBox = styled(IconSvg)`
  cursor: pointer;
  margin-left: auto;
`;

export default TextList;

import { action, computed, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";
import { denormalizeEntity, normalizeCollection2 } from "../normalizerSchema";

const requester = new HttpWrapper("activity_app");

const showResolver = (state, storeState) => ({ id: state.showID, entities: storeState.entities });

const attributes = {
  showID: undefined,
  triggerContribution: false,
  activity: computed([showResolver], ({ id, entities }) => {
    return denormalizeEntity(id, entities, "activity") || {};
  }),
};

const actions = {
  showRequest: thunk(({ normalize, setShowID }, id) => {
    const url = `activities/${id}`;

    return requester.get(url).then(({ activity }) => {
      const id = normalize([activity])[0];
      setShowID(id);
    });
  }),
  setShowID: action((state, id) => {
    state.showID = id;
  }),
  setTriggerContribution: action((state, value) => {
    state.triggerContribution = value;
  }),
  normalize: thunk((actions, objects, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const result = normalizeCollection2(objects, "activity");

    setEntities(result);

    return result.result;
  }),
};

const activities = {
  ...attributes,
  ...actions,
};

export default activities;

import { Box, breakpoints, Input, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import { Link, PaddedButton } from "../common";
import GoogleButton from "../GoogleButton";

const initialState = {
  username: "",
  email: "",
  password: "",
  password_confirmation: "",
  validationText: "",
};

const PASSWORDERROR =
  "Your password must contain at least 8 characters, including at least one uppercase and lowercase letter, and at least one number.";

const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

export const arePasswordsInvalid = (password, password_confirmation) => {
  if (password !== password_confirmation) {
    return "Please make sure your passwords match.";
  }

  if (!passwordRegex.test(password)) {
    return PASSWORDERROR;
  }
};

const CreateAccountStep = ({ goToStep }) => {
  const [state, setState] = useState(initialState);
  const { createAccountRequest } = useStoreActions((actions) => actions.authentication);
  const { hideNotice, setError } = useStoreActions((actions) => actions.notification);
  const code = sessionStorage.getItem("referral-code");
  const goToSignIn = () => goToStep(STEP_INDEXES.SignInStep);
  useVirtualPageView({ pathname: "/sign-up" });

  useEffect(() => {
    return hideNotice;
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    const validationText = arePasswordsInvalid(state.password, state.password_confirmation);

    if (validationText) {
      setState({
        ...state,
        validationText,
      });

      return;
    }

    createAccountRequest({ ...state, code })
      .then((data) => {
        sessionStorage.removeItem("referral-code");
        goToStep(STEP_INDEXES.VerificationStep);
      })
      .catch(({ response }) => {
        const errors = response.data.data.errors;

        if (response.data.message?.includes("You are not allowed to register")) {
          setError("You are not allowed to register!");
          return;
        }

        if (errors.email) {
          setState({ ...state, userValidation: "", emailValidation: errors.email[0] });
          return;
        }

        setState({ ...state, emailValidation: "", userValidation: errors.username[0] });
      });
  };

  const onChange = (event) => {
    const { id, value } = event.target;
    setState({ ...state, [id]: value, validationText: "", userValidation: "" });
  };

  return <Page {...state} onChange={onChange} onSubmit={onSubmit} goToSignIn={goToSignIn} />;
};

const Page = ({
  username,
  email,
  password,
  password_confirmation,
  onSubmit,
  onChange,
  validationText,
  userValidation,
  emailValidation,
}) => {
  const { isDesktop, isTablet, isMobile } = useStoreState((state) => state.media);

  return (
    <Container textAlign="center">
      <StyleHeader>Create an account to start your Challenge</StyleHeader>
      {isDesktop && <SubHeader>Without an account, we can't keep track of your Challenge progress.</SubHeader>}
      <form onSubmit={onSubmit} onChange={onChange}>
        <Box spacing={isMobile ? "mb-4" : "mb-5"} textAlign="left">
          <StyledInput
            id="username"
            placeholder="Username"
            maxLength="25"
            value={username}
            required
            invalid={userValidation}
            validationText={userValidation}
          />
        </Box>
        <Box spacing={isMobile ? "mb-4" : "mb-5"}>
          <StyledInput
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            required
            invalid={emailValidation}
            validationText={emailValidation}
          />
        </Box>
        <Box spacing={isMobile ? "mb-6" : "mb-7"}>
          <Box flex direction={!isDesktop && "column"} justifyContent="space-between">
            <Box width={isDesktop && "48%"} spacing={isTablet ? "mb-5" : isMobile && "mb-4"}>
              <StyledInput
                id="password"
                placeholder="Password"
                type="password"
                value={password}
                required
                invalid={validationText}
                validationAbsolute
              />
            </Box>
            <Box width={isDesktop && "48%"}>
              <StyledInput
                id="password_confirmation"
                placeholder="Confirm Password"
                type="password"
                value={password_confirmation}
                required
                invalid={validationText}
              />
            </Box>
          </Box>
          {validationText && (
            <Text color="#f16d67" align="left" spacing="mt-2">
              {validationText}
            </Text>
          )}
        </Box>
        <div>
          <PaddedButton type="submit" spacing="mb-4" modal={true}>
            CREATE ACCOUNT
          </PaddedButton>
        </div>
      </form>
      <div>
        <GoogleButton spacing="mb-6">
          <label>Sign In with Google</label>
        </GoogleButton>
        {!isMobile && <Terms />}
      </div>
    </Container>
  );
};

const Terms = () => {
  return (
    <Text size="14px" color="#1F2029">
      By registering, you accept our{" "}
      <Link color="primary500" semiBold to={`/terms_services`} target="_blank">
        Terms of use
      </Link>{" "}
      and{" "}
      <Link color="primary500" semiBold to={`/terms_privacy`} target="_blank">
        Privacy Policy
      </Link>
    </Text>
  );
};

const Container = styled(Box)`
  flex: 1;
  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    max-width: 426px;
  }
  @media ${breakpoints.mobile} {
    padding: 0 1rem;
  }
`;

const BorderedBox = styled(Box)`
  border-top: 1px solid #e4e4ed;
`;

const StyledInput = styled(Input)`
  input {
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    font-size: 16px;
    line-height: 18px;
    color: #1f2029;
    border-color: ${({ theme }) => theme.colors.secondary100};
    border-radius: 8px;
    padding: 19px 17px;

    &::placeholder {
      color: #8083a8;
      font-size: 16px;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  }
`;

const StyleHeader = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #1f2029;
  margin: 32px 0 10px 0;

  @media ${breakpoints.tablet} {
    margin: 0;
    margin-bottom: 32px;
  }

  @media ${breakpoints.mobile} {
    margin: 2rem 0 1.5rem;
    text-align: left;
  }
`;

const SubHeader = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary450};
  margin-bottom: 32px;
`;

const Footer = ({ goToStep }) => {
  const onClick = () => goToStep(STEP_INDEXES.SignInStep);
  return (
    <BorderedBox align="center" spacing="p-6" bgColor="tertiary50">
      Already have an account?{" "}
      <Text inline color="primary500" onClick={onClick}>
        {" "}
        Sign In
      </Text>
    </BorderedBox>
  );
};

CreateAccountStep.Footer = Footer;

export default CreateAccountStep;

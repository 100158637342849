export function removeUndefined(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.filter((value) => value !== null && value !== undefined);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (value !== null && value !== undefined) {
      acc[key] = removeUndefined(value);
    }
    return acc;
  }, {});
}

import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("raffles");
const usersRequester = new HttpWrapper("users");

const DEFAULT_PAGINATION = {
  page: 1,
  total: 1,
  per_page: 20,
};

const attributes = () => ({
  raffle: {},
  qualifiedContributors: [],
  raffleEntries: [],
  rafflePrizes: [],
  qualifiedContributorsPagination: DEFAULT_PAGINATION,
  raffleEntriesPagination: DEFAULT_PAGINATION,
});

const actions = {
  getRaffle: thunk(({ setRaffle }, { id }) => {
    return requester.get(`/${id}`).then(({ raffle }) => setRaffle(raffle));
  }),
  getQualifiedContributors: thunk(
    (
      { setQualifiedContributors, loadMoreQualifiedContributors, setQualifiedContributorsPagination },
      { id, keyword = "", page = 1 }
    ) => {
      const params = { page };
      if (keyword) {
        params.keyword = keyword;
      }
      return requester.get(`${id}/contributors`, params).then(({ contributors, pagination }) => {
        if (page > 1) {
          loadMoreQualifiedContributors(contributors);
        } else {
          setQualifiedContributors(contributors);
        }
        setQualifiedContributorsPagination(pagination);
      });
    }
  ),
  getRafflePrizes: thunk(({ setRafflePrizes }, { id }) => {
    return requester.get(`${id}/raffle_prizes`).then(({ raffle_prizes }) => setRafflePrizes(raffle_prizes));
  }),
  getRaffleEntries: thunk(({ setRaffleEntries, loadMoreRaffleEntries, setRaffleEntriesPagination }, params) => {
    return usersRequester.get(`current/raffle_entries`, params).then(({ raffle_entries, pagination }) => {
      if (params?.page > 1) {
        loadMoreRaffleEntries(raffle_entries);
      } else {
        setRaffleEntries(raffle_entries);
      }
      setRaffleEntriesPagination(pagination);
    });
  }),
  setRaffle: action((state, raffle) => {
    state.raffle = raffle;
  }),
  reset: action((state) => {
    //TOOD: only change the showID if we link everything using normalzr
    Object.assign(state, attributes());
  }),
  setQualifiedContributors: action((state, qualifiedContributors) => {
    state.qualifiedContributors = qualifiedContributors;
  }),
  loadMoreQualifiedContributors: action((state, qualifiedContributors) => {
    state.qualifiedContributors = [...state.qualifiedContributors, ...qualifiedContributors];
  }),
  setQualifiedContributorsPagination: action((state, pagination) => {
    state.qualifiedContributorsPagination = pagination;
  }),
  setRafflePrizes: action((state, rafflePrizes) => {
    state.rafflePrizes = rafflePrizes;
  }),
  setRaffleEntries: action((state, raffleEntries) => {
    state.raffleEntries = raffleEntries;
  }),
  loadMoreRaffleEntries: action((state, raffleEntries) => {
    state.raffleEntries = [...state.raffleEntries, ...raffleEntries];
  }),
  setRaffleEntriesPagination: action((state, pagination) => {
    state.raffleEntriesPagination = pagination;
  }),
};

const raffle = {
  ...attributes(),
  ...actions,
};

export default raffle;

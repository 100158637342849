import { action, thunk } from "easy-peasy";
const DEFAULT_DISMISS_TIME = 5000;
let counter = 0;

/// ///////////Actions//////////////

const actions = {
  celebratoryToast: thunk(({ showToast }, params) => {
    showToast({ ...params, type: "celebratory" });
  }),
  successToast: thunk(({ showToast }, params) => {
    showToast({ ...params, type: "success" });
  }),
  dangerToast: thunk(({ showToast }, { dismissTimer = null, ...params }) => {
    showToast({ ...params, dismissTimer, type: "danger" });
  }),
  infoToast: thunk(({ showToast }, params) => {
    showToast({ ...params, type: "info" });
  }),
  warningToast: thunk(({ showToast }, params) => {
    showToast({ ...params, type: "warning" });
  }),
  showToast: action(
    (state, { content, dismissTime = DEFAULT_DISMISS_TIME, position = "bottm-right", type = "info", ...props }) => {
      const data = { content, position, type, dismissTime, id: counter++, ...props };
      state.list.push(data);
    }
  ),
  setToasts: action((state, list) => {
    state.list = list;
  }),
  removeByID: action((state, id) => {
    state.list = state.list.filter((item) => item.id !== id);
  }),
  hideLast: action((state) => {
    state.list.pop();
  }),
  clear: action((state) => {
    state.list = [];
  }),
};

/// ///////////attributes//////////////
const attributes = {
  list: [],
};

const modalsModel = {
  ...attributes,
  ...actions,
};

export default modalsModel;

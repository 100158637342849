import { useState } from "react";
import { getInstance } from "../../utils/web3wrapper";
import useWeb3 from "./useWeb3";

const API_KEY = process.env.REACT_APP_GITCOIN_KEY;
const SCORER_ID = process.env.REACT_APP_GITCOIN_SCORER_ID;

// endpoint for submitting passport
const SUBMIT_PASSPORT_URI = "https://api.scorer.gitcoin.co/registry/submit-passport";
// endpoint for getting the signing message
const SIGNING_MESSAGE_URI = "https://api.scorer.gitcoin.co/registry/signing-message";

const headers = {
  "Content-Type": "application/json",
  "X-API-Key": API_KEY,
};

const useGitcoinScorer = (_address) => {
  const instance = getInstance() || {};
  const { loginFlow } = useWeb3();
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(0);
  const connected = instance.isConnected();
  const address = _address || instance.selectedAddress();

  const updateScore = async () => {
    setLoading(true);
    // call the API to get the signing message and the nonce
    const { message, nonce } = await getSigningMessage();
    // ask the user to sign the message
    const signature = await instance.getUserSignature(message, address);

    return _updateScore(address, signature, nonce).finally(() => setLoading(false));
  };

  const updateScoreNoSignature = async () => {
    setLoading(true);
    return _updateScore(address).finally(() => setLoading(false));
  };

  const connect = () => loginFlow();

  const reFetchScore = () => {
    return getScore(address).then((_score) => {
      const score = parseFloat(_score) || 0;
      setScore(score);
      return score;
    });
  };

  return {
    address,
    loading,
    connected,
    score,
    connect,
    updateScore,
    updateScoreNoSignature,
    reFetchScore,
  };
};

async function _updateScore(address, signature, nonce) {
  // call the API, sending the signing message, the signature, and the nonce
  const response = await fetch(SUBMIT_PASSPORT_URI, {
    method: "POST",
    headers,
    body: JSON.stringify({
      address,
      scorer_id: SCORER_ID,
      signature,
      nonce,
    }),
  });

  return await response.json();
}

async function getScore(address) {
  const GET_PASSPORT_SCORE_URI = `https://api.scorer.gitcoin.co/registry/score/${SCORER_ID}/${address}`;
  try {
    const response = await fetch(GET_PASSPORT_SCORE_URI, {
      headers,
    });
    const passportData = await response.json();
    if (passportData.score) {
      // if the user has a score, round it and set it in the local state
      const roundedScore = Math.round(passportData.score * 100) / 100;
      return roundedScore.toString();
    } else {
      // if the user has no score, display a message letting them know to submit thier passporta
      console.log("No score available, please add stamps to your passport and then resubmit.");
    }
  } catch (err) {
    console.log("error: ", err);
  }
}

async function getSigningMessage() {
  try {
    const response = await fetch(SIGNING_MESSAGE_URI, {
      headers,
    });
    const json = await response.json();
    return json;
  } catch (err) {
    console.log("error: ", err);
  }
}

export default useGitcoinScorer;

import { Box, breakpoints, Button, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useContext, useState } from "react";
import styled from "styled-components";
import StepNavigation from "../../common/StepNavigation";
import HeaderButtons from "../../CommunityEditor/Steps/common/HeaderButtons";
import { Divider } from "../common";
import { ListingEditorContext } from "../ListingEditorContext";
import NameSelectionStep from "./NameSelectionStep";
import RewardSelectionStep from "./RewardSelectionStep";
import SpreadsheetStep from "./SpreadsheetStep";
import TypeSelectionStep from "./TypeSelectionStep";

const STEPS = [TypeSelectionStep, NameSelectionStep, RewardSelectionStep, SpreadsheetStep];
const STEP_HEADERS = [
  { label: "1. Type", num: 0 },
  { label: "2. Name", num: 1 },
  { label: "3. Reward", num: 2 },
];

const STEP_HEADERS_2 = [...STEP_HEADERS, { label: "4. Spreadsheet", num: 3 }];

const Stepper = () => {
  const [index, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const StepKlass = STEPS[index];
  const {
    isEditing,
    previewMode,
    setPreviewMode,
    submit,
    isDraft,
    onChange,
    hideSpreadsheet,
    saveDraft,
    canSaveAsDraft,
  } = useContext(ListingEditorContext);
  const { isMobile } = useStoreState((state) => state.media);
  const { hideModal } = useStoreActions((actions) => actions.modals);

  const maxStep = hideSpreadsheet ? STEPS.length - 2 : STEPS.length - 1;
  const title = previewMode
    ? "Way To Contribute Preview"
    : isEditing
    ? "Edit Contribution"
    : "Create Way to Contribute";
  const stepHeaders = hideSpreadsheet ? STEP_HEADERS : STEP_HEADERS_2;
  const _canSaveDraft = !isMobile && canSaveAsDraft && !previewMode;
  const prevStep = () => setStep((step) => step - 1);

  const goToStep = (value) => () => {
    if (previewMode) {
      setPreviewMode(false);
    }
    setStep((step) => step + value);
  };

  const cancel = () => {
    hideModal();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (index < maxStep) {
      return setStep((prev) => prev + 1);
    }
    setLoading(true);
    submit().finally(() => setLoading(false));
  };

  const togglePreview = () => setPreviewMode((preview) => !preview);

  const wrapperSpacing = previewMode ? "pb-6" : "pb-7+2";
  const titleSpacing = previewMode ? "my-6" : "mb-6+2";
  const onClose = previewMode ? togglePreview : cancel;

  const buttonsProps = {
    setStep: goToStep,
    index,
    submit,
    loading,
    cancel,
    isNew: !isEditing,
    isDraft,
    prevStep,
  };

  return (
    <Wrapper preview={previewMode}>
      <form className="listing-form" onSubmit={onSubmit} onChange={onChange}>
        <Box spacing={wrapperSpacing}>
          <Box>
            <HeaderButtons
              onClickPreview={togglePreview}
              textBtn={"Preview"}
              icon={previewMode ? "chevron-up" : "chevron-down"}
              onClose={onClose}
              hidePreview={isMobile || previewMode}
              saveAsDraft={_canSaveDraft && saveDraft}
            />
            <TitleText color="01Primary700" typography="heading/medium" spacing={titleSpacing}>
              {title}
            </TitleText>

            {!previewMode && <StepNavigation index={index} steps={stepHeaders} setStep={setStep} />}
          </Box>

          {!previewMode && (
            <Box maxWidth="556px" spacing="mx-a" sm-spacing="px-4">
              <StepKlass />
              <Divider />
            </Box>
          )}
          {!previewMode && <ButtonList {...buttonsProps} maxStep={maxStep} />}
        </Box>
      </form>
    </Wrapper>
  );
};

const ButtonList = ({ index, maxStep, prevStep, cancel, isNew, loading }) => (
  <ButtonsContainer flex gap="16px" maxWidth="556px" spacing="mx-a" sm-spacing="px-4">
    {index === 0 && (
      <Button kind="outline" variant="01Primary300" bold onClick={cancel}>
        Cancel
      </Button>
    )}
    {index > 0 && (
      <Button kind="outline" variant="01Primary700" bold onClick={prevStep}>
        Back
      </Button>
    )}
    {index < maxStep && (
      <Button bold type="submit">
        Next
      </Button>
    )}
    {index == maxStep && (
      <Button loading={loading} type="submit">
        {isNew ? "Create Contribution" : "Save"}
      </Button>
    )}
  </ButtonsContainer>
);

const ButtonsContainer = styled(Box)`
  ${Button} {
    flex-grow: 1;
    border-radius: 50px;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${breakpoints.mobile} {
    top: 0;
    background: ${({ theme }) => theme.colors.background};
  }
`;

const TitleText = styled(Text)`
  text-align: center;
  margin-top: 40px;

  @media ${breakpoints.mobile} {
    margin: 30px 16px !important;
    text-align: unset;
  }
`;

export default Stepper;

import { useStoreActions } from "easy-peasy";

const useCopyToClipboard = (value, content, dismissTime = 3000) => {
  const { successToast } = useStoreActions((actions) => actions.toasts);
  return () => {
    navigator.clipboard.writeText(value);
    successToast({ content, dismissTime });
  };
};

export default useCopyToClipboard;

import UAParser from "ua-parser-js";

export const shouldDeepLink = () => {
  const hasProvider = !!window.ethereum;
  const mobileWidth = hasMobileWidth();
  const touchDevice = hasMobileTouch();
  const mobileNavigator = hasMobileNavigator();
  const mobileDevice = isMobileDevice();

  return !hasProvider && (mobileWidth || touchDevice || mobileNavigator || mobileDevice);
};

const hasMobileWidth = () => window.matchMedia("(max-width: 767px)").matches;

const hasMobileTouch = () => window.matchMedia("(pointer: coarse)").matches;

const hasMobileNavigator = () => navigator?.userAgentData?.mobile;

const isMobileDevice = () => {
  const myParser = new UAParser();
  return myParser.getDevice().type === "mobile";
};

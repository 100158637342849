import { useStoreActions } from "easy-peasy";
import styled from "styled-components";
import useConnectSocialMedias from "../../hooks/useConnectSocialMedias";
import SocialButtonCard from "./SocialButtonCard";

export const HELPER_TEXT_COLOR = ["01Primary700", "02Primary500"];

const ConnectionButton = ({
  social,
  connected,
  count,
  displayName,
  provider,
  override,
  onModal,
  isModal,
  ...props
}) => {
  const { showModal, hideModal } = useStoreActions((actions) => actions.modals);
  const connectionMethod = override || useConnectSocialMedias(true)[social];
  const onClick = () => connectionMethod(displayName, provider);
  const isDiscourse = social === "discourse";
  const action = connected ? "Disconnect" : "Connect";
  const connectionText =
    displayName === "Email" ? "Verify Email" : connected ? `${action} Network` : `${action} ${displayName}`;
  const text = isDiscourse ? `${action} Forum` : connectionText;

  const onDisconnect = () => {
    showModal({
      modalName: "DisconnectSocialProfileModal",
      hideModal,
      provider,
      name: displayName,
    });
  };

  if (!social) {
    return null;
  }

  return (
    <SocialButtonCard
      network={social}
      onModal={onModal}
      onClick={connected ? onDisconnect : onClick}
      {...props}
      count={count}
      connected={connected}
      isConnected={!!connected}
      displayName={displayName}
      isModal={isModal}
    >
      {text}
    </SocialButtonCard>
  );
};

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    svg:first-child {
      margin-right: 7px;
    }
    svg:last-child {
      width: 18px;
      margin-left: 5px;
    }

    svg {
      line-height: 14px;
    }
  }
`;

ConnectionButton.defaultProps = {
  canDisconnect: true,
  onModal: false,
};

export default ConnectionButton;

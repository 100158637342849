export const item = {
  name: "Vote on a GitCoin proposal on Snapshot",
  description:
    "Quia hic deserunt. Aspernatur vitae necessitatibus. Rerum et id. Quia hic deserunt. Aspernatur vitae necessitatibus. Rerum et id. Quia hic deserunt. Aspernatur vitae necessitatibus. Rerum et id. Quia hic deserunt. Aspernatur vitae necessitatibus. Rerum et id. Quia hic deserunt. Aspernatur vitae necessitatibus. Rerum et id. Quia hic deserunt. Aspernatur vitae necessitatibus. Rerum et id. Quia hic deserunt. Aspernatur vitae necessitatibus. Rerum et id. Quia hic deserunt. Aspernatur vitae necessitatibus. Rerum et id.",
  activities: ["Create"],
  canCompleteMultipleTimes: false,
  type: "twitter_app",
  isAuthenticated: true,
  socialProfileConnected: true,
  manuallyVerification: false,
  completed: false,
  reward: 30,
  completedCount: 0,
  contributions_per_user: 5,
  steps: 3,
  earned: 60,
  image_url: "https://thrivecoin-assets.s3.amazonaws.com/images/twitter.png",
  hide_amounts: false,
  token: "THRIVE",
  custom_url: "https://app.staging.thrivecoin.com/",
  selectedList: false,
  combinedCompleted: true,
  communityLink: "www.google.com",
  communityName: "Thrivecoin",
};

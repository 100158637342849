import { breakpoints } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";

const desktop = window.matchMedia(breakpoints.desktop);
const tablet = window.matchMedia(breakpoints.tablet);
const miniTablet = window.matchMedia(breakpoints.miniTablet);
const mobile = window.matchMedia(breakpoints.mobile);

const mapper = ([listener, callback]) => {
  const onChange = ({ matches }) => callback(matches);
  listener.addEventListener("change", onChange);

  return () => listener.removeEventListener("change", onChange);
};

const useMediaQueries = () => {
  const { setDesktop, setTablet, setMiniTablet, setMobile } = useStoreActions((actions) => actions.media);
  useEffect(() => {
    const listeners = [
      [desktop, setDesktop],
      [tablet, setTablet],
      [miniTablet, setMiniTablet],
      [mobile, setMobile],
    ];
    const subscriptions = listeners.map(mapper);

    listeners.map(([listener, callback]) => callback(listener.matches));

    return () => {
      subscriptions.map((unsubscribe) => unsubscribe());
    };
  }, []);
};

export default useMediaQueries;

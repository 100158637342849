import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("communities");

const attributes = {
  pendingContributions: [],
};

const actions = {
  getPendingContributions: thunk(({ setPendingContributions }, { community_id, searchInput }) => {
    const search = searchInput ? `?keyword=${searchInput}` : "";
    return requester
      .post(`${community_id}/pending_contribution_claims${search}`)
      .then(({ pending_contribution_claims }) => {
        setPendingContributions(pending_contribution_claims);
      });
  }),
  setPendingContributions: action((state, pendingContributions) => {
    state.pendingContributions = pendingContributions;
  }),
};

const pendingContributions = {
  ...attributes,
  ...actions,
};

export default pendingContributions;

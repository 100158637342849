import { useRef } from "react";

const getItems = (itemSelector) => [...document.querySelectorAll(itemSelector)];
const GAP_CLASS = "gap-row";

export const dragClass = "highlight";
const PADDING_TOP = 150;
const PADDING_BOTTOM = 150;

// On drag scroll, prevents page from growing with mobile safari rubber-band effect
const maxY = () => window.scrollMaxY || document.documentElement.scrollHeight - document.documentElement.clientHeight;
const VerticalMaxed = () => window.scrollY >= maxY();

let stopY = true;

const scroll = (stepX, stepY) => {
  const scrollY = document.documentElement.scrollTop || document.body.scrollTop;
  const scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
  window.scrollTo(scrollX + stepX, scrollY + stepY);

  if (!stopY) {
    setTimeout(() => scroll(stepX, stepY), 20);
  }
};

const OrderableList = ({ itemSelector, onChange }) => {
  const draggedRef = useRef();
  // Create a state variable to track the currently dragged item
  // const [draggedItem, setDraggedItem] = useState(null);

  // Handle the drag start event for an item
  const handleDragStart = (event) => {
    draggedRef.current = event.currentTarget;
    event.currentTarget.classList.add(dragClass);
  };

  // Handle the drag end event for an item, Reset the dragged item
  const handleDragEnd = (event) => {
    draggedRef.current = null;
    event.currentTarget.classList.remove(dragClass);
    stopY = true;
  };

  const handleDrag = (event) => {
    stopY = true;
    // scroll up
    if (event.clientY < PADDING_TOP) {
      stopY = false;
      scroll(0, -1);
    }

    // scroll down
    if (event.clientY > document.documentElement.clientHeight - PADDING_BOTTOM && !VerticalMaxed()) {
      stopY = false;
      scroll(0, 1);
    }
  };

  // Handle the drag over event for an item
  // Prevent default behavior to allow dropping
  const handleDragOver = (e) => e.preventDefault();

  // Handle the drag enter event for an item
  // const handleDragEnter = (event) => event.currentTarget.classList.add(dragClass);
  const handleDragEnter = (event) => {
    if (isSameIndex(draggedRef.current, event.currentTarget)) {
      return;
    }
    event.currentTarget.classList.add("active");
  };

  // Handle the drag leave event for an item
  // Unhighlight the item
  const handleDragLeave = (event) => event.currentTarget.classList.remove(dragClass);

  // Handle the drop event for an item
  const handleDrop = (event) => {
    // Prevent default behavior
    event.preventDefault();
    const item = event.currentTarget;
    const draggedItem = draggedRef.current;
    item.classList.remove("active");

    if (isSameIndex(draggedItem, item)) {
      return;
    }

    // Get the index of the dragged item and the item being dropped on
    const items = getItems(itemSelector);
    const draggedIndex = items.indexOf(draggedItem);
    let targetIndex = items.findIndex((listItem) => listItem.dataset.dragid == item.dataset.dragid);
    targetIndex = targetIndex == -1 ? items.length - 1 : targetIndex;
    let indexes = items.map((_, index) => index).filter((index) => index !== draggedIndex);
    indexes.splice(targetIndex, 0, draggedIndex);
    draggedItem.classList.remove("highlight");

    onChange(indexes);
  };

  const removeItems = () => {
    const rows = [...document.querySelectorAll(`.${GAP_CLASS}`)];
    rows.map((row) => row.remove());
  };

  const bindItems = () => {
    const items = getItems(itemSelector);
    items.forEach((item, index) => {
      const last = index + 1 == items.length;
      item.dataset.dragid = index;
      item.draggable = true;
      item.ondragstart = handleDragStart;
      item.ondragend = handleDragEnd;
      item.ondrag = handleDrag;
      item.ondragover = handleDragOver;
      item.ondragenter = handleDragEnter;
      item.ondragleave = handleDragLeave;
      item.ondrop = handleDrop;
      addGapRow(item, handleDrop, handleDragEnter, last);
    });
  };

  return { bindItems, removeItems };
};

const addGapRow = (element, handleDrop, handleDragEnter, last = false) => {
  const gapRow = buildGapRow();
  bindGap(gapRow, element.dataset.dragid, handleDragEnter, handleDrop);
  element.before(gapRow);
  if (last) {
    const lastGap = buildGapRow();
    const index = parseInt(element.dataset.dragid) + 1;
    bindGap(lastGap, index, handleDragEnter, handleDrop);
    element.after(lastGap);
  }
};

const bindGap = (element, index, handleDragEnter, handleDrop) => {
  element.dataset.dragid = index;
  element.ondrop = handleDrop;
  element.ondragenter = handleDragEnter;
  element.ondragleave = gapDragLeave;
  element.ondragover = gapDragOver;
};

const buildGapRow = () => {
  const row = document.createElement("tr");
  const td = document.createElement("td");
  td.colSpan = 7;
  row.appendChild(td);
  row.classList = GAP_CLASS;
  return row;
};

const isSameIndex = (from, target) => from.dataset.dragid === target.dataset.dragid;
const gapDragLeave = (event) => event.currentTarget.classList.remove("active");
const gapDragOver = (event) => event.preventDefault();

export default OrderableList;

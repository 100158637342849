import { Button, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const ConnectMultiple = ({ connectNetworks, connectionsCount, validCount }) => {
  return (
    <>
      <StyledButton
        size="sm"
        height="44px"
        bold
        borderRadius="50px"
        bgColor="02Primary500"
        color="02Primary0"
        hoverBackground="02Primary500"
        hoverBorderColor="02Primary500"
        hoverColor="02Primary0"
        leftIcon="chain"
        width="196px"
        onClick={connectNetworks}
      >
        Connect Your Networks
      </StyledButton>

      <StyledText color="02Primary500" size="40px" bold>
        <StyledIcon icon="chain" color="white" />
        {connectionsCount}{" "}
        <Text size="20px" alignSelf="end" inline>
          OF
        </Text>{" "}
        {validCount}
      </StyledText>
    </>
  );
};

const StyledText = styled(Text)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: "Outfit";

  svg {
    background-color: ${({ theme }) => theme.colors["hover"]};
    padding: 8px;
    border-radius: 50%;
    margin-right: 2px;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const StyledIcon = styled(IconSvg)`
  max-wdith: 40px;
  min-height: 24px;
  max-height: 40px;
`;
export default ConnectMultiple;

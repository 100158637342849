import { Accordion, Box, breakpoints, IconSvg, Panel, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { doLogout as walletLogout } from "../../utils/web3/useSetUpWeb3Provider";
import NotificationContainer from "./NotificationContainer";

import { CommunityContext } from "../CommunityContext";
import { DEFAULT_PROFILE_IMAGE } from "../Constants";
import ThemeSwitcher from "./ThemeSwitcher";

const ImageContainer = styled.div`
  width: 31px;
  height: 31px;
  border-radius: 2em;
  margin-right: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.mobile} {
    margin-right: 0;
  }
`;

const StyleImgProfile = styled.img`
  ${({ defaultImage }) =>
    defaultImage
      ? `width: 34px;
    height: 34px`
      : `width: 31px;
    height: 31px;
    border-radius: 50%`}
`;

const Title = ({ name, isDesktop, image, defaultImage }) => (
  <Box flex alignItems="center">
    <ImageContainer>
      <StyleImgProfile alt="user-profile" src={image} defaultImage={defaultImage} />
    </ImageContainer>
    {isDesktop && (
      <>
        <Text fontFamily="Proxima Nova" bold={+true} spacing="pr-2" color="01Primary700">
          {name}
        </Text>
        <IconSvg marginLeft="auto" icon="chevron-down" spacing="mr-3" color="01Primary700" />
      </>
    )}
  </Box>
);

const SwitchButton = ({ icon, selected, onClick }) => {
  return (
    <SwitchContainer flex selected={selected} pointer onClick={onClick}>
      <IconSvg icon={icon} width="16px" color={selected ? "01Primary0" : "01Primary700"} />
    </SwitchContainer>
  );
};

const HeaderProfile = () => {
  const { isCustomDomain } = useContext(CommunityContext);
  const navigate = useNavigate();
  const { isTablet, isDesktop, isMiniTablet } = useStoreState((state) => state.media);
  const { user } = useStoreState((state) => state.user);
  const { logout: apiLogout } = useStoreActions((actions) => actions.authentication);
  const { showModal } = useStoreActions((actions) => actions.modals);
  let formattedName = formatUsername(user);
  const image = user.image_url || DEFAULT_PROFILE_IMAGE;
  const title = <Title name={formattedName} isDesktop={isDesktop} image={image} defaultImage={!user.image_url} />;
  const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem("theme") || "system");

  const profileModal = () => {
    showModal({ modalName: "ManageUserWalletsModal" });
  };

  const goToProfile = () => {
    navigate(`../../current_user/profile`);
  };

  const logout = () => apiLogout().then(walletLogout);
  const getTheme = () => {
    setSelectedTheme(localStorage.getItem("theme"));
  };

  useEffect(() => {
    window.addEventListener("theme-change", getTheme);
    return () => {
      window.removeEventListener("theme-change", getTheme);
    };
  }, []);

  return (
    <StyledBox flex={+true} spacing="ml-a" borderradius="8px" alignItems="center" color="white">
      {(isTablet || isDesktop) && !isMiniTablet && <ThemeSwitcher />}
      <Accordion hideLine={true} hideIcon={true} title={title}>
        <Container shadows bgColor="01Primary0" width="225px">
          {/*
          <MenuOption
            spacing="p-4"
            icon="wallet"
            text="Manage wallets"
            onClick={profileModal}
          />
          */}
          <MenuOption alignItems="center" color="01Primary700" spacing="p-4" text="Profile" onClick={goToProfile} />
          <MenuOption alignItems="center" color="error" spacing="p-4" icon="log-out" text="Sign out" onClick={logout} />
        </Container>
      </Accordion>

      {!isCustomDomain && <NotificationContainer />}
    </StyledBox>
  );
};

const MenuOption = styled(({ icon, text, onClick, color, ...props }) => (
  <Box {...props} color="02Primary700" justifyContent="space-between" flex={+true} pointer onClick={onClick}>
    <Text bold lineHeight="24px" size="16px" color={color}>
      {text}
    </Text>
    {icon && <IconSvg color={color} icon={icon} />}
  </Box>
))`
  &:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  }

  &:hover {
    font-weight: 700;
    background: ${({ theme }) => theme.colors["01Primary100"]};
  }
`;

const formatUsername = ({ username, address }) => {
  if (address && username === address) return truncateAddress(address);

  if (username?.length > 20) {
    return username.substr(0, 17) + "...";
  }

  return username;
};

const truncateAddress = (address) => {
  const leftName = address.substring(0, 5);
  const rightName = address.substring(address.length - 4, address.length);
  return leftName + "..." + rightName;
};

const StyledBox = styled(Box)`
  position: relative;

  .accordion-title {
    flex-wrap: wrap;
    align-items: center;
    padding: 1px;
    @media ${breakpoints.tablet} {
      display: inline-block;
    }
    @media ${breakpoints.mobile} {
      display: inline-block;
      margin-right: 8px;
    }
  }
`;

const Container = styled(Panel)`
  position: absolute;
  padding: 1px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
  margin-top: 10px;
  right: 0;
  z-index: 1;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
  }

  &::before {
    right: 20px;
    top: -12px;
    border: 6px solid;
    border-color: transparent transparent ${({ theme }) => theme.colors["01Primary100"]}; transparent;
  }

  &::after {
    right: 21px;
	  top: -10px;
	  border: 5px solid;
  	border-color: transparent transparent ${({ theme }) => theme.colors["01Primary0"]} transparent;
  }
`;

const SwitchContainer = styled(Box)`
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme, selected }) => theme.colors[selected ? "03Primary500" : "01Primary300"]};
  border-radius: 50%;
  ${({ theme, selected }) => selected && `background-color: ${theme.colors["03Primary500"]};`}

  &:hover {
    ${({ theme, selected }) => !selected && `background-color:  ${theme.colors["01Primary100"]};`}
  }
`;

HeaderProfile.defaultProps = {
  name: "Jonathan Doe",
};

export default HeaderProfile;

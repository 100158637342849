import { useStoreActions, useStoreState } from "easy-peasy";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import humanize from "../../utils/humanize";
import { DEFAULT_MEDIA, DEFAULT_RAFFLE, DEFAULT_SEASON } from "./constants";

const DETAILS_REQUIRED = ["title", "subtitle", "description", "goal", "order_number", "slug"];
const DETAILS_FIELDS = [...DETAILS_REQUIRED, "image", "community_id", "label"];
const OVERVIEW_FIELDS = ["start_date", "end_date", "draw_date", "prizes_list"];

const validateDetails = (data, isPreview) => {
  const errors = {};

  for (const validation of DETAILS_REQUIRED) {
    if (!data[validation]) {
      errors[validation] = [`${humanize(validation)} is required!`];
    }
  }

  if (!isPreview && !data.community_id) {
    errors.community_id = ["Community is required!"];
  }

  return errors;
};

const validateOverview = (data) => {
  for (const prize of data.prizes) {
    if (!prize.text) {
      return false;
    }
  }

  return true;
};

const validatePrizes = (data) => {
  if (data.reward_system === "raffle") {
    for (const prize of data.raffle_prizes) {
      if (!prize.name) {
        return false;
      }
    }
  }
  return true;
};

const isValid = (data, setErrorValues, isPreview) => {
  const detailsErrors = validateDetails(data, isPreview);
  const overviewValidation = validateOverview(data);
  const prizesValidation = validatePrizes(data);
  const errors = { ...detailsErrors };

  if (!overviewValidation) {
    errors.prizes_list = { text: ["Prize text is required!"] };
  }

  if (!prizesValidation) {
    errors.raffle = { name: ["Prize name is required!"] };
  }

  setErrorValues(errors);
  return Object.keys(errors).length === 0;
};

export const SeasonEditorContext = createContext({
  currentStep: 0,
  isEditing: false,
  previewMode: false,
  season: { ...DEFAULT_SEASON, ...DEFAULT_MEDIA },
  submitLoading: false,
  errorValues: null,
  loadingData: false,
  canSaveAsDraft: false,

  submitSeason: () => {},
  setCurrentStep: () => {},
  setErrorValues: () => {},
  setPreviewMode: () => {},
  toggleRaffle: () => {},
  updateSeason: () => {},
  saveAsDraft: async () => {},
  hasPendingChanges: () => {},
  onClose: () => {},
  isValid: () => {},
});

export const SeasonEditorProvider = ({ children, edit, communityID, seasonID }) => {
  const id = seasonID;
  const navigate = useNavigate();
  const [previewMode, setPreviewMode] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorValues, setErrorValues] = useState(null);
  const [season, setSeason] = useState(DEFAULT_SEASON);
  const [originalState, setOriginalState] = useState(DEFAULT_SEASON);
  const [loadingData, setLoadingData] = useState(false);
  const { getManagedCommunities } = useStoreActions((actions) => actions.community);
  const { createSeason, editSeason, getSeasonToEdit, normalize, setShowID, createDraftSeason } = useStoreActions(
    (actions) => actions.season
  );
  const { successToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const raffleEnabled = Boolean(season.raffle);
  const canSaveAsDraft = !id;
  const { communities } = useStoreState((state) => state.entities);
  const { showModal, hideModal } = useStoreActions((actions) => actions.modals);
  const slugOrID = season.slug || season.id;
  const listPath = ["/manage/communities", season.community_id].filter((x) => x).join("/");
  const showPath = listPath;
  const onEdit = () => showModal({ modalName: "SeasonEditorModal", seasonID: slugOrID, edit: true });

  const updateStoreSeason = (data) => {
    const _id = id || "preview";
    normalize({ data: [{ image_url: null, ...data, id: _id, isPreview: true }], entityName: "season", id: _id });
    setShowID(_id);
  };

  const updateSeason = (key, value) => {
    const cloneSeason = { ...season };
    cloneSeason[key] = value;
    setSeason(cloneSeason);
    updateStoreSeason(cloneSeason);
  };

  const reward_system = raffleEnabled ? "raffle" : "custom_token";

  const seasonData = {
    ...season,
    reward_system,
  };

  const onError = ({ response }) => {
    const content = response?.data?.data?.errors || response?.data?.message || "Something went wrong!";
    setErrorValues(content);
    let message = content;
    if (typeof content === "object") {
      message = Object.values(content)[0];
    }
    dangerToast({ content: message, dismissTime: 5000 });
  };

  const onSubmitResponse = (props) => {
    successToast({ content: props.message, timeout: 3000 });
    hideModal();
    navigate(listPath, { replace: true });
  };

  const getData = () => {
    const data = { ...seasonData };

    if (!seasonData.image) {
      delete data.image;
    }
    if (!seasonData.featured_image) {
      delete data.featured_image;
    }
    return data;
  };

  const submitSeason = async () => {
    const valid = isValid(seasonData, setErrorValues, edit);
    const data = getData();
    if (valid) {
      setSubmitLoading(true);
      if (edit) {
        editSeason({ ...data, id })
          .then(onSubmitResponse)
          .catch(onError)
          .finally(() => setSubmitLoading(false));
      } else {
        createSeason(data)
          .then(onSubmitResponse)
          .catch(onError)
          .finally(() => setSubmitLoading(false));
      }
    } else {
      if (previewMode) {
        setPreviewMode(false);
      }
    }
  };

  const toggleRaffle = () => {
    const updated = { ...season, raffle: season.raffle ? undefined : { ...DEFAULT_RAFFLE } };
    setSeason(updated);
  };

  const saveAsDraft = async () => {
    const valid = isValid(seasonData, setErrorValues, edit);
    const data = getData();

    if (valid) {
      setSubmitLoading(true);

      return createDraftSeason(data)
        .then(() => {
          const community = communities[data.community_id];
          successToast({
            content: `This season has been saved as a draft on the Manage page under "${community.name}" Community.`,
            timeout: 3000,
          });
          onEdit();
        })
        .catch(onError)
        .finally(() => setSubmitLoading(false));
    }
  };

  const hasPendingChanges = () => JSON.stringify(originalState) !== JSON.stringify(season);

  const _isValid = () => isValid(seasonData, setErrorValues, edit);

  const onClose = () => {
    if (hasPendingChanges()) {
      const filledRequired = _isValid();
      return showModal({
        modalName: "BeforeYouLeaveSeasonModal",
        filledRequired,
        onSubmit: canSaveAsDraft ? saveAsDraft : submitSeason,
        listPath,
        showPath,
      });
    }

    return hideModal();
  };

  useEffect(() => {
    if (communityID) {
      updateSeason("community_id", communityID);
    }
  }, [communityID]);

  useEffect(() => {
    getManagedCommunities(["active", "draft"]);
    updateStoreSeason(seasonData);
  }, []);

  useEffect(() => {
    updateStoreSeason(seasonData);
  }, [season, reward_system]);

  useEffect(() => {
    if (!!errorValues && typeof errorValues === "object") {
      let errFound = false;
      for (const field of DETAILS_FIELDS) {
        if (Object.prototype.hasOwnProperty.call(errorValues, field)) {
          setCurrentStep(0);
          errFound = true;
          break;
        }
      }
      if (!errFound) {
        for (const field of OVERVIEW_FIELDS) {
          if (Object.prototype.hasOwnProperty.call(errorValues, field)) {
            setCurrentStep(2);
            errFound = true;
            break;
          }
        }
      }
      if (!errFound) {
        if (Object.prototype.hasOwnProperty.call(errorValues, "raffle")) {
          setCurrentStep(3);
          errFound = true;
        }
      }
    }
  }, [errorValues]);

  useEffect(() => {
    if (id) {
      setLoadingData(true);
      getSeasonToEdit(id)
        .then((response) => {
          const _season = response.season;
          if (_season.prizes === null) {
            _season.prizes = [];
          }
          setSeason(_season);
          setOriginalState(_season);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            dangerToast({ content: e.response.data.message });
          }
          navigate("/manage/communities");
        })
        .finally(() => {
          setLoadingData(false);
        });
    }
  }, [id]);

  return (
    <SeasonEditorContext.Provider
      value={{
        currentStep,
        isEditing: edit,
        errorValues,
        loadingData,
        previewMode,
        raffleEnabled,
        season: seasonData,
        submitLoading,
        submitSeason,
        setCurrentStep,
        setSeason,
        setPreviewMode,
        toggleRaffle,
        updateSeason,
        saveAsDraft,
        setErrorValues,
        canSaveAsDraft,
        hasPendingChanges,
        isValid: _isValid,
        onClose,
      }}
    >
      {children}
    </SeasonEditorContext.Provider>
  );
};

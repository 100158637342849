import DefaultFeatured from "@assets/dashboard/SeasonFeaturedBlockchain.svg";
import TabWrapper from "@common/TabWrapper";
import { Box, CommunityPanelCard, Loader, SeasonPanelCard, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EmptyContent from "../EmptyContent";

const SectionTitle = ({ text }) => (
  <Text typography="heading/small" spacing="mb-6">
    {text}
  </Text>
);

const MyActivity = ({ setTabIndex }) => {
  const navigate = useNavigate();
  const { contributedTo } = useStoreState((state) => state.community);
  const { seasonsContributedTo } = useStoreState((state) => state.season);
  const { getContributedTo } = useStoreActions((actions) => actions.community);
  const { getSeasonsContributedTo } = useStoreActions((actions) => actions.season);
  const [fetched, setFetched] = useState(false);
  const goToWays = () => setTabIndex(1);
  let content = (
    <Box width="100%" spacing="m-a p-7" flex alignItems="center" justifyContent="center">
      <Loader />
    </Box>
  );

  if (fetched) {
    if (contributedTo.length === 0) {
      content = (
        <EmptyContent
          title="No activity yet..."
          text="Don’t be shy -- start contributing to Communities you love!"
          btnText="Find Contribution Opportunities "
          onClick={goToWays}
        />
      );
    } else {
      content = (
        <Box>
          {seasonsContributedTo.length > 0 && <Line height="1px" bgColor="01Primary300" />}
          <SectionTitle text="Community Contributions" />
          <ResponsiveMasonry columnsCountBreakPoints={{ 1338: 1, 1339: 2 }}>
            <Masonry gutter="24px">
              {contributedTo.map((item) => (
                <Box key={item.id}>
                  <CommunityPanelCard
                    {...item}
                    items={item.recent_listings}
                    cover_media_url=""
                    image_url=""
                    currentUserStats
                    onStart={() => navigate(`communities/${item.slug || item.id}`)}
                  />
                </Box>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </Box>
      );
    }
  }

  useEffect(() => {
    getContributedTo().finally(() => setFetched(true));
    getSeasonsContributedTo();
  }, []);

  return (
    <TabWrapper>
      {seasonsContributedTo.length > 0 && (
        <Box>
          <SectionTitle text="Season Contributions" />
          {seasonsContributedTo.map((item) => (
            <StyledBox key={item.id}>
              <SeasonPanelCard
                {...item}
                items={item.recent_listings}
                end_date={moment(item.end_date).format("MMM Do, YYYY")}
                missionCard
                currentUserStats
                season_number={item.order_number}
                featured_image_url={item.featured_image_url || DefaultFeatured}
                onStart={() => navigate(`seasons/${item.slug || item.id}`)}
              />
            </StyledBox>
          ))}
        </Box>
      )}
      {content}
    </TabWrapper>
  );
};

const Line = styled(Box)`
  margin: 32px 0;
`;

const StyledBox = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export default MyActivity;

const attributes = {
  groupWalletType: "virtual",
};

const actions = {};

const preferenaces = {
  ...attributes,
  ...actions,
};

export default preferenaces;

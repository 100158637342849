import { SearchInput } from "@thrivecoin/ui-components";
import debounce from "@utils/debounce";

const DEBOUNCE_TIME = 3000;

//TODO: this still fires multiple requests https://i.imgur.com/hlf6KhV.png

const DebouncedSearchInput = ({ onChange, dtime = DEBOUNCE_TIME, ...props }) => {
  const _onChange = onChange ? debounce(onChange, dtime) : null;

  return <SearchInput {...props} onChange={_onChange} />;
};

export default DebouncedSearchInput;

const ALL_SORT_OPTIONS = [
  {
    label: "Community Name (A-Z)",
    value: "community",
    direction: "desc",
    for: ["raffle", "profile_contributions"],
  },
  {
    label: "Community Name (Z-A)",
    value: "community",
    direction: "desc",
    for: ["raffle", "profile_contributions"],
  },
  {
    label: "Contribution Name (A-Z)",
    value: "title",
    direction: "asc",
    for: ["contribution", "profile_contributions", "season_contribution"],
  },
  {
    label: "Contribution Name (Z-A)",
    value: "title",
    direction: "desc",
    for: ["contribution", "profile_contributions", "season_contribution"],
  },
  {
    label: "# of Contributions (highest to lowest)",
    value: "count",
    direction: "desc",
    for: ["contributor", "season_contribution", "default"],
  },
  {
    label: "# of Contributions (lowest to highest)",
    value: "count",
    direction: "asc",
    for: ["contributor", "season_contribution", "default"],
  },
  {
    label: "# of Contributions (highest to lowest)",
    value: "contribution",
    direction: "desc",
    for: [],
  },
  {
    label: "# of Contributions (lowest to highest)",
    value: "contribution",
    direction: "asc",
    for: [],
  },
  {
    label: "Contributor (A-Z)",
    value: "contributor",
    direction: "asc",
    for: ["contribution", "season_contribution"],
  },
  {
    label: "Contributor (Z-A)",
    value: "contributor",
    direction: "desc",
    for: ["contribution", "season_contribution"],
  },
  {
    label: "Date (newest to oldest)",
    value: "date",
    direction: "desc",
    for: ["contribution", "profile_contributions", "default"],
  },
  {
    label: "Date (oldest to newest)",
    value: "date",
    direction: "asc",
    for: ["contribution", "profile_contributions", "default"],
  },
  {
    label: "Reward (highest to lowest)",
    value: "reward",
    direction: "desc",
    for: ["contribution", "profile_contributions", "listing", "default"],
  },
  {
    label: "Reward (lowest to highest)",
    value: "reward",
    direction: "asc",
    for: ["contribution", "profile_contributions", "listing", "default"],
  },
  {
    label: "Rewards (highest to lowest)",
    value: "amount",
    direction: "desc",
    for: ["contributor", "season_contribution", "default"],
  },
  {
    label: "Rewards (lowest to highest)",
    value: "amount",
    direction: "asc",
    for: ["contributor", "season_contribution", "default"],
  },
  {
    label: "Season (A-Z)",
    value: "season",
    direction: "asc",
    for: ["raffle"],
  },
  {
    label: "Serial Number (lowest to highest)",
    value: "serial_number",
    direction: "asc",
    for: ["raffle"],
  },
];

const forFilter = (modelName) => (item) => item.for.includes(modelName);

export const DEFAULT_SORT_OPTIONS = ALL_SORT_OPTIONS.filter(forFilter("default"));
export const RAFFLE_SORT_OPTIONS = ALL_SORT_OPTIONS.filter(forFilter("raffle"));
export const CONTRIBUTOR_SORT_OPTIONS = ALL_SORT_OPTIONS.filter(forFilter("contributor"));
export const CONTRIBUTION_SORT_OPTIONS = ALL_SORT_OPTIONS.filter(forFilter("contribution"));
export const PROFILE_CONTRIBUTON_OPTIONS = ALL_SORT_OPTIONS.filter(forFilter("profile_contributions"));
export const LISTING_SORT_OPTIONS = ALL_SORT_OPTIONS.filter(forFilter("listing"));
export const SEASON_CONTRIBUTION_SORT_OPTIONS = ALL_SORT_OPTIONS.filter(forFilter("season_contribution"));

import { Box, breakpoints, Button, Countdown, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import styled from "styled-components";
import useRewardsContract, { removePendingTransaction } from "../../../hooks/useRewardsContract";

const ClaimingOpportunity = ({ season }) => {
  const { getSeasonDetails, claimRewardRequest } = useStoreActions((actions) => actions.season);

  const claimEarnings = (tx_id) => {
    return claimRewardRequest({ id: season.id, tx_id }).then(() => {
      removePendingTransaction(season);
      return getSeasonDetails(season.id);
    });
  };

  const { deadline, contract_address } = season.reward_claiming_config || {};
  const { claimReward, processing, isPending } = useRewardsContract(season, claimEarnings);

  const alertText = processing
    ? "Please don't close the page until the transaction proceed!"
    : "Unclaimed earnings are auto-donated.";

  return (
    <>
      <Box>
        {!processing && deadline && (
          <CountdownWrapper bgColor="headerBackground">
            <Box flex alignItems="center">
              <IconSvg icon="pacman" width="18px" color="02Primary0" />
              <Text typography="body/small" bold color="02Primary0" spacing="ml-2 mr-4">
                Claim within
              </Text>
            </Box>
            <Countdown color="02Primary500" bgColor="02Primary0" countdown={deadline} />
          </CountdownWrapper>
        )}
        <Box flex gap="8px" spacing={`mt-2 ${!processing && "ml-3"}`} color="error">
          <IconSvg width="16px" icon="alert-triangle" height="21px" />
          <Text typography="body/small" bold>
            {alertText}
          </Text>
        </Box>
      </Box>
      {processing ? (
        <Text bold color="02Primary0" sm-spacing="mx-a">
          Transaction in progress...
        </Text>
      ) : (
        <StyledButton
          color="02Primary500"
          hoverBackground="02Primary0"
          variant="02Primary0"
          borderColor="02Primary0"
          borderRadius="40px"
          height="54px"
          onClick={claimReward}
          contributed
          disabled={!contract_address || isPending}
        >
          Claim My Earnings
        </StyledButton>
      )}
    </>
  );
};

const CountdownWrapper = styled(Box)`
  border-radius: 10px;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 12px;
  width: 439px;

  @media ${breakpoints.mobile} {
    width: unset;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: unset;
    height: unset;
    padding: 8px 4px 10px;
    gap: 10px;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

export default ClaimingOpportunity;

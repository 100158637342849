import { action, computed, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";
import { normalizeCollection2 } from "../normalizerSchema";
const requester = new HttpWrapper("tags");

const attributes = () => ({
  byCommunity: {},
});

const ByCommunityResolver = (state, storeState) => {
  return {
    tags: storeState.entities.tags,
    byCommunity: state.byCommunity,
  };
};

const computedProps = {
  byCommunityID: computed([ByCommunityResolver], ({ tags, byCommunity }) => {
    return (id) => {
      const ids = byCommunity[id] || [];
      return ids.map((id) => tags[id]);
    };
  }),
};

const actions = {
  getCommunityTagsRequest: thunk(({ normalize, setCommunityTags }, id) => {
    const type = "community_tag";
    return requester.get("", { community_id: id, type }).then(({ tags }) => {
      const tagIDs = normalize({ data: tags, entityName: "tag" });
      setCommunityTags({ id, tagIDs });
    });
  }),
  normalize: thunk((actions, { data, entityName }, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const result = normalizeCollection2(data, entityName);
    setEntities(result);

    return result.result;
  }),
  setCommunityTags: action((state, { id, tagIDs }) => {
    state.byCommunity[id] = tagIDs;
  }),
};

const community = {
  ...attributes(),
  ...computedProps,
  ...actions,
};

export default community;

import { createGlobalStyle } from "styled-components";
import HeeboBold from "../fonts/Heebo-Bold.ttf";
import OutfitBold from "../fonts/Outfit-Bold.ttf";
import OutfitRegular from "../fonts/Outfit-Regular.ttf";
import OutfitSemiBold from "../fonts/Outfit-SemiBold.ttf";
import ProximaNovaBold from "../fonts/ProximaNova/ProximaNova-Bold.otf";
import ProximaNovaRegular from "../fonts/ProximaNova/ProximaNova-Regular.otf";
import ProximaNovaSemiBold from "../fonts/ProximaNova/ProximaNova-Semibold.otf";

import COVER_IMAGE from "@assets/dashboard/footer_cover.svg";
import COVER_IMAGE_DARK from "@assets/dashboard/footer_cover_dark.svg";
import CloudGray from "@assets/dashboard/gray-cloud.svg";
import STARS from "@assets/dashboard/stars.svg";
import { breakpoints } from "@thrivecoin/ui-components";

export default createGlobalStyle`
  :root {
    --bgImage: url(${({ theme }) => (theme.themeName == "light" ? COVER_IMAGE : COVER_IMAGE_DARK)});
  }

  @font-face {
    font-family: Proxima Nova;
    src: url(${ProximaNovaRegular}) format('truetype');
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: Proxima Nova;
    src: url(${ProximaNovaBold}) format('truetype');
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: Proxima Nova;
    src: url(${ProximaNovaSemiBold}) format('truetype');
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: Outfit;
    src: url(${OutfitRegular}) format('truetype');
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: Outfit;
    src: url(${OutfitSemiBold}) format('truetype');
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: Outfit;
    src: url(${OutfitBold}) format('truetype');
    font-weight: 700;
    font-display: swap;
  }


  @font-face {
    font-family: Heebo;
    src: url(${HeeboBold}) format('truetype');
    font-weight: bold;
    font-display: swap;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    color: ${({ theme }) => theme.colors["01Primary700"]};
    background: ${({ theme }) => theme.colors.background};
    font-family: 'Proxima Nova';
  }

  body > * {
    line-height: 1.5em;
  }

  input, textarea, select, option{
    font-family: 'Proxima Nova';
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors["01Link"]}
  }

  #root{
    min-height: 100%;
    display: flex;
    flex-direction: column;

  }

  .background-container{
    background-image: var(--bgImage), url(${STARS}), url(${CloudGray});
    background-repeat: no-repeat, repeat, no-repeat, repeat;
    background-position: left bottom, center top, 100% 25%,  center top;
    background-size: contain, contain, 250px 150px;
    background-color:  ${({ theme }) => theme.colors.background};
    padding-bottom: 691px;

    @media ${breakpoints.tablet} {
      padding-bottom: 600px;
    }

    @media ${breakpoints.miniTablet} {
      background-size: 1440px, contain, 250px, 150px;
      background-position: center bottom,center top,100% 25%,center top;
    }

    @media ${breakpoints.mobile} {
      padding-bottom: 350px;
      background-size: 907px, contain, 250px, 150px;
      background-position: center bottom,center top,100% 25%,center top;
    }
  }

  wcm-modal{
    position: absolute;
    z-index: 10000;
  }
`;

import { useParams } from "react-router-dom";

const generator =
  (flow, page) =>
  ({ community_id, listing_id, challenge_id }) =>
    `/communities/${community_id}/${flow}/${challenge_id}/${listing_id}/${page}`;

// Completed pages
export const stepCompleted = generator("complete", "step_completed");
export const challengeCompleted = generator("complete", "challenge_completed");

// Activity pages
export const activityContribute = generator("activity", "contribute");
export const activityInstruction = generator("activity", "instruction");
export const activityReview = generator("activity", "review");
export const activityReward = generator("activity", "reward");

// Challenge pages

export const challengeVideo = generator("challenge", "video");
export const challengeQuiz = generator("challenge", "quiz");
export const challengeReward = generator("challenge", "reward");

//Hook helper
export const useUrlHelper = () => {
  const params = useParams();

  return {
    challenges: () => {
      return {
        challengeVideo: challengeVideo(params),
        challengeQuiz: challengeQuiz(params),
        challengeReward: challengeReward(params),
      };
    },
    activity: () => {
      return {
        activityContribute: activityContribute(params),
        activityInstruction: activityInstruction(params),
        activityReview: activityReview(params),
        activityReward: activityReward(params),
      };
    },
    completed: () => {
      return {
        stepCompleted: stepCompleted(params),
        challengeCompleted: challengeCompleted(params),
        activityCompleted: activityReward(params),
      };
    },
  };
};

export const referralLink = (code) => `${window.location.origin}/referred/${code}`;

import debounce from "@utils/debounce";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const SORT_DIRECTIONS = ["asc", "desc"];
const DEBOUNCE_TIME = 1000;

const useSort = (sortMethod, initialSort, idProperty = "id", noQueryParam) => {
  const id = noQueryParam ? noQueryParam : useParams()[idProperty];
  const [sortType, setSortType] = useState(initialSort);
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const onSort = (e) => {
    const type = e.target.dataset.name;
    const _sortType = e.target.dataset.sorttype || e.target.dataset.name;

    setLoading(true);
    if (sortType === _sortType) {
      const direction = SORT_DIRECTIONS.find((d) => d !== sortDirection);
      setSortDirection(direction);
      sortMethod({ [idProperty]: id, sort_type: type, sort_direction: direction }).finally(() => setLoading(false));
    } else {
      setSortType(_sortType);
      setSortDirection("desc");
      sortMethod({ [idProperty]: id, sort_type: type, sort_direction: "desc" }).finally(() => setLoading(false));
    }
  };

  const onLoadMore = (page) =>
    sortMethod({
      [idProperty]: id,
      keyword: searchValue,
      sort_type: sortType,
      sort_direction: sortDirection,
      page,
    });

  const onFilterApply = (search, sort) => {
    setSearchValue(search);
    setSortType(sort.value);
    setSortDirection(sort.direction);
    sortMethod({ [idProperty]: id, keyword: search, sort_type: sort.value, sort_direction: sort.direction });
  };

  const onChange = (keyword) => {
    if (!keyword || (keyword && keyword.length >= 3)) {
      setSearchValue(keyword);
      setLoading(true);
      sortMethod({ [idProperty]: id, keyword: keyword }).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    sortMethod({ [idProperty]: id }).finally(() => setLoading(false));
  }, []);

  return {
    sortMethod,
    onSort,
    loading,
    sortType,
    searchValue,
    sortDirection,
    onFilterApply,
    onLoadMore,
    setLoading,
    onChange: debounce(onChange, DEBOUNCE_TIME),
  };
};

export default useSort;

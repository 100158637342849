import Cloud from "@assets/cloud.png";
import CloudTransparent from "@assets/cloud_transparent.png";
import Logo from "@assets/logo.png";
import { Box, Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { STEP_INDEXES } from ".";

const MobileStartStep = ({ goToStep }) => {
  const goToSignIn = () => goToStep(STEP_INDEXES.SignInStep);
  const goToSignUp = () => goToStep(STEP_INDEXES.CreateAccountStep);

  return (
    <Container>
      <Text size="28px" bold textAlign="left" spacing="mb-5">
        Sign Up / Sign In
      </Text>
      <Text textAlign="left" color="secondary400" spacing="mb-7">
        Earn crypto for contributing to the communities you love! Sign Up Sign In
      </Text>
      <Button height="53px" bgColor="success500" borderRadius="40px" spacing="mb-4" onClick={goToSignUp} modal={true}>
        Sign Up
      </Button>
      <Button height="53px" bgColor="white" color="secondary400" borderRadius="40px" onClick={goToSignIn} modal={true}>
        Sign In
      </Button>
      <LogoImage src={Logo} />
      <Image src={Cloud} />
      <Image2 src={CloudTransparent} />
    </Container>
  );
};

const Container = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f7f2eb;
  padding: 5.5rem 1rem 0;
`;

const LogoImage = styled.img`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

const Image2 = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default MobileStartStep;

import { useParams } from "react-router";
import EntriesContainer from "../../Season/BodySeason/RaffleEntries/EntriesContainer";

const RaffleEntriesTab = ({ raffleCompleted }) => {
  const { id } = useParams();

  return <EntriesContainer raffle_id={id} raffleCompleted={raffleCompleted}></EntriesContainer>;
};

export default RaffleEntriesTab;

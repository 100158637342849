import IMAGE from "@assets/personal-legend-banner.png";
import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";

const HeroBanner = () => {
  const { isDesktop } = useStoreState((state) => state.media);

  return (
    <Wrapper relative maxHeight="190px" bgColor="#7EC0CE" flex direction="column">
      {isDesktop && <StyledImage src={IMAGE} />}
      <Content spacing="m-a">
        <Text bold size="34px" lHeight="22px" color="01Primary0" fontFamily="Outfit">
          My Contributions
        </Text>
        {isDesktop && (
          <Text bold spacing="mt-4" color="02Primary700">
            See which contributions you’ve completed.
          </Text>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  height: 190px;

  @media ${breakpoints.mobile} {
    height: 104px;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  top: 30px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 160px;
  width: 100%;
`;

const Content = styled(Box)`
  z-index: 1;
  width: min(1302px, 100% - 32px);

  @media ${breakpoints.tablet} {
    width: auto;
  }

  @media ${breakpoints.mobile} {
    width: auto;
  }
`;

export default HeroBanner;

import DebouncedSearchInput from "@common/DebouncedSearchInput";
import { Box, breakpoints, Loader, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import LoadMore from "../../common/LoadMore";
import ReferredUser from "./ReferredUser";

const MyReferrals = () => {
  const { getReferredUsers } = useStoreActions((actions) => actions.user);
  const { referredUsers, referredUsersPagination } = useStoreState((state) => state.user);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const _renderUsers = referredUsers.map((item) => <ReferredUser key={item.id} {...item} />);

  const content = () => (
    <>
      <Box flex alignItems="flex-end" justifyContent="space-between" spacing="mb-6">
        <Text bold typography="body/large">
          You currently have {referredUsersPagination.total} referrals
        </Text>
        <DebouncedSearchInput onChange={onSearch} />
      </Box>
      <UsersList>{_renderUsers}</UsersList>
    </>
  );

  const loadMore = () => getReferredUsers({ keyword: search, page: +referredUsersPagination.page + 1 });

  const onSearch = (val) => {
    setLoading(true);
    getReferredUsers({ keyword: val, page: referredUsersPagination.page }).finally(() => setLoading(false));
    setSearch(val);
  };

  useEffect(() => {
    setLoading(true);
    getReferredUsers({ keyword: search, page: 1 }).finally(() => setLoading(false));
  }, []);

  return (
    <Wrapper>
      <Text typography="heading/large" spacing="mb-4">
        My Referrals
      </Text>
      <Box maxWidth="798px" spacing="mb-5">
        <Text>
          Copy your referral URL using the link at the top of the page. When others join ThriveCoin using your link,
          you’ll see your referrals below. Your referral code is also automatically added to any “Share” button you use
          on the app.
        </Text>
      </Box>

      {loading ? (
        <Box $flex>
          <Loader $center />
        </Box>
      ) : (
        content()
      )}
      <LoadMore pagination={referredUsersPagination} loadMore={loadMore} />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  padding: 40px 32px 32px;
`;

const UsersList = styled(Box)`
  display: grid;
  gap: 1.5rem;
  row-gap: 28px;
  grid-template-columns: 1fr 1fr;

  @media ${breakpoints.miniTablet}, ${breakpoints.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${breakpoints.mobile} {
    row-gap: 24px;
  }
`;

export default MyReferrals;

import useSocialProfilesInfo from "../../hooks/useSocialProfilesInfo";
import { TabWrapper } from "../common";
import ConnectedNetworks from "./ConnectedNetworks";
import ProfileNetworkConnections from "./ProfileNetworkConnections";

const NetworkConnectionsTab = () => {
  const props = useSocialProfilesInfo();
  return (
    <TabWrapper>
      <ConnectedNetworks />
      <ProfileNetworkConnections {...props} />
    </TabWrapper>
  );
};

export default NetworkConnectionsTab;

export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isOnWebview = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const ios = /iphone|ipod|ipad/.test(userAgent);
  const android = userAgent.includes("wv");

  return ios || android;
};

const isMobile = () => {
  return !!localStorage.mobile || window.navigator.maxTouchPoints > 1;
};

export const openOnNativeBrowser = (url) => window.open(url, "_system");

export const formatThriveAmount = (amount) => {
  return parseFloat(amount);
};

const ADMIN_ROLES = ["owner", "admin"];

export const isAdmin = (role) => ADMIN_ROLES.includes(role);

export const isOwner = (role) => role === "owner";

import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { CONTRIBUTOR_SORT_OPTIONS } from "../../../SortOptions";
import TableTab from "./../../../CommunityPage/Challenges/Contributions/TableTab";
import useSort from "./../../../hooks/useSort";
import MobileQualifedContributors from "./MobileQualifedContributors";
import TableQualifedContributors from "./TableQualifedContributors";

const modalProps = {
  sortOptions: CONTRIBUTOR_SORT_OPTIONS,
  backLabel: "Back to Community Page",
  filterLabel: "Contributors",
};

const QualifedContributors = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getQualifiedContributors } = useStoreActions((actions) => actions.raffle);
  const { qualifiedContributors, raffle, qualifiedContributorsPagination } = useStoreState((state) => state.raffle);
  const title = `Contributors to ${raffle.community_name} - Season ${raffle.season_number}`;
  const sortProps = useSort(getQualifiedContributors);

  const onProfileClick = (e) => {
    const user_id = e.target.dataset.userid;
    navigate(`/${user_id}/profile`);
  };

  const rowProps = {
    onProfileClick,
  };
  useEffect(() => {
    getQualifiedContributors({ id });
  }, []);

  return (
    <TableTab
      title={title}
      titleSearch
      sortOptions={CONTRIBUTOR_SORT_OPTIONS}
      TableComponent={TableQualifedContributors}
      MobileTableComponent={MobileQualifedContributors}
      data={qualifiedContributors}
      pagination={qualifiedContributorsPagination}
      sortProps={sortProps}
      modalProps={modalProps}
      rowProps={rowProps}
    ></TableTab>
  );
};

export default QualifedContributors;

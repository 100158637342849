import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import BottomSection from "./BottomSection";
import TopSection from "./TopSection";

const HeroRaffle = ({ scrollDown }) => {
  const { raffle } = useStoreState((state) => state.raffle);
  const { confirmed } = useStoreState((state) => state.user.user);

  return (
    <WrapperHero>
      <TopSection raffle={raffle} scrollDown={scrollDown} />
      {raffle.season_status === "active" && confirmed && <BottomSection raffle={raffle} />}
    </WrapperHero>
  );
};

const WrapperHero = styled.div`
  position: relative;
  overflow-x: hidden;
`;
export default HeroRaffle;

import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import { TableOfContributions } from "../../CommunityPage/Challenges/common";
import LoaderTable from "../../CommunityPage/Challenges/Contributions/LoaderTable";
import BodyRow from "./BodyRow";
import HeaderRow from "./HeaderRow";

const TableEntries = ({
  data,
  onSort,
  sortType,
  sortDirection,
  loading,
  isSearching,
  onRaffleClick,
  onSeasonClick,
}) => {
  const { isDesktop, isMobile, isMiniTablet } = useStoreState((state) => state.media);
  return (
    <>
      <TableOfContributions>
        {!isMobile && (
          <HeaderRow
            onSort={onSort}
            sortType={sortType}
            sortDirection={sortDirection}
            isDesktop={isDesktop}
            isMiniTablet={isMiniTablet}
          />
        )}

        {!loading && (
          <TBbody>
            {data.map((item, index) => (
              <BodyRow
                key={item.serial_number + index}
                {...item}
                isSearching={isSearching}
                onRaffleClick={onRaffleClick}
                isMobile={isMobile}
                isDesktop={isDesktop}
                isMiniTablet={isMiniTablet}
                onSeasonClick={onSeasonClick}
              />
            ))}
          </TBbody>
        )}
      </TableOfContributions>
      <LoaderTable loading={loading} />
    </>
  );
};

const TBbody = styled.tbody`
  &:before {
    content: "@";
    display: block;
    line-height: 14px;
    text-indent: -99999px;
  }
`;

export default TableEntries;

import { Box, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import { useState } from "react";
import styled from "styled-components";

const InputItem = ({ item, setTags, index, isMobile }) => {
  const { id, _id, name, featured } = item;
  const [_name, setName] = useState(name);
  const [_featured, setFeatured] = useState(featured);

  const handleUpdate = (key, value) => {
    setTags((prevTags) =>
      prevTags.map((tag) => {
        const identifier = tag.id ? id : _id;
        const tagIdentifier = tag.id ?? tag._id;
        return identifier === tagIdentifier ? { ...tag, [key]: value } : tag;
      })
    );
  };

  const onInputChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    handleUpdate("name", newName);
  };

  const handleFeatureToggle = () => {
    setFeatured(!featured);
    handleUpdate("featured", !featured);
  };

  const handleRemove = (event) => {
    if (!id) {
      const { index } = event.target.dataset;
      setTags((prevTags) => prevTags.filter((tag, _index) => _index != index));
    } else {
      handleUpdate("_destroy", true);
    }
  };

  return (
    <>
      <Wrapper  spacing={isMobile ? "mb-5" : "mb-6"}>
        <Box justifyContent="space-between" spacing="mb-4" flex>
          <TagBox flex gap="10px" onClick={handleFeatureToggle}>
            <input type="checkbox" name="featured" checked={_featured} />
            <TagIcon featured={_featured}>{_featured && <IconSvg icon="check" color="white" />}</TagIcon>
            <Text typography="body/small" color="01Primary700">
              Feature this tag
            </Text>
          </TagBox>

          <XIcon icon="x" width="16px" color="01Primary700" onClick={handleRemove} data-index={index} />
        </Box>
        <Box gap="16px" alignItems="center" flex>
          <DragBox>
            <Line />
            <Line />
          </DragBox>
          <Input value={_name} data-id={id} onChange={onInputChange} bold />
        </Box>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Box)`
  border-radius: 12px;
  padding: 16px 16px 24px 16px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
`;

const TagBox = styled(Box)`
  cursor: pointer;
  input {
    display: none;
  }
`;

const TagIcon = styled(Box)`
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  background: ${({ theme, featured }) => (featured ? theme.colors["hover"] : "transparent")};
  border: 1px solid;
  border-color: ${({ theme, featured }) => (!featured ? theme.colors["01Primary300"] : "transparent")};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  svg {
    width: 14px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
`;

const DragBox = styled(Box)`
  width: 18px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  gap: 4px;
`;
const Line = styled(Box)`
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey500"]};
`;

const XIcon = styled(IconSvg)`
  cursor: pointer;
`;

export default InputItem;

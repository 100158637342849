import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";

const DEFAULT = { score: 0 };

const useGitcoinRequirementCheck = (requiredScore) => {
  const { showModal, hideModalByName } = useStoreActions((actions) => actions.modals);
  const { user } = useStoreState((state) => state.user);
  const { score } = user.socialProfiles.find((profile) => profile.provider == "gitcoin") || DEFAULT;

  useEffect(() => {
    if (user.id && requiredScore && (!score || parseFloat(score) < requiredScore)) {
      showModal({ modalName: "GitcoinConnectModal", requiredScore });
    } else {
      hideModalByName("GitcoinConnectModal");
    }
  }, [requiredScore, score, user.id]);
};

export default useGitcoinRequirementCheck;

import { useStoreActions } from "easy-peasy";
import { createContext, useContext, useState } from "react";
import { makeErrorHandler } from "../../ConnectSocialProfilesPage";

const DiscourseContext = createContext({
  onSubmit: () => {},
  share: () => {},
  close: () => {},
});

export const useDiscourseContext = () => {
  return useContext(DiscourseContext);
};

export const DiscourseProvider = ({ children }) => {
  const { showModal } = useStoreActions((state) => state.modals);
  const { connectSocialProfile, getProfileRequest } = useStoreActions((actions) => actions.authentication);
  const { dangerToast } = useStoreActions((actions) => actions.toasts);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(0);
  const onError = makeErrorHandler(dangerToast, () => setError(true));

  const onSubmit = (provider, username, setStep) => {
    setLoading(true);
    connectSocialProfile({ provider, params: { username } })
      .then(() => {
        getProfileRequest();
        setStep(1);
      })
      .catch(onError)
      .finally(() => setLoading(false));
  };

  const shareMore = (provider, hideModal) => {
    hideModal();
    showModal({ modalName: "ConnectSocialMediasModal", provider });
  };

  const close = (provider, hideModal) => {
    hideModal();
    showModal({ modalName: "UpdateProfileModal", provider });
  };

  return (
    <DiscourseContext.Provider value={{ onSubmit, loading, error, shareMore, close }}>
      {children}
    </DiscourseContext.Provider>
  );
};

import CommunityEditorProvider from "./CommunityEditorContext";
import CommunityEditorLayout from "./CommunityEditorLayout";

const CommunityEditor = ({ communityID }) => {
  return (
    <CommunityEditorProvider communityID={communityID}>
      <CommunityEditorLayout />
    </CommunityEditorProvider>
  );
};

export default CommunityEditor;

import { useStoreActions, useStoreState } from "easy-peasy";
import useGitcoinScorer from "./useGitcoinScorer";
export const REDIRECTION_KEY = "social-redirection-url";

export const SHOULD_CONNECT_MORE = "connect-more-profiles";
export const SHOULD_UPDATE_PROFILE = "connect-update-profile";

const isDefaultProfile = (username, image_url) => username.length === 42 || !image_url;

const useConnectSocialMedias = (showConnectMore = false, autoUpdateProfile = true) => {
  const { generateProviderUrl, connectGitcoinProfile } = useStoreActions((actions) => actions.authentication);
  const { user, wallets } = useStoreState((state) => state.user);
  const { username, image_url } = user;
  const address = wallets[0]?.address;
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { updateScoreNoSignature, reFetchScore } = useGitcoinScorer(address);
  localStorage.setItem(REDIRECTION_KEY, window.location.pathname);

  const wrapper =
    (callback) =>
    (...params) => {
      const triggerUpdateProfile = isDefaultProfile(username, image_url) && autoUpdateProfile;
      localStorage.setItem(SHOULD_CONNECT_MORE, showConnectMore);
      triggerUpdateProfile && localStorage.setItem(SHOULD_UPDATE_PROFILE, true);
      return callback.apply(null, params);
    };

  const generateUrlFN = (provider) => () =>
    generateProviderUrl(provider).then(({ url }) => (window.location.href = url));

  const connectDiscord = generateUrlFN("discord");

  const connectGithub = generateUrlFN("github");

  const connectDiscourse = async (name, provider) => showModal({ modalName: "DiscourseConnectModal", name, provider });

  const connectEmail = () => showModal({ modalName: "ConnectEmailModal" });

  const connectTwitter = generateUrlFN("twitter");

  const connectAsana = generateUrlFN("asana");

  const connectLinkedin = generateUrlFN("linkedin");

  const connectZoom = generateUrlFN("zoom");

  const connectGitcoin = () => {
    return updateScoreNoSignature()
      .then(reFetchScore)
      .then((score) => {
        return connectGitcoinProfile({ address, score });
      });
  };

  const result = {
    connectDiscord,
    discord: connectDiscord,
    connectTwitter,
    twitter: connectTwitter,
    connectGithub,
    github: connectGithub,
    connectDiscourse,
    discourse: connectDiscourse,
    connectEmail,
    email: connectEmail,
    asana: connectAsana,
    linkedin: connectLinkedin,
    zoom: connectZoom,
    gitcoin: connectGitcoin,
  };

  return wrapActions(result, wrapper);
};

const wrapActions = (result, wrapper) => {
  return Object.entries(result).reduce((acc, entry) => {
    acc[entry[0]] = wrapper(entry[1]);
    return acc;
  }, {});
};

export default useConnectSocialMedias;

import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import PinCode from "./PinCode/PinCode";

let forceRefresh = 1;

const VerificationStep = ({ digits, goToStep }) => {
  const [showReSend, setShowReSend] = useState(true);
  const { user } = useStoreState((state) => state.user);
  const { verifyUserRequest, reSendVerificationRequest, setOnboardingStatus } = useStoreActions(
    (actions) => actions.authentication
  );

  const [validationText, setValidationText] = useState("");

  const reSendEmail = () => {
    forceRefresh++;
    setShowReSend(false);
    setValidationText("");
    reSendVerificationRequest(user.email);
  };

  const onCompleted = (code) => {
    verifyUserRequest({ id: user.user_id || user.id, code })
      .then((data) => {
        localStorage.setItem("onboarding", true);
        goToStep(STEP_INDEXES.AccountCreatedStep);
      })
      .catch(({ response }) => {
        setValidationText("The verification code you entered is incorrect.");
        return;
      });
  };

  useEffect(() => setOnboardingStatus(true), []);

  useVirtualPageView({ pathname: "/email-verification" });

  return (
    <Page
      digits={digits}
      onCompleted={onCompleted}
      email={user.email}
      reSendEmail={reSendEmail}
      showReSend={showReSend}
      validationText={validationText}
    />
  );
};

const Page = ({ onCompleted, digits, email, reSendEmail, showReSend, validationText }) => (
  <Container>
    <Text type="h4" spacing="mb-4" bold>
      Let’s get you verified!
    </Text>
    <Text spacing="mb-7">
      We’ve just send an email with a verification code to your email
      <b> {email}</b>. Please enter the 6 digit code from your email to verify it’s you.
    </Text>
    <PinContainer flex justifyContent="center" alignItems="center" direction="column">
      <PinCode digits={digits} onCompleted={onCompleted} key={forceRefresh} />
      {validationText && (
        <Text color="#f16d67" align="left" spacing="mt-2 mr-4">
          {validationText}
        </Text>
      )}
    </PinContainer>

    {showReSend && (
      <StyledText pointer color="#5159C9" link onClick={reSendEmail} bold>
        Send email again
      </StyledText>
    )}
  </Container>
);

const Container = styled(Box)`
  @media ${breakpoints.tablet} {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 60px;
    max-width: 486px;
  }

  @media ${breakpoints.mobile} {
    padding: 5rem 1rem 0;
  }
`;

const StyledText = styled(Text)`
  &:hover {
    text-decoration: underline;
  }

  @media ${breakpoints.desktop} {
    margin-bottom: 10px;
  }
`;

const PinContainer = styled(Box)`
  margin-bottom: 3rem;

  @media ${breakpoints.mobile} {
    margin-bottom: 2rem;
  }
`;

VerificationStep.defaultProps = {
  digits: 6,
};

export default VerificationStep;

import { Box, Button, IconSvg, Modal, Text, breakpoints } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";

export const CONDITIONS = {
  role: { text: "Role: Admin", icon: "astronaut" },
  post_contains: { text: "Post contains: User mention", icon: "file-text" },
  channel: { text: "Channel: ThriveCoin", icon: "square-right" },
  discord_server: { text: "Discord server", icon: "discord" },
  post_recency: { text: "Post recency: New", icon: "list-timeline" },
  conditional_verification: { text: "Conditional verification: within 5 minutes", icon: "calendar" },
  embedding_personal_code: { text: "Embedding personal code", icon: "key" },
};
const KEYS = Object.keys(CONDITIONS);

const AddListingConditionModal = ({ hideModal, addConditions, conditionFields = [] }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const [conditions, setCondition] = useState(conditionFields);
  const onClick = (event) => {
    const { value } = event.currentTarget.dataset;
    let newConditions = [...conditions];
    if (newConditions.includes(value)) {
      newConditions = newConditions.filter((key) => key !== value);
    } else {
      newConditions.push(value);
    }
    setCondition(newConditions);
  };

  const saveConditions = () => {
    addConditions(conditions);
    hideModal();
  };

  const elements = KEYS.map((key) => (
    <ConditionElement
      onClick={onClick}
      selected={conditions.includes(key)}
      value={key}
      key={key}
      {...CONDITIONS[key]}
    />
  ));

  const footerContent = (
    <ButtonsContainer>
      <Button bold color="02Primary500" kind="outline" onClick={hideModal} modal={true}>
        Cancel
      </Button>
      <Button bold color="02Primary500" bgColor="success500" onClick={saveConditions} modal={true}>
        Add Condition(s){"  +"}
      </Button>
    </ButtonsContainer>
  );

  return (
    <Modal
      open
      onClose={hideModal}
      maxWidth="684px"
      leaf={!isMobile}
      borderRadius={isMobile && "1.5rem"}
      paddingBody={isMobile && "0"}
      footerContent={footerContent}
    >
      <Box flex direction="column" spacing="pt-6" textAlign="center" alignItems="center">
        <Text typography="heading/large" spacing="mb-4">
          Add Condition(s)
        </Text>
        <Text typography="body/medium" maxWidth="564px" spacing="mb-6">
          Select conditions from the options below that would help potential contributors understand where to
          contribute, and when their contribution will be counted. <b>Options below</b> are shown in the format{" "}
          <b>[Condition]: [Example value]</b>
        </Text>
        <Box flex gap="1rem" direction="column" width="100%" alignItems="center">
          {elements}
        </Box>
      </Box>
    </Modal>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  box-sizing: border-box;
  padding: 0 16px;
  @media ${breakpoints.mobile} {
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
  ${Button} {
    border-radius: 40px;
    height: fit-content;
    width: 100%;
  }
`;

const ConditionElement = styled(({ text, icon, value, selected, className, onClick }) => (
  <Box className={className + ` ${selected && "selected"} `} onClick={onClick} data-value={value}>
    <IconSvg icon={icon} width="16px" />
    <Text>{text}</Text>
  </Box>
))`
  width: 100%;
  max-width: 481px;
  background: #f5f5f5;
  padding: 17px 16px;
  border: 1px solid #ededed;
  border-radius: 12px;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;

  &.selected {
    border: 1px solid #5fb974;

    ${IconSvg} {
      color: #5fb974;
    }
  }
`;

export default AddListingConditionModal;

import { useStoreState } from "easy-peasy";
import { createContext, useEffect, useState } from "react";
import { useMatch } from "react-router";
import getDomainsCommunity from "../utils/domainsCommunity";

export const CommunityContext = createContext({
  communityBrand: null,
  communityId: null,
  isCustomDomain: false,
  redirectionPath: "",

  setCommunityId: () => {},
  setRedirectionPath: () => {},
});

const getCommunityID = (match) => {
  const { community_id } = match ? match.params : {};

  return community_id;
};

export const CommunityProvider = ({ children }) => {
  const match = useMatch("communities/:community_id");
  const secondMatch = useMatch("communities/:community_id/discord_sign_up/:discord_genereated_uid");
  const community_id = getCommunityID(match || secondMatch);
  const path = window.location.pathname;
  const [isCustomDomain, setIsCustomDomain] = useState(false);
  const [communityId, setCommunityId] = useState(community_id);
  const [redirectionPath, setRedirectionPath] = useState(null);
  const { communityBrand } = useStoreState((state) => state.community);

  useEffect(() => {
    const community_id = getCommunityID(match);
    if (community_id) {
      setCommunityId(community_id);
    }
  }, [path]);

  useEffect(() => {
    const domainCommunity = getDomainsCommunity();
    if (domainCommunity) {
      setIsCustomDomain(true);
      setCommunityId(domainCommunity);
      setRedirectionPath("/");
    }
  }, []);

  useEffect(() => {
    if (!isCustomDomain && !!communityId) {
      setRedirectionPath(`/communities/${communityId}`);
    }
  }, [communityId, isCustomDomain]);

  return (
    <CommunityContext.Provider
      value={{
        communityBrand,
        communityId,
        isCustomDomain,
        redirectionPath,
        setCommunityId,
        setRedirectionPath,
      }}
    >
      {children}
    </CommunityContext.Provider>
  );
};

export default class Connector {
  constructor(options = {}) {
    this.options = options;
  }

  async connect() {
    return;
  }

  logout() {
    return true;
  }

  async isLoggedIn() {
    return true;
  }
}

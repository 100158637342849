import DefaultFeatured from "@assets/dashboard/SeasonFeaturedBlockchain.svg";
import TabWrapper from "@common/TabWrapper";
import { Box, breakpoints, CommunityCard, CommunityPanelCard, SeasonCard, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { useEffect, useRef } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoadMore from "../../common/LoadMore";

const ContributionOpportunities = () => {
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const { activeCommunities, recentlyExplored, activeCommunitiesPagination } = useStoreState(
    (state) => state.community
  );
  const { activeSeasons } = useStoreState((state) => state.season);
  const { getActiveCommunities } = useStoreActions((actions) => actions.community);
  const { getActiveSeasons } = useStoreActions((actions) => actions.season);

  const onStartCard = (id, path) => {
    navigate(`/${path}/${id}`);
  };

  const onLoadMore = (page) => getActiveCommunities(page);

  useEffect(() => {
    getActiveCommunities();
    getActiveSeasons();
  }, []);

  return (
    <TabWrapper>
      <Content color="01Primary700" spacing="mx-a" ref={contentRef}>
        {recentlyExplored.length > 0 && (
          <>
            <Text typography="heading/small" spacing="mb-6">Recently Explored</Text>
            <ContainerGrid>
              {recentlyExplored.map((community) => (
                <CommunityPanelCard
                  spacing="mb-4"
                  key={community.id}
                  {...community}
                  items={community.recent_rewards}
                  cover_media_url=""
                  image_url=""
                  onStart={() => onStartCard(community.slug || community.id, "communities")}
                />
              ))}
            </ContainerGrid>
          </>
        )}
        {activeSeasons.length > 0 && (
          <>
            {recentlyExplored.length > 0 && <Line />}
            <Text typography="heading/small" spacing="my-6">Seasons</Text>
            <Box>
              {activeSeasons.map((item) => {
                return (
                  <SeasonBox key={item.id}>
                    <SeasonCard
                      season_number={item.order_number}
                      {...item}
                      onStart={() => onStartCard(item.slug || item.id, "seasons")}
                      isDashboard
                      end_date={moment(item.end_date).format("MMM Do, YYYY")}
                      featured_image_url={item.featured_image_url || DefaultFeatured}
                    />
                  </SeasonBox>
                );
              })}
            </Box>
          </>
        )}
        {activeCommunities.length > 0 && (
          <>
            {(recentlyExplored.length > 0 || activeSeasons.length > 0) && <Line />}
            <Text typography="heading/small" spacing="my-6">Communities</Text>
            <ResponsiveMasonry columnsCountBreakPoints={{ 1338: 1, 1339: 2 }}>
              <Masonry gutter="24px">
                {activeCommunities.map((item) => (
                  <CommunityCard
                    {...item}
                    key={item.id}
                    onStart={() => onStartCard(item.slug || item.id, "communities")}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
            <LoadMore pagination={activeCommunitiesPagination} loadMore={onLoadMore} />
          </>
        )}
      </Content>
    </TabWrapper>
  );
};

const Content = styled(Box)`
  z-index: 1;
  position: relative;
`;

const Line = styled(Box)`
  height: 1px;
  margin-top: 32px;
  background-color: ${({ theme }) => theme.colors["01Primary300"]};
`;

const SeasonBox = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const ContainerGrid = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
`;

export default ContributionOpportunities;

import { Box, breakpoints, Button, IconSvg, Text } from "@thrivecoin/ui-components";
import truncateText from "@utils/truncateText";
import styled from "styled-components";

import OG from "@assets/dashboard/og-dark.svg";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";

const ReferredUser = ({ username, image_url, description, address, user_id }) => {
  const navigate = useNavigate();
  const { isTablet, isMobile } = useStoreState((state) => state.media);
  const onCopyClipboard = useCopyToClipboard(address, "The address has been copied.");
  const viewContributions = () => navigate(`/${user_id}/profile`);

  const _address = !!address && (
    <AddressContainer color="01Primary700" pointer onClick={onCopyClipboard}>
      <Text bold>{truncateText(address)}</Text>
      <IconSvg width="12px" icon="copy" />
    </AddressContainer>
  );

  const _description = !!description && (
    <Bio bold typography="body/small" sm-spacing="mb-5">
      {description}
    </Bio>
  );

  return (
    <Wrapper>
      <Box flex sm-spacing="mb-5" grow={1}>
        <Image src={image_url || OG} />
        <UserBio alignSelf="center" spacing="mr-4" flex direction="column" gap="12px">
          <Text extraBold typography="body/large">
            {truncateText(username)}
          </Text>
          {isMobile ? _address : _description}
        </UserBio>
      </Box>
      <Box spacing="my-1" flex direction="column" justifyContent="flex-end">
        {isMobile ? _description : _address}
        <StyledButton
          spacing="mt-5"
          size={isTablet ? "sm" : "md"}
          rightIcon={!isTablet && "arrow-up-right"}
          onClick={viewContributions}
        >
          View Contributions
        </StyledButton>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
  background: ${({ theme }) => theme.colors["01Primary110"]};

  display: flex;

  @media ${breakpoints.desktop} {
    padding-right: 24px;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 117px;
  height: 117px;
  border-radius: 8px;
  margin-right: 24px;

  @media ${breakpoints.tablet} {
    margin-right: 16px;
  }

  @media ${breakpoints.mobile} {
    width: 85px;
    height: 85px;
    margin-right: 16px;
  }
`;

const UserBio = styled(Box)`
  flex-grow: 1;
  align-self: center;
  margin-right: 16px;
`;

const Bio = styled(Text)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const AddressContainer = styled(Box)`
  border-radius: 4px;
  outline: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
  background: ${({ theme }) => theme.colors["01Primary100"]};

  padding: 4px 9px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  @media ${breakpoints.tablet} {
    width: 137px;
    padding: 4px 0;
  }

  @media ${breakpoints.mobile} {
    align-self: flex-start;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  border-radius: 40px;
  height: 44px;

  @media ${breakpoints.tablet} {
    width: 137px;
  }
`;

export default ReferredUser;

import { breakpoints } from "@thrivecoin/ui-components";
import { action } from "easy-peasy";

const attributes = {
  isDesktop: window.matchMedia(breakpoints.desktop).matches,
  isTablet: window.matchMedia(breakpoints.tablet).matches,
  isMiniTablet: window.matchMedia(breakpoints.miniTablet).matches,
  isMobile: window.matchMedia(breakpoints.mobile).matches,
};

const actions = {
  setDesktop: action((state, value) => {
    state.isDesktop = value;
  }),
  setTablet: action((state, value) => {
    state.isTablet = value;
  }),
  setMiniTablet: action((state, value) => {
    state.isMiniTablet = value;
  }),
  setMobile: action((state, value) => {
    state.isMobile = value;
  }),
};

const media = {
  ...attributes,
  ...actions,
};

export default media;

import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";

const GoBack = ({ isAbsolute = true, textColor = "02Primary700", navigationPath, text, ...props }) => {
  if (!window.history.length) {
    return <></>;
  }

  const navigate = useNavigate();

  const onBack = () => {
    if (navigationPath) {
      navigate(navigationPath);
    } else {
      window.history.go(-1);
    }
  };

  return (
    <PositioningBox
      className="go-back"
      pointer
      flex
      alignItems="center"
      gap="8px"
      onClick={onBack}
      isAbsolute={isAbsolute}
      {...props}
    >
      <IconSvg icon="left-long" size="14px" color={textColor} />
      <Text color={textColor} size="15px" lHeight="20px" bold>
        {text}
      </Text>
    </PositioningBox>
  );
};

const PositioningBox = styledComponents(Box)`
  ${({ isAbsolute }) => isAbsolute && "position: absolute;"}
  top: 33px;
  left: 300px;
  @media ${breakpoints.mobile} {
    left: 16px;
    top: 24px;
  }
`;

GoBack.defaultProps = {
  text: "Back to Previous Page",
};

export default GoBack;

import { Box, Button, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useChallengeStatus = () => {
  const navigate = useNavigate();
  const { challenge_id, listing_id } = useParams();
  const { getChallengeDetailRequest } = useStoreActions((actions) => actions.challenges);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { dangerToast, successToast, hideLast } = useStoreActions((actions) => actions.toasts);
  const walletChains = useStoreState((state) => state.user.walletChains);
  useEffect(() => {
    if (challenge_id && listing_id) {
      getChallengeDetailRequest(challenge_id).then((challenge) => {
        const step = challenge.steps.find((step) => step.listing_id == listing_id);
        let toastMethod = successToast;
        let props = {};
        let url;
        if (step) {
          const remainingCount = challenge.steps.filter(
            (step) => step.listing_id != step.listing_id && step.status !== "completed"
          ).length;

          if (step?.status == "completed") {
            url = "../" + rewardUrl(challenge, step, remainingCount);
            const onClick = () => {
              if (walletChains.includes(challenge.chain_type)) {
                navigate(url);
              } else {
                showModal({
                  modalName: "ClaimRewardFlowModal",
                  reward_id: step.reward_id,
                  afterClose: () => navigate(url),
                });
              }
              hideLast();
            };
            props.content = (
              <Box flex alignItems="center">
                <Text spacing="mr-6">{`Woohoo! Your contribution to “${step.name}” has been approved!`}</Text>
                <Button
                  borderRadius="40px"
                  bgColor="#9CEDAF"
                  color="black"
                  bold
                  onClick={onClick}
                  style={{ fontSize: "12px" }}
                >
                  Receive Reward
                </Button>
              </Box>
            );
          }

          if (step?.status == "rejected") {
            url = "../" + rejectionUrl(challenge, step, remainingCount);
            props.content = `Your contribution to “${step.name}” has been reviewed but needs revision.`;
            toastMethod = dangerToast;
          }

          if (!url) {
            return navigate("..");
          }

          toastMethod(props);
        }
      });
    }
  }, [challenge_id, listing_id]);
};

const rewardUrl = ({ challenge_id, status }, { listing_id, contribution_app }) => {
  if (status == "completed") {
    return `complete/${challenge_id}/${listing_id}/challenge_completed`;
  }

  if (contribution_app == "learning_app") {
    return `challenge/${challenge_id}/${listing_id}/reward`;
  }

  return `activity/${challenge_id}/${listing_id}/reward`;
};

const rejectionUrl = ({ challenge_id, status }, { listing_id, contribution_app }) => {
  if (status == "completed") {
    return `complete/${challenge_id}/${listing_id}/challenge_completed`;
  }

  if (contribution_app == "learning_app") {
    return `challenge/${challenge_id}/${listing_id}/video`;
  }

  return `activity/${challenge_id}/${listing_id}/instruction`;
};

export default useChallengeStatus;

import { Box, breakpoints, Button, Modal, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const ARCHIVED_TITLE = "Are you sure you want to archive this way to contribute?";
const ARCHIVED_DESCRIPTION =
  "Anyone that completes this way to contribute after it has been archived will no longer receive a crypto reward and on-chain thank you note. Historical contribution information is not available for all ways to contibute.";

const ACTIVATE_TITLE = "Are you sure you want to unarchive this way to contribute?";
const ACTIVATE_DESCRIPTION =
  "All contributors that already completed this activity will remain recorded. New contributions will start being recorded and rewarded as soon as the way to contribute is unarchived.";

const modal =
  (title, description, isArchived = true) =>
    ({ action, hideModal }) => {
      const onClick = () => {
        action();
        hideModal();
      };

      const footerContent = (
        <ButtonsWrapper flex gap="1rem">
          <Button
            size="lg"
            heightSize="56px"
            borderRadius="56px"
            semiBold
            onClick={hideModal}
            grow="1"
            kind="outline"
            borderColor="grey300"
            color="02Primary700"
            bold
            modal={true}
          >
          Cancel
          </Button>
          <Button
            size="lg"
            heightSize="56px"
            borderRadius="56px"
            semiBold
            onClick={onClick}
            grow="1"
            kind="outline"
            borderColor={isArchived ? "error" : "hover"}
            color="02Primary500"
            bold
            modal={true}
          >
          Yes, {isArchived ? "Archive" : "Unarchive"} it
          </Button>
        </ButtonsWrapper>
      );

      return (
        <Modal open onClose={hideModal} leaf={true} maxWidth="684px" footerContent={footerContent}>
          <Wrapper>
            <Text fontFamily="Outfit" bold type="header" size="28px" lineHeight="36px">
              {title}
            </Text>
            <Text color="02Primary700" spacing=" mt-3">
              {description}
            </Text>
          </Wrapper>
        </Modal>
      );
    };

const Wrapper = styled.div`
  text-align: center;
  @media ${breakpoints.mobile} {
    margin: 0 1rem;
  }
`;

const ButtonsWrapper = styled(Box)`
  margin-bottom: 10px;
  margin-top: 8px;

  @media ${breakpoints.mobile} {
    margin: 0 1rem;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const ArchiveListingModal = modal(ARCHIVED_TITLE, ARCHIVED_DESCRIPTION);
export const ActivateListingModal = modal(ACTIVATE_TITLE, ACTIVATE_DESCRIPTION, false);

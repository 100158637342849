import Hand from "@assets/profile/hand-big.svg";
import { Box, Button, IconSvg, Modal, Text, breakpoints } from "@thrivecoin/ui-components";
import styled from "styled-components";
import WalletCard from "../../Profile/WalletsTab/WalletCard";

const VerificationSuccessfulModal = ({ hideModal, address }) => {
  return (
    <Modal maxWidth="684px" open buttonCircle onClose={hideModal} borderRadius="12px">
      <Container>
        <Text typography="heading/large" color="02Primary500" spacing="mb-6">
          Verification Successful
        </Text>

        <Box>
          <HandImg src={Hand} />
          <WalletCard
            title="Additional Wallet"
            bgColorCard="03Secondary800"
            smallCard={true}
            height="242px"
            width="391px"
            address={address}
            isFromVerificationModal={true}
          />
        </Box>

        <Box flex gap="8px" spacing="mt-5">
          <CheckIcon icon="circle-check-filled" color="red" fill="blue" />
          <Text typography="body/medium" color="black" bold>
            The new wallet address has been added to your connected wallets.
          </Text>
        </Box>

        <Button spacing="mt-6" type="kind" borderRadius="40px" width="100%" onClick={hideModal} modal={true}>
          View Wallet Connections
        </Button>
      </Container>
    </Modal>
  );
};

const CheckIcon = styled(IconSvg)`
  path {
    fill: ${({ theme }) => theme.colors["03Primary500"]};
  }
`;

const Container = styled(Box)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HandImg = styled.img`
  position: absolute;
  left: -82px;
  top: 110px;
  z-index: 9;
  @media ${breakpoints.mobile}{
    left: 0;
    transform: translate(-34%, 34%);
    max-height: 157px;
  }
`;
export default VerificationSuccessfulModal;

import discord from "@assets/discord.svg";
import notFoundTreeLeft from "@assets/notFoundTreeLeft.svg";
import notFoundTreeRight from "@assets/notFoundTreeRight.svg";
import { Box, Text } from "@thrivecoin/ui-components";
import { useEffect } from "react";
import styled from "styled-components";
import { THRIVE_DISCORD_LINK } from "../../../constants";
import { trackEventCategory } from "../../hooks/useTrackEvents";
import { PaddedButton } from "../common";

const BG_COLOR = "#EDF9EF";

const RewardsFoundStep = () => {
  const onDiscordClick = () => {
    trackEventCategory("UO_NRF_Discord");
  };

  useEffect(() => {
    trackEventCategory("UO_NRF_PageLand");
  }, []);

  return (
    <Box sm-spacing="mx-4">
      <Text typography="heading/large" spacing="mb-4" sm-spacing="mt-6 mb-5 mx-4">
        No past contributions found
      </Text>

      <Text typography="body/medium" spacing="mb-7" sm-spacing="mb-6" bold>
        Don’t worry, it’s not too late to start contributing!
      </Text>

      <Text spacing="mb-4" typography="body/medium" sm-spacing="mb-5">
        Connect with us on to stay connected, see new ways to contribute, and request us supporting a new Community.
      </Text>
      <Box flex justifyContent="center" gap="1rem" spacing="mb-6+6+4">
        <Link target="blank" href={THRIVE_DISCORD_LINK}>
          <FrameBox color="#4253E4" borderColor="#4253E4" onClick={onDiscordClick}>
            <img src={discord} />
            <Text typography="body/medium" bold>
              Discord
            </Text>
          </FrameBox>
        </Link>
      </Box>

      <StyledImage src={notFoundTreeLeft} left="0" bottom="-10px" />
      <StyledImage src={notFoundTreeRight} right="0" bottom="0" />
    </Box>
  );
};

const FrameBox = styled(Box)`
  border: 1px solid ${({ color }) => color};
  border-radius: 12px;
  padding: 1rem 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Link = styled.a`
  text-decoration: none;
`;

const Footer = ({ closeModal }) => {
  const onClick = () => {
    trackEventCategory("UO_NRF_Communities");
    closeModal();
  };

  return (
    <StyledBox height="132px" bgColor="#81C394" flex justifyContent="center" alignItems="center">
      <PaddedButton
        onClick={onClick}
        typography="body/medium"
        bgColor="#FFFFFF"
        color="02Primary700"
        kind="outline"
        maxWidth="396px"
        height="56px"
        borderColor="transparent"
        rightIcon="arrow-right"
        sm-spacing="mx-5+1"
        modal={true}
      >
        Explore Ways to Contribute
      </PaddedButton>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  margin-bottom: env(safe-area-inset-bottom);
`;

const StyledImage = styled.img`
  position: absolute;
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

RewardsFoundStep.bgColor = BG_COLOR;
RewardsFoundStep.maxWidth = "810px";
RewardsFoundStep.Footer = Footer;

export default RewardsFoundStep;

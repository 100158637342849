import { useStoreActions } from "easy-peasy";
import { createContext, useContext, useState } from "react";
import { useParams } from "react-router";

const PendingContributionContext = createContext({
  onClick: () => {},
  onClickReject: () => {},
});

export const usePendingContributionContext = () => {
  return useContext(PendingContributionContext);
};

export const PendingContributionProvider = ({ children }) => {
  const [selected, setSelected] = useState();
  const [reviewer_comments, setRejectionNote] = useState("");
  const { approveClaimRequest, rejectClaimRequest } = useStoreActions((actions) => actions.contributions);
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const disabled = !selected;

  const onClick = (id, contribution_claim_id, hideModal) => {
    approveClaimRequest({
      id,
      contribution_claim_id,
    })
      .then(refreshPendingContributions)
      .then(() => {
        showToast({
          content: "Contribution claim was approved successfully!",
          dismissTime: 5000,
        });
        hideModal();
      })
      .catch(({ response }) => {
        dangerToast({
          content: response.data.message,
          dismissTime: 5000,
        });
      });
  };
  const onClickReject = (id, contribution_claim_id, selected, reviewer_comments, hideModal) => {
    rejectClaimRequest({
      id,
      contribution_claim_id,
      rejection_reason: selected,
      reviewer_comments,
    })
      .then(refreshPendingContributions)
      .then(() => {
        showToast({
          content: "Contribution claim was rejected successfully!",
          dismissTime: 5000,
        });
        hideModal();
      })
      .catch(({ response }) => {
        dangerToast({
          content: response.data.message,
          dismissTime: 5000,
        });
      });
  };

  const { getPendingContributions } = useStoreActions((actions) => actions.pendingContributions);
  const { community_id } = useParams();
  const refreshPendingContributions = () => {
    getPendingContributions({ community_id });
  };

  return (
    <PendingContributionContext.Provider
      value={{ onClick, onClickReject, selected, setSelected, disabled, reviewer_comments, setRejectionNote }}
    >
      {children}
    </PendingContributionContext.Provider>
  );
};

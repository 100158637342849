import { useNavigate, useParams } from "react-router-dom";

export const DISCORD_KEY = "discord_uid";

const DiscordSignUpPage = () => {
  const discord_genereated_uid = useParams().discord_genereated_uid;
  const community_identifier = useParams().community_id;
  const navigate = useNavigate();

  if (discord_genereated_uid) {
    localStorage.setItem(DISCORD_KEY, discord_genereated_uid);
  }

  const navigationPath = community_identifier ? `/communities/${community_identifier}` : "dashboard";
  navigate(navigationPath);
  return <></>;
};

export default DiscordSignUpPage;

import { Box, Button, IconSvg, Input, Modal, Text, TextArea } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { DEFAULT_PROFILE_IMAGE } from "../Constants";

const FILE_CONTROL = "profile-file-upload";
const DEFAULT_PROFILE = { username: "" };

const useLogic = ({ provider, profile, hideModal }) => {
  const { socialProfiles } = useStoreState((state) => state.user.user);
  const { updateProfileRequest } = useStoreActions((actions) => actions.user);
  const { refresh } = useStoreActions((actions) => actions.authentication);
  const { showToast } = useStoreActions((actions) => actions.toasts);
  const social = profile || socialProfiles.find((social) => social.provider === provider) || DEFAULT_PROFILE;
  const { image_url: socialImageUrl, username: socialUsername } = social;
  const [error, setError] = useState();
  const [file, setFile] = useState();
  const [image_url, setPreviewUrl] = useState(socialImageUrl);
  const [{ username, description }, setState] = useState({ username: socialUsername });
  const canClearImage = socialImageUrl !== image_url;
  const canClearNAme = socialUsername !== username;
  const removeFile = () => {
    setPreviewUrl(socialImageUrl);
    setFile();
  };

  const onFileChange = (event) => {
    window.URL.revokeObjectURL(image_url);
    const file = event.target.files[0];
    const url = window.URL.createObjectURL(file);
    setFile(file);
    setPreviewUrl(url);
  };

  const saveChanges = () => {
    updateProfileRequest({ username, description, image_url: socialImageUrl, image: file })
      .then(refresh)
      .then(() => {
        showToast({
          content: "Your profile information has been successfully updated.",
          type: "success",
          dismissTime: 5000,
        });
      })
      .then(hideModal)
      .catch((response) => {
        setError(response.response.data.data.errors.username[0]);
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (name == "username") {
      setError();
    }
  };

  const resetUsername = () => setState((prev) => ({ ...prev, username: "" }));

  const isDisabled = !username.trim();

  return {
    error,
    isDisabled,
    username,
    description,
    image_url: image_url || DEFAULT_PROFILE_IMAGE,
    removeFile,
    resetUsername,
    saveChanges,
    onFileChange,
    onChange,
    provider,
    canClearImage,
    canClearNAme,
  };
};

const UpdateProfileModal = (props) => {
  return (
    <Modal open leaf onClose={props.hideModal} maxWidth="684px" spacing="px-8">
      <Content {...props} />
    </Modal>
  );
};

export const Content = (props) => {
  const {
    error,
    isDisabled,
    canClearImage,
    canClearNAme,
    username,
    description,
    image_url,
    removeFile,
    resetUsername,
    saveChanges,
    onFileChange,
    onChange,
  } = useLogic(props);

  return (
    <>
      <Text typography="heading/large" spacing="mx-a my-5" align="center">
        Update profile information
      </Text>
      <Box width="100%" minWidth="325px" spacing="mx-a mb-7" onChange={onChange}>
        <ProfileImageUpload
          image_url={image_url}
          onChange={onFileChange}
          removeFile={removeFile}
          canClearImage={canClearImage}
        />
        <UsernameContainer>
          <Input
            type="text"
            bgColor="02Primary0"
            color="02Primary500"
            variant="grey300"
            placeholder="Username"
            spacing="mb-4"
            validationText={error}
            invalid={error}
            name="username"
            maxLength="25"
            value={username}
            onChange={onChange}
          />
          {canClearNAme && <IconSvg onClick={resetUsername} icon="x" />}
        </UsernameContainer>
        <TextArea
          name="description"
          value={description}
          placeholder="Your bio"
          bgColor="02Primary0"
          color="02Primary500"
          variant="grey300"
        />
      </Box>
      <Box flex gap="1rem">
        <Button borderRadius="40px" bold width="100%" disabled={isDisabled} onClick={saveChanges} modal={true}>
          Update Profile
        </Button>
      </Box>
    </>
  );
};

UpdateProfileModal.defaultProps = {
  provider: "ENS",
};

const ProfileImageUpload = ({ image_url, onChange, removeFile, canClearImage }) => {
  const doUpload = () => document.getElementById(FILE_CONTROL).click();

  return (
    <Box spacing="mb-4+1" flex alignItems="center" direction="column">
      <input type="file" hidden onChange={onChange} id={FILE_CONTROL} accept="image/*" />
      <StyledImage src={image_url} />
      <ButtonsContainer>
        <StyledIcon icon="upload-bold" width="13px" onClick={doUpload} />
        <Separator />
        <StyledIcon icon="trash-bold" width="13px" color="error" onClick={removeFile} disabled={!canClearImage} />
      </ButtonsContainer>
    </Box>
  );
};

const UsernameContainer = styled(Box)`
  position: relative;
  input {
    padding-right: 3rem;
  }

  ${IconSvg} {
    cursor: pointer;
    position: absolute;
    right: 16px;
    bottom: 30px;
  }
`;

const Separator = styled.div`
  background: #d9d9d9;
  width: 1px;
  height: 80%;
  align-self: center;
`;

const ButtonsContainer = styled.div`
  width: 76px;
  height: 34px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  gap: 14px;

  ${({ theme }) => `
    background: ${theme.colors["02Primary0"]};
    border: 1px solid ${theme.colors["grey300"]};
  `}
`;

const StyledIcon = styled(IconSvg)`
  cursor: pointer;
  ${({ disabled }) => disabled && `opacity: 0.5; pointer-events: none;`}
`;

const StyledImage = styled.img`
  width: 89px;
  height: 89px;
  border-radius: 50px;
`;

export default UpdateProfileModal;

function fireEvent(element, eventName, bubbles = true, cancelable = true) {
  if (document.createEventObject) {
    // dispatch for IE
    const evt = document.createEventObject();
    return element.fireEvent("on" + eventName, evt);
  } else {
    // dispatch for firefox + others
    const evt = new Event(eventName, { bubbles, cancelable });
    evt.simulated = true;
    // const evt = document.createEvent("HTMLEvents");
    // evt.initEvent(eventName, bubbles, cancelable); // event type,bubbling,cancelable
    return !element.dispatchEvent(evt);
  }
}

export default fireEvent;

import CoinBase from "./CoinBase";
import Metamask from "./Metamask";
import WalletConnect from "./WalletConnect";

const list = [
  {
    class: Metamask,
    key: "metamask",
    options: {},
  },
  {
    class: WalletConnect,
    key: "walletconnect",
    options: {},
  },
  {
    class: CoinBase,
    key: "coinbase",
    options: {
      ethJsonrpcUrl: "https://cloudflare-eth.com",
      chainId: 1,
    },
  },
];

export default list;

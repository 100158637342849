import { Box, Button, DropDown, Input, MultiSelect, Text } from "@thrivecoin/ui-components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import fireEvent from "../../utils/fireEvent";
import RichEditor from "../common/RichEditor";
export { default as TextList } from "./TextList";

const triggerChangeEvent = (name, value) => {
  const element = document.getElementsByName(name)[0];
  var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
  nativeInputValueSetter.call(element, value);
  fireEvent(element, "change", true, false);
};

export const Divider = (props) => <Box height="1px" bgColor="01Primary200" spacing="my-6" {...props} />;

export const InputGroup = ({
  field,
  label,
  placeholder,
  required,
  InputClass = Input,
  spacing = "mb-6",
  description,
  ...props
}) => {
  const hidden = InputClass === InputHidden;

  return (
    <StyledBox spacing={spacing} $hidden={hidden}>
      <Text bold spacing="mb-3" htmlFor={field} color="01Primary700">
        {label || field}
      </Text>
      {description && (
        <Text spacing="mb-5" color="01Primary700">
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </Text>
      )}
      <InputClass fieldLabel={label} name={field} placeholder={placeholder} required={required} {...props} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  ${({ $hidden }) => $hidden && `display: none;`}
`;

export const Select = styled.select`
  padding: 18px 16px;
  border-radius: 12px;
  border-color: transparent;
  width: 100%;
  ${({ theme }) => `
    background: ${theme.colors["01Primary50"]};
    border: 1px solid ${theme.colors["01Primary300"]};
    color: ${theme.colors["01Primary700"]};
   `}

  &,
  option {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  ${({ grow }) => grow && `flex-grow: ${grow};`}
`;

export const wrappedSelect = (options) =>
  function WrappedSelect({ defaultValue, disabled, name, placeholder }) {
    const [xValue, setState] = useState(defaultValue);
    const onItemClick = ({ value }) => {
      triggerChangeEvent(name, value);
      setState(value);
    };

    return (
      <Box inline>
        <input type="text" hidden name={name} value={xValue} />
        <DropDown
          required
          valueKey="value"
          label="label"
          image="image"
          value={xValue}
          options={options}
          disabled={disabled}
          onChange={onItemClick}
          placeholder={placeholder}
        />
      </Box>
    );
  };

export const wrappedMultiSelect = (options) =>
  function WrappedMultiSelect({ name, defaultValue, setState, idKey }) {
    const [selected, setSelected] = useState(defaultValue || []);

    const onSelectChange = (item) => {
      let updatedValues = [...selected];
      if (selected.some((obj) => obj.value === item.value)) {
        updatedValues = updatedValues.filter((obj) => obj.value !== item.value);
      } else {
        updatedValues = [...updatedValues, item];
      }
      const valuesIds = idKey ? updatedValues.map((val) => val[idKey]) : updatedValues;

      setState((state) => ({ ...state, [name]: valuesIds }));
    };

    useEffect(() => {
      if (defaultValue) {
        setSelected(defaultValue);
      }
    }, [defaultValue]);

    return (
      <Box flex direction="column">
        <MultiSelect
          maxHeight="170"
          hideX
          height="56px"
          options={options}
          selectedOptions={selected}
          onItemClick={onSelectChange}
        />
      </Box>
    );
  };

const IDENTIFIERS = [
  {
    value: "snapshot_app",
    label: "Snapshot",
  },
  {
    value: "twitter_app",
    label: "Twitter ID",
  },
  {
    value: "discord_app",
    label: "Discord ID",
  },
  {
    value: "banklessdao_discourse_app",
    label: "Discourse Account",
  },
  {
    value: "particledao_discourse_app",
    label: "Discourse Account",
  },
  {
    value: "apecoin_discourse_app",
    label: "Discourse Account",
  },
  {
    value: "aavegotchi_discourse_app",
    label: "Discourse Account",
  },
  {
    value: "activity_app",
    label: "Wallet Address",
  },
];

export const IdentifierSelect = wrappedSelect(IDENTIFIERS);

const isCharcodeInteger = (charCode) => (charCode != 8 && charCode == 0) || (charCode >= 48 && charCode <= 57);

const forceInteger = (event) => {
  if (isCharcodeInteger(event.charCode)) {
    return true;
  }
  return event.preventDefault();
};

const DOT_CODE = 46;

const forceFloat = (event) => {
  const { charCode } = event;
  if (isCharcodeInteger(charCode) || charCode == DOT_CODE) {
    return true;
  }
  return event.preventDefault();
};

export const IntegerInput = (props) => <Input {...props} type="number" min="0" onKeyPress={forceInteger} />;

export const FloatInput = (props) => <Input {...props} type="number" step="0.01" onKeyPress={forceFloat} />;

export const InputHidden = (props) => <Input {...props} type="hidden" />;

export const InputCheckbox = (props) => <input {...props} type="checkbox" />;

export const InputSelect = ({ options, ...props }) => {
  const optionElements = options.map((value) => (
    <option key={value} value={value}>
      {value}
    </option>
  ));
  return (
    <Select {...props}>
      <option selected disabled>
        Select an option
      </option>
      {optionElements}
    </Select>
  );
};

export const WaysToContributeLink = () => (
  <Link to="/manage/listings/create">
    <Button rightIcon="plus" size="md">
      Add Way to Contribute
    </Button>
  </Link>
);

export const RichText = ({ placeholder, value, setState, disabled, name }) => {
  const onChange = (value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  return <RichEditor disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} />;
};

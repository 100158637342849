import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";

const Header = ({}) => {
  const { isMobile } = useStoreState((state) => state.media);

  const onPreview = () => {};
  return (
    <Box flex direction="column" alignItems={!isMobile && "center"}>
      <Text typography="heading/small" color="01Primary700" spacing="mb-4">
        Community Tags
      </Text>
      <StyledText typography="body/medium" color="01Primary700" spacing={isMobile ? "mb-5" : "mb-6"}>
        How is your community structured? Create tags to group contribution opportunities for easier viewing and
        analysis
      </StyledText>
      <YellowBox alignItems="flex-start" flex gap="8px">
        <IconSvg color="03Secondary800" icon="alert-triangle" width="24px" spacing="mt-1" />
        <Text typography="body/small" color="01Primary700" bold>
          "Feature this tag" to give contributors quick filter access to the featured tag. Make selections below then
          <Text spacing="ml-1" inline color="link" onClick={onPreview} extraBold>
            Preview{" "}
          </Text>
          the results.
        </Text>
      </YellowBox>
      <Divider />
    </Box>
  );
};

const YellowBox = styled(Box)`
  border-radius: 12px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors["03Secondary800"]};
  svg {
    min-width: 16px;
  }
`;

const Divider = styled(Box)`
  height: 1px;
  margin: 2rem 0;
  width: 100%;
  background: ${({ theme }) => theme.colors["01Primary200"]};
  @media ${breakpoints.mobile} {
    margin: 24px 0;
  }
`;

const StyledText = styled(Text)`
  text-align: center;
  @media ${breakpoints.mobile} {
    text-align: left;
  }
`;
export default Header;

import domains from "../../../thc-configurations/communities/domains.json";

const getDomainsCommunity = () => {
  const domain = window.location.host.replace(".localhost:3000", "");
  const community = domains[domain];

  return community;
};

export default getDomainsCommunity;

export const formatImageFiles = (community, fields = []) => {
  fields.forEach((key) => {
    const value = community[key];
    if (value) {
      const urlField = `${key}_url`;
      community[urlField] = getFileUrl(value);
    }
  });
};

const getFileUrl = (value) => {
  if (value instanceof File) {
    return window.URL.createObjectURL(value);
  }
  return value;
};

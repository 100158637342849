const image = "https://thrivecoin-assets.s3.amazonaws.com/images/thrivecoin_logo_metamask.png";

const config = {
  polygon: {
    address: "",
    symbol: "THRIVE",
    decimals: 8,
  },
  goerli: {
    address: process.env.REACT_APP_ETHEREUM_ADDRESS,
    symbol: process.env.REACT_APP_ETHEREUM_SYMBOL,
    decimals: process.env.REACT_APP_ETHEREUM_DECIMALS,
    image,
  },
  polygonMumbai: {
    address: process.env.REACT_APP_POLYGON_ADDRESS,
    symbol: process.env.REACT_APP_POLYGON_SYMBOL,
    decimals: process.env.REACT_APP_POLYGON_DECIMALS,
    image,
  },
};

export default config;

import { MainContainer } from "@common/MainContainer";
import { Box, breakpoints, TabsContainer } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useQuery from "../hooks/useQuery";
import Cover from "./Header/Cover";
import MyRaffleEntriesTab from "./MyRaffleEntriesTab";
import MyReferrals from "./MyReferralsTab";
import NetworkConnectionsTab from "./NetworkConnectionsTab";
import NotificationsSettings from "./NotificationsSettings";
import ProfileContributions from "./ProfileContributions";
import WalletsTab from "./WalletsTab";

const TAB_HEADERS = [
  "Network Connections",
  "Wallet Connections",
  "Notifications",
  "My Contributions",
  "My Raffle Entries",
  "My Referrals",
];

const TAB_CLASSES = [
  NetworkConnectionsTab,
  WalletsTab,
  NotificationsSettings,
  ProfileContributions,
  MyRaffleEntriesTab,
  MyReferrals,
];

export const MY_CONTRIBUTIONS_INDEX = TAB_CLASSES.indexOf(ProfileContributions);

const Profile = () => {
  const { user_id } = useParams();
  const { getProfileRequest } = useStoreActions((actions) => actions.authentication);
  const { getPublicUserRequest } = useStoreActions((actions) => actions.authentication);
  const { getNotificationSettings } = useStoreActions((actions) => actions.user);
  const { getPublicUserContributionsRequest, currentUserContributionsRequest } = useStoreActions(
    (actions) => actions.contributions
  );
  const { user } = useStoreState((state) => state.user);
  const isPublicProfile = user_id && parseInt(user_id) !== user.user_id;
  const selectedIndex = parseInt(useQuery().get("tabIndex")) || 0;

  useEffect(() => {
    if (user_id) {
      getPublicUserRequest(user_id);
    } else {
      getProfileRequest();
      getNotificationSettings();
    }
  }, []);

  let content;
  const tabProps = {
    user_id: user_id || user.user_id,
    isPublicProfile,
    getContributions: isPublicProfile ? getPublicUserContributionsRequest : currentUserContributionsRequest,
  };

  if (isPublicProfile) {
    content = <ProfileContributions {...tabProps} />;
  } else {
    content = (
      <Container>
        <TabsContainer
          selectedIndex={selectedIndex}
          tabClasses={TAB_CLASSES}
          headers={TAB_HEADERS}
          tabProps={tabProps}
        />
      </Container>
    );
  }

  return (
    <Wrapper>
      <Cover isPublicProfile={isPublicProfile} user={user} />
      <MainContainer>
        <WrapperCard>{content}</WrapperCard>
      </MainContainer>
    </Wrapper>
  );
};

const Container = styled(Box)`
  margin-bottom: 3rem;
  padding: 0 0 24px 0;

  & > div > div:first-child {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    display: flex;
    justify-content: center;
    border-width: 1px 0px;
    border-style: solid;
    border-radius: 0px;
    border-top: none;
    border-color: ${({ theme }) => theme.colors["01Primary100"]};
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  @media ${breakpoints.mobile} {
    margin-bottom: 0;
    ${TabsContainer.BorderedBox} {
      justify-content: unset;
      border-bottom: none;
    }
  }
`;

const Wrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.backgroundThrive};
  flex-grow: 1;

  & > div:last-child {
    @media ${breakpoints.belowDesktop} {
      width: 100%;
    }
  }
`;

const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
  margin-bottom: 60px;
`;

const WrapperCard = styled(Box)`
  background: ${({ theme }) => theme.colors["surfaces"]};
  border-radius: 12px;
  position: relative;

  @media ${breakpoints.desktop} {
    margin: -20px auto 0 auto;
  }
  @media ${breakpoints.tablet} {
    width: 100%;
  }
  @media ${breakpoints.mobile} {
    margin: 0px auto 0 auto;
  }
`;

export default Profile;

import { fireCustomEvent } from "@utils/customEvent";
import axios from "axios";

const noDataMethods = ["get", "delete"];
const TIMEOUT_TIME = 65 * 1000;

export const readToken = () => localStorage.getItem("token");
export const writeToken = (value) => localStorage.setItem("token", value);
export const deleteToken = () => localStorage.removeItem("token");

const headers = () => {
  const token = readToken();
  return token ? { headers: { Authorization: `Bearer ${token}` } } : "";
};

const instances = {};

const cleanUndefined = (data) => {
  if (data && !(data instanceof FormData)) {
    const result = { ...data };
    Object.keys(result).forEach((key) => result[key] === undefined && delete result[key]);
    return result;
  }

  return data;
};

const removeDataAttribute = ({ data, headers }) => {
  const response = data.data;
  response.message = response.message || data.message;
  if (headers.page && headers["per-page"] && headers.total) {
    response.pagination = { page: headers.page, per_page: headers["per-page"], total: headers.total };
  }
  return response;
};

const globalOptions = { transformResponse: [parseResponse] };

function parseResponse(response) {
  return JSON.parse(response);
}

const fireTimeoutEvent = () => fireCustomEvent({ code: "request-timeout" });
const fireUnauthorizedEvent = () => fireCustomEvent({ code: "unauthorized" });

const errorHandler = (error) => {
  if (error.isAxiosError && error.code === "ECONNABORTED") fireTimeoutEvent();
  if (error.response?.status === 401) fireUnauthorizedEvent();

  throw error;
};

const makeRequest = (methodName) =>
  function (_url, options = {}) {
    const url = _url || this.url;
    const params = cleanUndefined(options);
    const config = { ...headers(), timeout: TIMEOUT_TIME };

    if (noDataMethods.includes(methodName.toLowerCase())) {
      return this.requester[methodName](url, { params, ...config })
        .catch(errorHandler)
        .then(removeDataAttribute);
    }

    return this.requester[methodName](url, params, config).then(removeDataAttribute).catch(errorHandler);
  };

export default class HttpWrapper {
  constructor(modelName, apiBase = process.env.REACT_APP_API_BASE_URL, params = {}) {
    const baseURL = `${apiBase}/${modelName}/`;
    // const options = { ...globalOptions, ...params, baseURL, withCredentials: true };
    const options = { ...globalOptions, ...params, baseURL };

    this.get = makeRequest("get");
    this.post = makeRequest("post");
    this.put = makeRequest("put");
    this.delete = makeRequest("delete");
    this.patch = makeRequest("patch");

    this.url = baseURL.replace(/\/$/, "");
    this.requester = axios.create(options);
    //TODO: check this later, for some reason axios is not respecting global settings
    instances[baseURL] = this.requester;
  }

  create(params) {
    return this.post(this.url, params);
  }

  list(options) {
    return this.get(this.url, options);
  }

  // get(_url, options = {}) {
  //   const url = _url || this.url;
  //   const params = cleanUndefined(options);

  //   return this.requester.get(url, { params, ...headers() }).then(removeDataAttribute);
  // }

  find(id, options = {}) {
    const url = [this.url, id, "show"].join("/");

    return this.get(url, options);
  }

  update(id, options) {
    const url = [this.url, id].join("/");
    const params = cleanUndefined(options);

    return this.put(url, params);
  }

  destroy(id, options) {
    const url = [this.url, id].join("/");
    const params = cleanUndefined(options);

    return this.delete(url, params);
  }
}

import { Box, breakpoints, Button } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const TopWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 860px;
  margin: 0 auto 52px auto;
  background: ${({ theme }) => theme.colors["01Primary0"]};
  border-radius: 22px;
  padding: 24px;
  gap: 32px;
  height: 295px;
  box-sizing: border-box;

  @media ${breakpoints.tablet} {
    gap: 24px;
    height: ${({ header, isPublic }) => (!header ? "363px" : isPublic ? "365px" : "415px")};
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    height: ${({ description, isFromEdit, header }) =>
      header ? "unset" : isFromEdit && description ? "625px" : description ? "570px" : "550px"};
    gap: 16px;
    ${({ isFromEdit }) => isFromEdit && "padding-top: 73px"};
  }
`;

export const StyledAvatar = styled.img`
  border-radius: 12px;
  min-width: 244px;
  height: 100%;
  height: 247px;
  width: 247px;

  @media ${breakpoints.mobile} {
    width: 100%;
    height: unset;
    min-height: 299px;
    margin: 0 auto;
  }
`;

export const CornerButton = styled(Button)`
  position: absolute;
  right: 24px;
  top: 24px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors["01Primary700"]};
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  padding: 0;
  @media ${breakpoints.belowDesktop} {
    min-width: 35px;
    height: 35px;
    right: 16px;
    top: 16px;
  }
  &:hover {
    background: transparent;
    border-color: ${({ theme }) => theme.colors["01Primary700"]};
  }
  @media ${breakpoints.mobile} {
    top: 382px;
  }
  svg {
    overflow: overlay;
    path {
      fill: ${({ theme }) => theme.colors["01Primary700"]};
    }
  }
`;

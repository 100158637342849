import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import BottomPart from "./BottomPart";
import TopPart from "./TopPart";

const HeroPart = ({ scrollToBody, focusAboutTab, onRaffleDetails }) => {
  const { id } = useParams();
  const { season, seasonDetails } = useStoreState((state) => state.season);
  const { getActiveSeasonRequest } = useStoreActions((actions) => actions.season);

  useEffect(() => {
    if (id && !season?.isPreview) {
      getActiveSeasonRequest(id);
    }
  }, [id]);

  return (
    <WrapperHero>
      <TopPart season={season} />
      <BottomPart
        season={season}
        seasonDetails={seasonDetails}
        scrollToBody={scrollToBody}
        focusAboutTab={focusAboutTab}
        onRaffleDetails={onRaffleDetails}
      />
    </WrapperHero>
  );
};

const WrapperHero = styled.div`
  position: relative;
  overflow-x: hidden;
`;

export default HeroPart;

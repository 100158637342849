/* eslint-disable react/display-name */
/* eslint-disable max-len */
import { breakpoints } from "@thrivecoin/ui-components";
import { forwardRef } from "react";
import styled from "styled-components";
import { toNumber } from "./PinCode";

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;
const ENTER_KEY = 13;

const PinBox = styled(
  forwardRef((props, ref) => {
    const { references, onComplete, index, className, validationText } = props;

    const onChange = (event) => {
      const input = event.target;
      let numbers = toNumber(input.value);
      input.value = numbers[numbers.length - 1] || "";

      if (input.value) {
        const nextElement = references.current[index + 1];
        onComplete();
        nextElement && nextElement.focus();
      }
    };

    const onKeyDown = (event) => {
      const previousElement = references.current[index - 1];
      const nextElement = references.current[index + 1];
      const character = event.which;

      if ((character === BACKSPACE_KEY || LEFT_ARROW_KEY === character) && previousElement) {
        setTimeout(() => previousElement.focus());
      }

      if (character === BACKSPACE_KEY) {
        references.current[index].value = "";
      }

      if ((character === RIGHT_ARROW_KEY || ENTER_KEY === character) && nextElement) {
        nextElement.focus();
      }
    };

    return (
      <input
        ref={ref}
        type="text"
        min="0"
        max="9"
        data-index={index}
        className={className}
        onChange={onChange}
        onKeyDown={onKeyDown}
        validationText={!!validationText}
      />
    );
  })
)`
  border: none;
  appearance: none;
  background: ${({ theme }) => theme.colors.grey100};
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 4px;
  margin-right: 1em;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 1.25em;
  font-weight: bold;
  ${({ theme, validationText }) => validationText && `border-color: ${theme.colors["error2"]}!important};`}
  &:focus{
    ${({ theme, validationText }) => validationText && `border-color: ${theme.colors["error2"]}!important};`}
  }
  &:focus-visible{
    ${({ theme, validationText }) => validationText && `border-color: ${theme.colors["error2"]}!important};`}
    outline: none;
  }
  @media ${breakpoints.mobile} {
    margin-right: 12px;
  }
`;

export default PinBox;

const toFormdata = (prefix, _data, _formdata) => {
  const data = toObjectFormat(_data); //Ensure all keys are formatted as regular object properties.
  return _toFormObject(data, prefix, _formdata); // Convert them into a rails friendly format
};

const _toFormObject = (object, prefix = "", _formdata) => {
  const formData = _formdata || new FormData();

  Object.entries(object).forEach(([key, value]) => {
    const formattedKey = `${prefix}[${key}]`;

    if (value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item !== null) {
            if (typeof item === "object") {
              Object.entries(item).forEach(([nestedKey, nestedValue]) => {
                const formattedNestedKey = `${formattedKey}[][${nestedKey}]`;
                if (nestedValue !== null) {
                  if (nestedValue instanceof File) {
                    formData.append(formattedNestedKey, nestedValue, nestedValue.name);
                  } else {
                    formData.append(formattedNestedKey, nestedValue);
                  }
                }
              });
            } else {
              formData.append(`${formattedKey}[]`, item);
            }
          }
        });
      } else if (typeof value === "object") {
        if (value instanceof File) {
          formData.append(formattedKey, value, value.name);
        } else if (value instanceof Date) {
          formData.append(formattedKey, value);
        } else {
          _toFormObject(value, formattedKey, formData);
        }
      } else {
        formData.append(formattedKey, value);
      }
    }
  });

  return formData;
};

export const toObjectFormat = (inputObject) => {
  const result = {};
  for (const [key, value] of Object.entries(inputObject)) {
    const matches = key.match(/^([^[]+)\[([^\]]+)\]$/);
    if (matches) {
      const objKey = matches[1];
      const nestedKey = matches[2];
      if (!result[objKey]) {
        result[objKey] = {};
      }
      result[objKey][nestedKey] = value;
    } else {
      result[key] = value;
    }
  }
  return result;
};

export default toFormdata;

import { useEffect, useRef } from "react";
import styled from "styled-components";

const BUTTON_CONFIG = { theme: "outline", size: "large", width: "400", shape: "circle " }; // customization attributes

const GoogleButton = (props) => {
  const ref = useRef();
  useEffect(() => {
    google.accounts.id.renderButton(ref.current, BUTTON_CONFIG);
  }, []);

  return <CustomButton ref={ref} />;
};

const CustomButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

export default GoogleButton;

import { useStoreState } from "easy-peasy";
import StepNavigationBig from "./StepNavigationBig";
import StepNavigationSmall from "./StepNavigationSmall";

const StepNavigation = ({ index, setStep, steps = [] }) => {
  const maxStep = steps?.length;
  const { isDesktop } = useStoreState((state) => state.media);
  const params = { index, maxStep, steps, setStep };
  const Klass = isDesktop ? StepNavigationBig : StepNavigationSmall;

  return <Klass {...params} />;
};

export default StepNavigation;

import { Box } from "@thrivecoin/ui-components";
import { useState } from "react";
import styled from "styled-components";

const TABS_CONFIGS = [
  { label: "All", count: "total" },
  { label: "Active", count: "active" },
  { label: "Claim Opens Soon", count: "claim_opens_soon" },
  { label: "Open to Claim", count: "open_to_claim" },
  { label: "Upcoming Raffle", count: "upcoming_raffle" },
  { label: "Past", count: "completed" },
];

const Tab = ({ status, count, label, selected, onClick }) => {
  const bgColor = selected ? "01Primary700" : "surfaces";
  const color = selected ? "01Primary0" : "01Primary700";

  return (
    <TabBox data-status={status} bgColor={bgColor} pointer onClick={onClick}>
      <TabText bold color={color}>
        {label} ({count})
      </TabText>
    </TabBox>
  );
};

const StatusesTabs = ({ counts, onTabChange }) => {
  const [status, setStatus] = useState("total");
  const filteredTabs = TABS_CONFIGS.filter((item) => !!counts?.[item.count] && counts?.[item.count] > 0);

  const onTabClick = (e) => {
    const dataStatus = e.target.dataset.status;
    if (status !== dataStatus) {
      setStatus(dataStatus);
      onTabChange(dataStatus === "total" ? undefined : dataStatus);
    }
  };

  return (
    <Wrapper flex gap="16px" spacing="mb-6">
      {filteredTabs.map((item, index) => (
        <Tab
          key={index}
          label={item.label}
          status={item.count}
          count={counts?.[item.count] || 0}
          selected={status === item.count}
          onClick={onTabClick}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  overflow-x: auto;
`;

const TabBox = styled(Box)`
  height: 44px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  border-radius: 8px;

  & * {
    pointer-events: none;
  }
`;

const TabText = styled(Box)`
  white-space: nowrap;
`;

export default StatusesTabs;

import { Box, breakpoints, Button, IconSvg, Input, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import PinCode from "./SignInFlowModal/PinCode/PinCode";

const PinCodeVerificationModal = ({ hideModal, onCompleted, ...props }) => {
  const { isDesktop, isTablet, isMobile } = useStoreState((state) => state.media);
  const fullScreen = isTablet || isMobile;
  const showBackToCommunity = fullScreen;
  const [validationText, setvalidationText] = useState(null);

  const completed = (pin) => onCompleted(pin, setvalidationText, hideModal);

  return (
    <StyledModal
      open
      onClose={hideModal}
      leaf={isDesktop}
      spacing={isDesktop ? "px-7 pt-7+6 pb-6+2" : isTablet ? "px-0 pt-6" : "px-0 pt-4"}
      maxWidth={"684px"}
      fullScreen={fullScreen}
      borderRadius={fullScreen ? "0 !important" : isMobile && "1.5rem"}
      contentHeight={fullScreen && "100vh"}
      hideX={fullScreen}
      backText={showBackToCommunity && "Back to community"}
      paddingBody={isMobile && "0"}
    >
      <StyledBox flex={!isDesktop} direction="column" textAlign="center">
        <Page {...props} onCompleted={completed} validationText={validationText} />
      </StyledBox>
    </StyledModal>
  );
};

const Page = ({
  onCompleted,
  digits,
  email,
  reSendEmail,
  showReSend: showReSendParam,
  validationText,
  socialProfile,
}) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [reSend, setReSend] = useState(false);
  const showReSend = showReSendParam && !reSend;

  const onCodeChange = (e) => setCode(e.target.value);

  const onVerify = () => {
    setLoading(true);
    onCompleted({ provider: "email", params: { confirmation_code: code } })
      .catch(({ response }) => {
        setError(response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const onResendClick = () => reSendEmail().then(() => setReSend(true));

  return (
    <Container>
      <Text spacing="mb-4" color="02Primary700" typography="heading/large" extraBold>
        Let’s get you verified!
      </Text>
      <Text spacing="mb-7">
        We’ve just send an email with a verification code to
        <b> {email}</b>. Please enter the {!socialProfile && "6 digit "}code from your email to verify it’s you.
      </Text>
      {!socialProfile && (
        <PinContainer flex justifyContent="center" alignItems="center" direction="column">
          <PinCode digits={digits} validationText={validationText} onCompleted={onCompleted} key={1} />
          {validationText && (
            <Box gap="6px" spacing="mt-4" flex>
              <IconSvg color="error2" icon="alert-triangle" />
              <Text color="error2" align="left" spacing="mt-2 mr-4">
                {validationText}
              </Text>
            </Box>
          )}
        </PinContainer>
      )}
      {socialProfile && (
        <StyledInput
          spacing="mb-6"
          placeholder="Enter Code"
          value={code}
          onChange={onCodeChange}
          validationText={error}
        />
      )}

      {showReSend && (
        <StyledText pointer color="#5159C9" link onClick={onResendClick} bold>
          Send email again
        </StyledText>
      )}
      {socialProfile && (
        <Button
          size="lg"
          spacing="mt-5"
          borderRadius="40px"
          disabled={!code || loading}
          loading={loading}
          width="100%"
          onClick={onVerify}
          modal={true}
        >
          Verify Email
        </Button>
      )}
    </Container>
  );
};

Page.defaultProps = {
  digits: 6,
};

const StyledModal = styled(Modal)`
  margin-top: 0;
`;

const StyledBox = styled(Box)`
  @media $${breakpoints.tablet} {
    height: 100%;
  }
  @media ${breakpoints.mobile} {
    height: 100%;
  }
`;

const Container = styled(Box)`
  @media ${breakpoints.tablet} {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 60px;
    max-width: 486px;
  }

  @media ${breakpoints.mobile} {
    padding: 5rem 1rem 0;
  }
`;

const PinContainer = styled(Box)`
  margin-bottom: 3rem;

  @media ${breakpoints.mobile} {
    margin-bottom: 2rem;
  }
`;

const StyledText = styled(Text)`
  &:hover {
    text-decoration: underline;
  }

  @media ${breakpoints.desktop} {
    margin-bottom: 10px;
  }
`;

const StyledInput = styled(Input)`
  & input {
    background: ${({ theme }) => theme.colors["02Primary0"]};
    border-color: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors["02Primary700"]};
  }
`;

export default PinCodeVerificationModal;

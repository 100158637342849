export { default as DEFAULT_PROFILE_IMAGE_DARK } from "@assets/profile-default-dark.svg";
export { default as DEFAULT_PROFILE_IMAGE } from "@assets/profile-default.svg";

export const MANAGER_ROLES = ["owner", "manager", "admin"];

export const LAST_VISITED_COMMUNITY_KEY = "last_visited_communities";

export const recentCommunityIDs = () =>
  (localStorage.getItem(LAST_VISITED_COMMUNITY_KEY) || "").split(",").filter(Boolean);

export const saveRecentCommunityID = (id) => {
  let ids = recentCommunityIDs();
  const idExists = ids.findIndex((val) => val == id) > -1;
  if (!idExists) {
    ids = [id, ...ids];
    ids = Array.from(new Set(ids));

    localStorage.setItem(LAST_VISITED_COMMUNITY_KEY, ids.slice(0, 2));
  }
};

export const CHALLENGE_PAGE_TO_COMMUNITY_PATH = "../..";

export const PERSONAL_LEGEND_PATH = "/current_user/profile?tabIndex=1";

//TODO: i think this should be relative tot he reducers, folder wise
export const DEFAULT_PAGINATION = {
  page: 0,
  total: 0,
  per_page: 5,
};

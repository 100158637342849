import ConfettiIcon from "@assets/confettis.svg";
import preview_image from "@assets/preview-discourse2.gif";
import { Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { useDiscourseContext } from "./DiscourseContext";
import useGetDiscourseUrl from "./useGetDiscourseUrl";

const CongratsBody = ({ username, provider }) => {
  const forum = useGetDiscourseUrl(provider);
  const profileLink = `${forum}/u/${username}/preferences/profile`;

  return (
    <>
      <StyledImage src={ConfettiIcon} />
      <Text typography="heading/medium" align="center" spacing="mb-5">
        Congratulations, your username was successfully verified.
      </Text>
      <Text align="center" spacing="mb-6">
        <b>
          You can now delete the code from{" "}
          <StyledLink href={profileLink} target="_blank" rel="noreferrer">
            your profile
          </StyledLink>
        </b>
        . Your account on this forum will remain verified even if you change your username.
      </Text>
      <img src={preview_image} />
    </>
  );
};

const StyledLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.colors["01Link"]};
`;

const StyledImage = styled.img`
  position: absolute;
  left: 4%;
  top: 1%;
`;

const Footer = ({ provider, hideModal }) => {
  const { shareMore, close } = useDiscourseContext();
  const handleShareMore = () => {
    shareMore(provider, hideModal);
  };

  const handleClose = () => {
    close(provider, hideModal);
  };
  return (
    <>
      <Button
        size="lg"
        borderRadius="50px"
        spacing="mb-6 mt-6+2"
        width="100%"
        bold
        onClick={handleShareMore}
        modal={true}
      >
        Connect More Networks For More Rewards
      </Button>
      <Button
        size="lg"
        borderRadius="50px"
        kind="outline"
        color="02Primary500"
        variant="grey300"
        bold
        onClick={handleClose}
        modal={true}
        width="100%"
      >
        Keep Contributing
      </Button>
    </>
  );
};

CongratsBody.Footer = Footer;

export default CongratsBody;

import styled from "styled-components";
import { ListingEditorProvider } from "./ListingEditorContext";
import ListingEditorLayout from "./ListingEditorLayout";

const ListingEditor = ({ listingID, communityID }) => {
  return (
    <ListingEditorProvider id={listingID} communityID={communityID}>
      <TopLevelContainer>
        <ListingEditorLayout id={listingID} />
      </TopLevelContainer>
    </ListingEditorProvider>
  );
};

export const TopLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex: 1;
  padding: 0;

  * {
    text-decoration: none;
  }
`;

export default ListingEditor;

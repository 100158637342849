import { useEffect } from "react";

const useReferralCode = () => {
  const params = new URLSearchParams(window.location.search);
  const referral_code = params.get("referral_code");

  useEffect(() => {
    referral_code && sessionStorage.setItem("referral_code", referral_code);
  }, [referral_code]);
};

export default useReferralCode;

import { normalize } from "@utils/socialUtils";
import { SOCIALS_NO_DISCORD } from "../../../constants";
import ConnectionButton from "./ConnectionButton";

const ConnectionButtons = ({
  canDisconnect,
  countByApp,
  showCount,
  networks,
  children,
  onModal,
  isModal,
  ...props
}) => {
  const buttons = networks.map((social) => {
    const config = normalize(social);
    const connected = props[`${social}Connected`];
    const count = countByApp[config.appName];

    return (
      <ConnectionButton
        key={social}
        showCount={showCount}
        canDisconnect={canDisconnect}
        social="discord"
        connected={connected}
        count={count}
        onModal={onModal}
        isModal={isModal}
        {...config}
      />
    );
  });

  return (
    <>
      {buttons}
      {children}
    </>
  );
};

ConnectionButtons.defaultProps = {
  canDisconnect: true,
  showCount: true,
  networks: SOCIALS_NO_DISCORD,
};

export default ConnectionButtons;

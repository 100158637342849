import { Box, breakpoints } from "@thrivecoin/ui-components";
import { useContext } from "react";
import styled from "styled-components";
import { ListingEditorContext } from "./ListingEditorContext";
import PreviewListing from "./PreviewListings";
import Steps from "./Steps";

const ListingEditorLayout = ({ id }) => {
  const { previewMode } = useContext(ListingEditorContext);

  return (
    <MainContainer grow={1} bgColor="01Surface" color="01Primary700">
      <Steps id={id} />
      {previewMode && <PreviewListing />}
    </MainContainer>
  );
};

const MainContainer = styled(Box)`
  width: 100%;
  position: relative;

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

export default ListingEditorLayout;

import { Box, breakpoints, Button, Countdown, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { useEffect } from "react";
import styled from "styled-components";
import { isSeasonCompleted } from "../../common";
import useColors from "../../useColors";
import ClaimingOpportunity from "./ClaimingOpportunity";

import COMING_SOON from "@assets/coming_soon.svg";
import CONFETTIS_HAND from "@assets/confettis_hand.svg";
import CONFETTI from "@assets/confetti_new.svg";
import { reconnectWalletProvider } from "@utils/web3/useSetUpWeb3Provider";

const RaffleCountdown = ({ raffleDate }) => (
  <CountdownWrapper bgColor="headerBackground">
    <Box flex alignItems="center" color="01Primary0">
      <IconSvg icon="pacman" width="18px" />
      <Text color="01Primary700" typography="body/small" bold spacing="ml-2 mr-4">
        Raffle Countdown
      </Text>
    </Box>
    <Countdown color="01Primary700" bgColor="01Primary0" countdown={raffleDate} />
  </CountdownWrapper>
);

const RaffleCompleted = () => (
  <RaffleTextBox spacing="mt-2" lineHeight="17px">
    <Text typography="body/small" bold fontFamily="Outfit">
      Prizes have been handed out! Check below to see the prizes.
    </Text>
  </RaffleTextBox>
);

const DonationsCompleted = ({ organization }) => (
  <DonationsTextBox spacing="mt-2" lineHeight="17px">
    <Text typography="body/small" bold fontFamily="Outfit">
      Based on your preferences, your earnings have been successfully donated to {organization?.name}!
    </Text>
  </DonationsTextBox>
);

const ClaimingContent = ({ claimed }) => (
  <ClaimingTextBox claimed={claimed} spacing="mt-2" lineHeight="17px" sm-spacing="mb-3 mr-0">
    <Text typography="body/small" bold fontFamily="Outfit">
      {claimed ? "Congratulations! Your Earnings have been Successfully Claimed!" : "You can now claim your earnings."}
    </Text>
  </ClaimingTextBox>
);

const ClaimingSoonContent = ({ isMobile }) => (
  <ComingSoontTextBox claimed={true} spacing="mt-2" lineHeight="17px" sm-spacing="mb-3 mr-0">
    <Text typography="body/small" bold fontFamily="Outfit">
      Opportunity to claim funds will be available soon...
      {isMobile ? " " : <br />}
      Please revisit this page again.
    </Text>
  </ComingSoontTextBox>
);

const NoContributions = () => (
  <NoContributionsBox spacing="mt-2" lineHeight="16px">
    <Text typography="body/small" bold fontFamily="Outfit">
      Even though you have not contributed this season, no need to worry, there are many other ways to contribute.
    </Text>
  </NoContributionsBox>
);

const AutomaticDistributionContent = () => (
  <AutomaticDistributionBox spacing="mt-2" lineHeight="17px">
    <Text typography="body/small" bold fontFamily="Outfit">
      Congratulations! Your Earnings have been distributed to ‘Community Name’. Thank you for your contributions!
    </Text>
  </AutomaticDistributionBox>
);

const Contents = {
  noContributions: NoContributions,
  raffleCountdown: RaffleCountdown,
  raffleCompleted: RaffleCompleted,
  donationsCompleted: DonationsCompleted,
  claimingOpportunity: ClaimingContent,
  claimingSoon: ClaimingSoonContent,
  automaticDistribution: AutomaticDistributionContent,
};

const getContentName = (
  contributed,
  isRaffleSystem,
  isRaffleCompleted,
  organization,
  automaticDistribution,
  noContract
) => {
  let contentName = "noContributions";
  if (contributed) {
    if (isRaffleSystem) {
      contentName = isRaffleCompleted ? "raffleCompleted" : "raffleCountdown";
    } else {
      if (automaticDistribution) {
        contentName = "automaticDistribution";
      } else if (organization) {
        contentName = "donationsCompleted";
      } else if (noContract) {
        contentName = "claimingSoon";
      } else {
        contentName = "claimingOpportunity";
      }
    }
  }

  return contentName;
};

const text = {
  completed: "Season complete!",
  open_to_claim: "Season complete!",
  raffle_completed: "The Raffle has ended!",
};

const CompletedSeasonState = ({ season, seasonDetails, onCommunityDetails }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const { user } = useStoreState((state) => state.user);
  const { getRaffleEntries } = useStoreActions((actions) => actions.raffle);
  const raffleDate = moment(season.raffle_draw_date);
  const isRaffleCompleted = season.status === "raffle_completed" || season.status === "completed";
  const isRaffleSystem = season.reward_system === "raffle";
  const contributed = +seasonDetails.rewards_earned > 0;
  const claimed = seasonDetails?.earnings_status === "claimed";
  const automaticDistribution = season?.reward_distribution_type === "automatic";
  const organization =
    !!seasonDetails.donated_to && season.donation_options.filter(({ id }) => id === seasonDetails.donated_to)[0];
  const { contract_address } = season.reward_claiming_config || {};
  const showClaiming = contributed && !isRaffleSystem && !organization && !automaticDistribution && !!contract_address;
  const showOrganization = !isRaffleSystem && contributed && organization;
  const showComingSoon = !!contributed && isSeasonCompleted(season.status) && !isRaffleSystem && !contract_address;
  const buttonText = contributed ? "See your Raffle Entries" : "More Contributions Opportunities";

  const Klaas =
    Contents[
      getContentName(
        contributed,
        isRaffleSystem,
        isRaffleCompleted,
        organization,
        automaticDistribution,
        !contract_address,
        season.status
      )
    ];

  const showHandIcon = showOrganization || claimed || (automaticDistribution && contributed);
  const { theme } = useColors(season);

  useEffect(() => {
    user.id && getRaffleEntries(season.id);
  }, [season.id, user.id]);

  useEffect(() => {
    if (contract_address) {
      reconnectWalletProvider(true);
    }
  }, [contract_address]);

  return (
    <Wrapper bgColor="headerGradient" relative isRaffleCompleted={isRaffleCompleted}>
      <Container bgColor={theme.accentColor} relative isRaffleCompleted={isRaffleCompleted} claiming={showClaiming}>
        <StatusBox claiming={showClaiming}>
          <TextWrapper flex>
            <StyledText typography="heading/small" color="02Primary0">
              {text[season.status]}
            </StyledText>
            {!isRaffleCompleted && isRaffleSystem && contributed && (
              <Text bold fontFamily="Outfit" typography="body/small" lineHeight="24px" color={theme.completedColor}>
                We will be raffling off prizes soon.
              </Text>
            )}
          </TextWrapper>
          <Box color={theme.completedColor}>
            <Klaas
              raffleDate={raffleDate}
              organization={organization}
              claimed={claimed}
              theme={theme}
              isMobile={isMobile}
            />
          </Box>
        </StatusBox>
        {showClaiming && !claimed && <ClaimingOpportunity season={season} seasonDetails={seasonDetails} />}
        {isRaffleCompleted && contributed && <ConfettiImg src={CONFETTI} />}
        {(isRaffleSystem || !contributed) && (
          <StyledButton
            variant="02Primary0"
            color="02Primary500"
            borderColor="02Primary0"
            borderRadius="40px"
            height="54px"
            contributed={contributed}
            onClick={onCommunityDetails}
            hoverBackground="02Primary0"
          >
            {buttonText}
          </StyledButton>
        )}
        {showComingSoon && <Confetti2Img claimed src={COMING_SOON} comingSoon />}
        {showOrganization && <OrgLogo src={organization?.image_url} />}
        {showHandIcon && <Confetti2Img claimed={claimed || automaticDistribution} src={CONFETTIS_HAND} />}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  border-radius: 8px 8px 0 0;
  padding: 1rem;

  ${({ isRaffleCompleted }) => isRaffleCompleted && "width: 720px;"}

  @media ${breakpoints.tablet} {
    ${({ isRaffleCompleted }) => isRaffleCompleted && "width: 560px;"}
  }

  @media ${breakpoints.miniTablet}, ${breakpoints.mobile} {
    width: unset;
  }
`;

const Container = styled(Box)`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 10px 16px;
  gap: 24px;

  ${({ isRaffleCompleted }) => isRaffleCompleted && "justify-content: space-between;"}

  @media ${breakpoints.tablet} {
    flex-direction: column;
    align-items: unset;
    gap: ${({ claiming }) => (claiming ? "10px" : "2rem")};
    padding: 24px;
  }

  @media ${breakpoints.miniTablet} {
    padding: 10px 1rem 20px;
    gap: ${({ claiming }) => (claiming ? "10px" : "24px")};
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: unset;
    gap: ${({ isRaffleCompleted, claiming }) => (isRaffleCompleted ? "50px" : claiming ? "10px" : "20px")};
    padding: 20px;
  }
`;

const CountdownWrapper = styled(Box)`
  margin-top: 10px;
  border-radius: 10px;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 12px;
  width: 470px;

  @media ${breakpoints.miniTablet} {
    width: unset;
    margin-top: 24px;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: unset;
    height: unset;
    width: unset;
    padding: 8px 4px 10px;
    gap: 10px;
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
  font-size: 14px;
  width: ${({ contributed }) => (contributed ? "186px" : "294px")};

  @media ${breakpoints.tablet} {
    width: unset;
  }

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

const TextWrapper = styled(Box)`
  align-items: flex-end;
  gap: 24px;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: unset;
    gap: unset;
  }
`;

const ConfettiImg = styled.img`
  position: absolute;
  left: 55%;
  bottom: 15px;
  width: 74px;

  @media ${breakpoints.tablet} {
    bottom: unset;
    top: 20px;
    right: 24px;
    left: unset;
  }

  @media ${breakpoints.mobile} {
    left: unset;
    bottom: unset;
    right: 30px;
    top: 58px;
  }
`;

const RaffleTextBox = styled(Box)`
  @media ${breakpoints.mobile} {
    width: 140px;
  }
`;

const DonationsTextBox = styled(Box)`
  max-width: 290px;

  @media ${breakpoints.tablet} {
    margin-right: 200px;
  }

  @media ${breakpoints.miniTablet} {
    margin-right: unset;
    max-width: 225px;
  }
`;

const ClaimingTextBox = styled(Box)`
  ${({ claimed }) => (claimed ? "margin-right: 400px;" : "max-width: 115px;")}

  @media ${breakpoints.tablet} {
    max-width: unset;
    ${({ claimed }) => !claimed && "margin-left: 24px;"}
    margin-right: unset;
  }

  @media ${breakpoints.miniTablet} {
    ${({ claimed }) =>
      claimed &&
      `
    max-width: 225px;
    margin-bottom: 100px;
    `}
  }

  @media ${breakpoints.mobile} {
    max-width: unset;
    margin-right: 0;
  }
`;

const ComingSoontTextBox = styled(Box)`
  margin-right: 400px;

  @media ${breakpoints.tablet} {
    max-width: unset;
    margin-right: unset;
  }

  @media ${breakpoints.miniTablet} {
    margin-bottom: 100px;
  }

  @media ${breakpoints.mobile} {
    max-width: unset;
    margin-right: 0;
  }
`;

const NoContributionsBox = styled(Box)`
  max-width: 430px;

  @media ${breakpoints.mobile} {
    margin-bottom: 55px;
  }
`;

const OrgLogo = styled.img`
  margin-left: 115px;
  margin-right: 300px;
  height: 45px;

  @media ${breakpoints.tablet} {
    margin-left: unset;
    margin-right: auto;
    margin-top: 32px;
  }

  @media ${breakpoints.miniTablet} {
    margin-top: 22px;
  }

  @media ${breakpoints.mobile} {
    margin-left: unset;
    margin-right: unset;
    object-fit: contain;
    margin-right: auto;
  }
`;

const Confetti2Img = styled.img`
  position: absolute;
  right: 15px;
  bottom: -1px;

  @media ${breakpoints.tablet} {
    ${({ claimed }) =>
      claimed &&
      `
    right: unset;
    bottom: unset;
    position: relative;
    height: 102px;
    margin-bottom: -25px;
  `}
  }

  @media ${breakpoints.miniTablet} {
    ${({ claimed }) =>
      claimed &&
      `
      position: absolute;
      right: 15px;
      bottom: -2px;
      height: 122px;
    margin-bottom: unset;
  `}

    ${({ comingSoon }) =>
      comingSoon &&
      `
      height: 147px;
  `}
  }

  @media ${breakpoints.mobile} {
    position: relative;
    right: unset;
    bottom: unset;
    margin-bottom: -22px;

    ${({ comingSoon }) =>
      comingSoon &&
      `
      height: 147px;
  `}
  }
`;

const StatusBox = styled(Box)`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    ${({ claiming }) => claiming && "flex-direction: row;"}
  }
`;

const AutomaticDistributionBox = styled(Box)`
  max-width: 400px;
  margin-right: 400px;

  @media ${breakpoints.tablet} {
    margin-right: 60px;
  }

  @media ${breakpoints.miniTablet} {
    max-width: 225px;
    margin-bottom: 60px;
  }

  @media ${breakpoints.mobile} {
    max-width: unset;
    margin-right: unset;
  }
`;

const StyledText = styled(Text)`
  white-space: nowrap;
`;

export default CompletedSeasonState;

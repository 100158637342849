import rewardFoundImage from "@assets/rewardFoundImage.svg";
import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import { PERSONAL_LEGEND_PATH } from "../../Constants";
import { trackEventCategory } from "../../hooks/useTrackEvents";
import useWeb3 from "../../hooks/useWeb3";
import { PaddedButton } from "../common";
import FirstThrive from "../FirstThrive";

const BG_COLOR = "#EDF9EF";

const RewardsFoundStep = ({ closeModal, goToStep }) => {
  const navigate = useNavigate();
  const { providerName } = useWeb3();
  const { reward } = useStoreState((state) => state.user);
  const onClick = () => {
    trackEventCategory("UO_RF_View_Rewards");
    goToStep(STEP_INDEXES.AddNetworkStep);
  };

  const dismiss = () => {
    trackEventCategory("UO_RF_Dismiss_Rewards");
    closeModal();
    navigate(PERSONAL_LEGEND_PATH);
  };

  return (
    <Box sm-spacing="mx-4">
      <Text typography="heading/large" spacing="mb-6" sm-spacing="mb-4 mt-6" color="02Primary500">
        Congratulations! Past contributions found!
      </Text>

      <Box flex justifyContent="center" spacing="mb-6">
        <FirstThrive amount={reward} text="You've earned" />
      </Box>

      <Box maxWidth="429px" spacing="mx-a">
        <PaddedButton spacing="mb-4" typography="body/medium" onClick={onClick} modal={true}>
          See Your Rewards in {providerName}
        </PaddedButton>

        <PaddedButton
          onClick={dismiss}
          spacing="mb-8+8+7"
          typography="body/medium"
          bgColor={BG_COLOR}
          color="02Primary500"
          kind="outline"
          borderColor="02Primary200"
          variant="#BDBDBD"
          modal={true}
        >
          I want to do this later
        </PaddedButton>
      </Box>
    </Box>
  );
};

const Footer = () => (
  <Box height="132px" bgColor="#81C394">
    <StyledImage src={rewardFoundImage} />
  </Box>
);

const StyledImage = styled.img`
  position: absolute;
  bottom: 73px;
  left: 240px;
  height: 170px;

  @media ${breakpoints.mobile} {
    left: 50%;
    transform: translateX(-50%);
  }
`;

RewardsFoundStep.bgColor = BG_COLOR;
RewardsFoundStep.maxWidth = "810px";
RewardsFoundStep.Footer = Footer;

export default RewardsFoundStep;

import { thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("user_audits");

const attributes = () => ({});

const actions = {
  unBan: thunk((_, { user_id, additional_notes, message }) => {
    return requester.post(`${user_id}/unban`, { additional_notes, message });
  }),
  unFlag: thunk((_, { user_id, additional_notes, message }) => {
    return requester.post(`${user_id}/unflag`, { additional_notes, message });
  }),
};

const userAudits = {
  ...attributes(),
  ...actions,
};

export default userAudits;

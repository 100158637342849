import { Box, Button, IconSvg, ListingConditions, Loader, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";

const DEFAULT_STATE = { conditions: {} };

const ActivityDetailsModal = ({ hideModal, id }) => {
  const [loading, setLoading] = useState(true);
  const [activity, setActivity] = useState(DEFAULT_STATE);
  const [refresh, setRefresh] = useState(false);
  const [modalActivityOpen, setModalActivityOpen] = useState(true);

  const { isMobile } = useStoreState((state) => state.media);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { getActivityDetails } = useStoreActions((actions) => actions.listings);
  const completionsLeft = activity.current_user_contributions_left || 0;
  const completeMultipleTimes = activity.unlimited_contributions_per_user || false;
  const showContributionsPerUser =
    !activity.completed && (completeMultipleTimes || activity.contributions_per_user > 1);

  const onRefresh = () => setRefresh(!refresh);
  const manuallyComplete = (activity) => {
    showModal({ modalName: "ConfirmContributionModal", activity, callback: onRefresh });
    setModalActivityOpen(false);
  };
  const onManuallyComplete = () => manuallyComplete(activity);

  const Footer = () => (
    <Box spacing="px-8+3+2 pb-8+1 pt-6" direction={isMobile && "column"} sm-spacing="px-6 pb-6 pt-0" flex gap="22px">
      <Button
        size="lg"
        bold
        borderRadius="40px"
        height="56px"
        width={!isMobile && "177px"}
        kind="outline"
        borderColor="tertiary350"
        color="02Primary700"
        onClick={hideModal}
        modal={true}
      >
        Back
      </Button>
      <Button onClick={onManuallyComplete} size="lg" bold borderRadius="40px" height="56px" grow={1} modal={true}>
        I did this
      </Button>
    </Box>
  );

  const footerContent = <Footer />;

  useEffect(() => {
    getActivityDetails(id)
      .then(({ activity }) => setActivity(activity))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Modal
      open={modalActivityOpen}
      onClose={hideModal}
      leaf={!isMobile}
      maxWidth="684px"
      footerContent={footerContent}
      sm-spacing="px-6 pb-4"
      borderRadius={isMobile && "40px"}
    >
      <Box>
        {loading ? (
          <Box spacing="my-6" flex justifyContent="center">
            <Loader />
          </Box>
        ) : (
          <Box color="02Primary700">
            <Text typography="heading/medium" spacing="mb-4">
              {activity.name}
            </Text>
            <Text>{activity.description}</Text>
            <Box height="1px" bgColor="01Primary200" spacing="my-5" />
            <Box flex alignItems="center">
              <Box>
                <Text typography="body/small" extraBold spacing="mb-1">
                  Reward:
                </Text>
                <Box flex alignItems="center" gap="6px">
                  <IconSvg icon="logo-gold-circle" width="16px" />
                  <StyledText extraBold color="accent550">
                    {+activity.reward_amount} $THRIVE
                  </StyledText>
                </Box>
              </Box>
              {!!showContributionsPerUser && (
                <>
                  <Box height="12px" width="1px" bgColor="#c4c4c4" spacing="ml-2 mr-4" />
                  <IconSvg icon="repeat" width="15px" color="02Primary700" spacing="mr-2" />
                  <Text typography="body/small" bold>
                    Can be completed{" "}
                    {completeMultipleTimes
                      ? "multiple"
                      : completionsLeft + `${completionsLeft !== activity.contributions_per_user ? " more" : ""}`}{" "}
                    times
                  </Text>
                </>
              )}
            </Box>
            <Box height="1px" bgColor="01Primary200" spacing="my-5" />
            <ListingConditions conditions={activity.conditions} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const StyledText = styled(Text)`
  white-space: nowrap;
`;

export default ActivityDetailsModal;

import getENSData from "@utils/getENSData";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { shouldDeepLink } from "../../utils/shouldDeepLink";
import { doLogout, getConnectorName } from "../../utils/web3/useSetUpWeb3Provider";
import { CHAIN_PARAMS, getInstance } from "../../utils/web3wrapper";
import { DISCORD_KEY } from "../DiscordSignUpPage";

const ERROR_MESSAGES = {
  4001: "Accept the connection to see earned tokens in your wallet provider.",
  internal_error: "Something went wrong while communicating with your wallet provider! Please notify us!",
};

const SIGN_IN_ERROR = {
  4001: "You must sign the message in your wallet provider in order to log in",
  422: "Something went wrong while logging you in! Please contact support@thrivecoin.com!",
};

const LOGIN_TIMEOUT = 20 * 1000;

const registrationMessage = (nonce, address) =>
  [
    "Welcome to ThriveCoin! \n",
    "By connecting your wallet you’re agreeing to the ThriveCoin Terms of Service https://app.thrivecoin.com/terms_services \n",
    "This request will not trigger a blockchain transaction or cost any gas fees. \n",
    "Wallet address: \n",
    address.toLowerCase(),
    "\nNonce: \n",
    nonce,
  ].join("\n");

export const walletOwnershipMessage = (nonce, address) =>
  ["Verify that it is you ", address.toLowerCase(), ", ", nonce, "!"].join("");

export const isOnPolygon = (chainID = window.ethereum?.networkVersion) => chainID == parseInt(CHAIN_PARAMS.chainId, 16);

const useWeb3 = () => {
  const { isMobile } = useStoreState((state) => state.media);
  const { authLoading } = useStoreState((state) => state.authentication);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { getNonce, signatureSignIn, setAuthLoading } = useStoreActions((state) => state.authentication);
  const { dangerToast, infoToast, clear: clearToasts } = useStoreActions((actions) => actions.toasts);
  const instance = getInstance();
  const providerName = getConnectorName() || "metamask";
  const hasWeb3Provider = Boolean(window.ethereum);
  const _isOnPolygon = isOnPolygon();

  const {
    addNetwork: _addNetwork,
    switchNetwork,
    connectToAccounts,
    addThriveToken,
    getChainType,
    switchToPolygonNetwork,
    switchToEtheterumNetwork,
    getUserSignature,
  } = instance;

  const mobileSignatureNotice = () => {
    if (isMobile) {
      infoToast({
        content: "Complete the signature request in your wallet provider to finish Signing In.",
      });
    }
  };

  const addToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => addThriveToken().then(resolve).catch(reject), 600);
    });
  };

  const errorHandler = ({ code, message }) => {
    const content = ERROR_MESSAGES[code] || ERROR_MESSAGES["internal_error"];
    dangerToast({ content, timeout: 5000 });

    return Promise.reject(message || content);
  };

  const signInErrorHandler = ({ code, message, response }) => {
    const status = response?.status || "";
    let content = SIGN_IN_ERROR[code] || SIGN_IN_ERROR[status] || ERROR_MESSAGES["internal_error"];
    if (status === 422 && response.data.message) {
      content = response.data.message;
    }
    dangerToast({ content, timeout: 5000 });

    return Promise.reject(message || content);
  };

  const connect = () => {
    return connectToAccounts()
      .then((accounts) => {
        return accounts;
      })
      .catch(errorHandler);
  };

  const addNetwork = (handler) => {
    return _addNetwork()
      .then((data) => {
        return data;
      })
      .catch(errorHandler);
  };

  const addPolygonToken = () => switchToPolygonNetwork().then(addToken).catch(errorHandler);
  const addEthereumToken = () => switchToEtheterumNetwork().then(addToken).catch(errorHandler);

  const doLogin = async (referral_code) => {
    const addresses = await connect();
    const address = addresses[0];
    const nonce = await getNonce(address);
    const message = registrationMessage(nonce, address);
    mobileSignatureNotice();
    const signature = await getUserSignature(message, address);
    const discord_generated_uid = localStorage.getItem(DISCORD_KEY);
    const signatureParams = { address, signature, referral_code, discord_generated_uid };
    const { created_at } = await signatureSignIn(signatureParams);
    localStorage.removeItem(DISCORD_KEY);
    clearToasts();

    const minutesSinceCreated = moment().diff(moment(created_at), "m");
    if (minutesSinceCreated < 10) {
      const ensData = await getENSData(address);
      if (ensData) {
        showModal({ modalName: "UpdateProfileModal", modalOrder: 9, profile: ensData, provider: "ENS" });
      }
      showModal({ modalName: "ConnectSocialMediasModal" });
    }
  };

  const loginProcess = async (referral_code) => {
    if (!authLoading) {
      setAuthLoading(true);
      let timeout;

      return new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          reject({ code: 4001, message: "The login flow has timed out." });
        }, LOGIN_TIMEOUT);

        doLogin(referral_code).then(resolve).catch(reject);
      })
        .catch((error) => {
          doLogout();
          signInErrorHandler(error);
        })
        .finally(() => {
          clearTimeout(timeout);
          setAuthLoading(false);
        });
    }
  };

  const installMetamask = () => {
    let url = "https://metamask.io/";

    if (shouldDeepLink()) {
      const path = location.host + location.pathname;
      url = `https://metamask.app.link/dapp/${path}`;
    }

    window.open(url, "_blank", "noopener,noreferrer,resizable");
  };

  const loginFlow = (referral_code) => {
    showModal({ modalName: "WalletConnectorModal", afterClose: loginProcess, referral_code });
  };

  return {
    loginFlow,
    switchNetwork,
    addNetwork,
    switchToPolygonNetwork,
    switchToEtheterumNetwork,
    connect,
    addPolygonToken,
    addEthereumToken,
    addThriveToken,
    getChainType,
    addToken,
    installMetamask,
    providerName,
    hasWeb3Provider,
    _isOnPolygon,
  };
};

export default useWeb3;

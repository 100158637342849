import { useStoreState } from "easy-peasy";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const useCustomMetrics = () => {
  const user = useStoreState((state) => state.user.user);
  useEffect(() => {
    if (user.id) {
      ReactGA.set({ userId: user.id });
      ReactGA.set({ dimension1: "Connected" });
      ReactGA.set({ dimension2: user.user_type });
      ReactGA.set({ dimension3: user.referrer });
    } else {
      ReactGA.set({ userId: null });
      ReactGA.set({ dimension1: null });
      ReactGA.set({ dimension2: null });
      ReactGA.set({ dimension3: null });
    }
  }, [user.id]);
};

export default useCustomMetrics;

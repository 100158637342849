import { useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWeb3 from "./useWeb3";

const useSignInParam = () => {
  const navigate = useNavigate();
  const { user } = useStoreState((actions) => actions.user);
  const params = new URLSearchParams(window.location.search);
  const fireModal = params.get("signin");
  const { loginFlow } = useWeb3();

  useEffect(() => {
    if (fireModal) {
      !user.id && loginFlow();
      navigate(window.location.pathname);
    }
  }, []);
};

export default useSignInParam;

import SharePageButton from "@common/SharePageButton";
import { Box, IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";

const config = [
  {
    position: "absolute",
    top: "16px",
    right: "0",
    bgColorEdit: "button",
    borderColorEdit: "03Primary500",
    colorEdit: "02Primary0",
  },
];

const EditShare = ({ isAdmin, cover_media, bgImage, editHeader, subheadline, logo }) => {
  const CONFIG = config[0];
  const noMediaOrSubheading = !subheadline && !logo && !cover_media;
  const fillColor = bgImage && !cover_media ? "02Primary0" : "02Primary500";
  return (
    <EditShareWrapper
      position={CONFIG.position}
      right={CONFIG.right}
      top={noMediaOrSubheading ? "50%" : CONFIG.top}
      transform={noMediaOrSubheading && "translateY(-50%)"}
      gap="12px"
      flex
    >
      <SharePageButton />
      {isAdmin && (
        <Edit background={CONFIG.bgColorEdit} borderColor={CONFIG.borderColorEdit} onClick={editHeader}>
          <StyledPen fill={fillColor} icon="pen" width="17px" />
        </Edit>
      )}
    </EditShareWrapper>
  );
};

const EditShareWrapper = styled(Box)`
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  transform: ${({ transform }) => transform};
`;

const StyledPen = styled(IconSvg)`
  fill: ${({ theme, fill }) => theme.colors[fill] || fill};

  path {
    fill: ${({ theme, fill }) => theme.colors[fill] || fill};
  }
`;

const Edit = styled.div`
  width: 44px;
  height: 44px;
  background: ${({ theme }) => theme.colors["02Primary0"]};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors["02Primary0"]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: auto;
  display: flex;
  cursor: pointer;
`;

export default EditShare;

import { Box, breakpoints, Modal } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { data } from "./data";
import Header from "./Header";
import NotifyStep from "./NotifyStep";
import ReasonStep from "./ReasonStep";

const replaceUnbanWithType = (text, type) => {
  return text.replace(/unban(ed)?/gi, type);
};

export const STEP_INDEXES = {
  ReasonStep: 0,
  NotifyStep: 1,
};

const STEP_KLASSES = [ReasonStep, NotifyStep];

const UndoActionsModal = ({ hideModal, initialStep, afterClose, type, image, username, id, profileData, ...props }) => {
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const [step, goToStep] = useState(initialStep);
  const StepKlass = STEP_KLASSES[step];
  const { isAdmin, isAdminOfCommunity } = useStoreState((state) => state.user);
  const { unBan, unFlag } = useStoreActions((actions) => actions.userAudits);
  const selectedData = isAdmin ? data.isAdmin : isAdminOfCommunity ? data.isAdminOfCommunity : null;

  const { title, description, btn } = selectedData || {};
  const _title = replaceUnbanWithType(title, type);
  const _description = replaceUnbanWithType(description, type);
  const _btn = replaceUnbanWithType(btn, type);

  const closeModal = () => {
    hideModal();
    afterClose && afterClose();
  };

  const [additionalNotes, setAditionalNotes] = useState("");
  const [message, setMessage] = useState("");
  const functionMap = {
    unban: unBan,
    unflag: unFlag,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const user_id = id;
    return functionMap[type]({ user_id, additional_notes: additionalNotes, message })
      .then(({ message }) => {
        showToast({
          content: message,
          type: "success",
          dismissTime: 5000,
        });
        hideModal();
      })
      .catch(onError);
  };

  const onError = ({ response }) => {
    const content = response.data.message;
    let message = content;
    if (typeof content === "object") {
      message = Object.values(content)[0];
    }
    dangerToast({ content: message, dismissTime: 5000 });
  };

  const footerContent = StepKlass.Footer ? (
    <StepKlass.Footer goToStep={goToStep} closeModal={closeModal} btn={_btn} handleSubmit={handleSubmit} />
  ) : null;

  return (
    <StyledModal
      open
      onClose={hideModal}
      footerContent={footerContent}
      maxWidth="620px"
      paddingBody="24px 48px 48px 48px"
    >
      <StyledBox direction="column" textAlign="center">
        <Header type={type} image={image} username={username} />
        <StepKlass
          goToStep={goToStep}
          closeModal={closeModal}
          title={_title}
          description={_description}
          type={type}
          setAditionalNotes={setAditionalNotes}
          setMessage={setMessage}
          profileData={profileData}
          {...props}
        />
      </StyledBox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  margin-top: 0;

  ${Modal.Body} {
    padding-bottom: 0;
  }
  ${Modal.Footer} {
    & > div {
      padding: 0 48px;
    }
  }
`;

const StyledBox = styled(Box)`
  @media ${breakpoints.tablet} {
    height: 100%;
  }

  @media ${breakpoints.mobile} {
    height: 100%;
  }
`;

UndoActionsModal.defaultProps = {
  initialStep: 0,
};

export default UndoActionsModal;

import { createStore } from "easy-peasy";
import reducers from "./reducers";
// import { routerMiddleware, routerReducer, createReduxHistory } from './router';
// import eventDataset from './middlewares/eventDataset';
// import removeReactEvent from './middlewares/removeReactEvent';

const mainModel = {
  // router: reducer(routerReducer),
  ...reducers,
  // ...pages,
};

const storeConfig = {
  name: "front-end-store",
  // middleware: [eventDataset, removeReactEvent, routerMiddleware],
};

const store = createStore(mainModel, storeConfig);

// export const history = createReduxHistory(store);
// Wrapping dev only code like this normally gets stripped out by bundlers
// such as Webpack when creating a production build.

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.reconfigure(reducers);
    });
  }
}

export default store;

import { useStoreActions } from "easy-peasy";

const useFavoriteListings = () => {
  const { addFavoriteRequest, removeFavoriteRequest } = useStoreActions((actions) => actions.listings);

  const toggleFavorite = (event) => {
    const { id, favorite } = event.currentTarget.dataset;
    const isFavorite = favorite == "true";
    const method = isFavorite ? removeFavoriteRequest : addFavoriteRequest;
    return method(id);
  };

  return toggleFavorite;
};

export default useFavoriteListings;

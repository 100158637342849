import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const communitiesRequester = new HttpWrapper("communities");
const invitationsRequester = new HttpWrapper("membership_invitations");

const attributes = {
  administrators: [],
  pendingAdministrators: [],
};

const actions = {
  inviteAdminsRequest: thunk((actions, { communityId, emails }) => {
    const url = `${communityId}/invite_members`;

    return communitiesRequester.post(url, { emails, role: "admin" });
  }),
  reSendInvitationRequest: thunk((actions, invitationId) => {
    const url = "re_send";

    return invitationsRequester.post(url, { ids: [invitationId] });
  }),
  cancelInvitationRequest: thunk((actions, invitationId) => {
    const url = `${invitationId}/cancel`;

    return invitationsRequester.post(url);
  }),
  removeAdminRequest: thunk((actions, { communityId, memberId }) => {
    const url = `${communityId}/community_members/${memberId}`;

    return communitiesRequester.delete(url);
  }),
  getAdministratorsRequest: thunk(({ setAdministrators }, { communityId, keyword }) => {
    const url = `${communityId}/members?role[]=admin&role[]=owner&keyword=${keyword}`;

    return communitiesRequester.get(url).then(({ members }) => {
      setAdministrators(members);
    });
  }),
  getPendingAdministratorsRequest: thunk(({ setPendingAdministrators }, { communityId, keyword }) => {
    const url = `${communityId}/invitations?role[]=admin&role[]=owner&keyword=${keyword}`;

    return communitiesRequester.get(url).then(({ invitations }) => {
      setPendingAdministrators(invitations);
    });
  }),
  setAdministrators: action((state, administrators) => {
    state.administrators = administrators;
  }),
  setPendingAdministrators: action((state, invitations) => {
    state.pendingAdministrators = invitations;
  }),
};

const members = {
  ...attributes,
  ...actions,
};

export default members;

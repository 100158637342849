import { Input, TextArea } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const StyledInput = styled(Input)`
  & > label > div {
    margin-left: 0 !important;
    margin-bottom: 12px;
    font-weight: 600;
  }

  & > div > input {
    background: ${({ theme }) => theme.colors["01Primary50"]};
  }
`;

export const StyledTextArea = styled(TextArea)`
  margin-bottom: 24px;

  & > label {
    margin-bottom: 12px;
    font-weight: 600;
  }

  & > textarea {
    background: ${({ theme }) => theme.colors["01Primary50"]};
    ${({ invalid }) => invalid && `border: 2px solid #f16d67;`}
  }
`;

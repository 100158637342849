import { inject } from "@utils/userSnap";
import { StoreProvider, useStoreActions } from "easy-peasy";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "./components/GlobalStyles";
import Loader from "./components/Loader";
import store from "./redux/store";
import useSession from "./session";
// const AuthenticatedApp = lazy(() => import("./components/AuthenticatedApp/App"));
// const UnauthenticatedApp = lazy(() => import("./components/UnauthenticatedApp/App"));
import ChallengeApp from "./components/ChallengeApp";
import MaintenancePage from "./components/MaintenancePage";
import getDomainsCommunity from "./components/utils/domainsCommunity";
import ThemeContainer from "./components/utils/ThemeContainer";

const App = () => (
  <StoreProvider store={store}>
    <ThemeContainer>
      <GlobalStyles />
      <AppRenderer />
    </ThemeContainer>
  </StoreProvider>
);

function AppRenderer() {
  const { loading } = useSession();
  const [communityLoading, setCommunityLoading] = useState(false);
  const { getCommunityBrand } = useStoreActions((actions) => actions.community);

  useEffect(inject, []);

  useEffect(() => {
    const community = getDomainsCommunity();
    if (community) {
      setCommunityLoading(true);
      getCommunityBrand(community).finally(() => setCommunityLoading(false));
    }
  }, []);

  if (process.env.REACT_APP_MAINTENANCE_MODE) {
    return <MaintenancePage />;
  }

  if (loading || communityLoading) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <ChallengeApp />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

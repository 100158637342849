import { IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const ListingsTable = ({
  onSort,
  isMobile,
  isDesktop,
  sortIcon,
  sortType,
  isCommunity,
  hide_amounts,
  isTablet,
  children,
}) => {
  return (
    <Table>
      <THead onClick={onSort} display={isMobile && "none"}>
        <HeaderElement width={isDesktop && "384px"} textAlign="center">
          <IconText>
            <IconSvg width="15px" icon="globe-not-filled" />
            {sortType === "network" && <IconSvg data-name="network" width="7.5px" icon={sortIcon} />}

            <HeaderText data-name="contribution" typography="body/small" color="secondary800" bold>
              Contribution
              {sortType === "contribution" && <IconSvg width="7.5px" icon={sortIcon} />}
            </HeaderText>
          </IconText>
        </HeaderElement>
        {!hide_amounts && (
          <HeaderElement>
            {" "}
            <HeaderText data-name="reward" typography="body/small" color="secondary800" bold>
              {isCommunity ? "Reward" : "End of Season Prize"}{" "}
              {sortType === "reward" && <IconSvg width="7.5px" icon={sortIcon} />}
            </HeaderText>
          </HeaderElement>
        )}
        <HeaderElement minWidth={"80px"}>
          {" "}
          <HeaderText typography="body/small" color="secondary800" bold>
            Can Be Completed
          </HeaderText>
        </HeaderElement>
        <HeaderElement display={isTablet && "none"} minWidth={"197px"}>
          <HeaderText data-name="verification_method" typography="body/small" color="secondary800" bold>
            Completion Check {sortType === "verification_method" && <IconSvg width="7.5px" icon={sortIcon} />}
          </HeaderText>
        </HeaderElement>
        <HeaderElement></HeaderElement>
      </THead>
      <tbody>{children}</tbody>
    </Table>
  );
};

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  tbody:before {
    content: ".";
    display: block;
    line-height: 12.2px;
    text-indent: -99999px;
  }
`;

const THead = styled.thead`
  display: ${({ display }) => display && display};
`;

const HeaderElement = styled.th`
  text-align: left;
  min-width: ${({ minWidth }) => minWidth && minWidth};
  width: ${({ width }) => width && width};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  display: ${({ display }) => display && display};
`;

const IconText = styled.div`
  display: grid;
  grid-template-columns: 64px auto;
  text-align: left;
  svg {
    margin: 0 auto;
  }
`;

const HeaderText = styled(Text)`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 4px;
`;

export default ListingsTable;

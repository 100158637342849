import { ToastList } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";

const POSITION = "bottom-center";

const ToastService = () => {
  const { list } = useStoreState((state) => state.toasts);
  const { removeByID } = useStoreActions((actions) => actions.toasts);

  return <ToastList list={list} removeByID={removeByID} position={POSITION} />;
};

export default ToastService;

import { useStoreState } from "easy-peasy";
import { TableOfContributions } from "../common";
import ContributionsHeaderRow from "./ContributionsHeaderRow";
import ContributionsRow from "./ContributionsRow";
import LoaderTable from "./LoaderTable";

const TableContributions = ({
  data,
  onCommunityClick,
  onSort,
  sortType,
  sortDirection,
  loading,
  isSearching,
  onProfileClick,
  hide_amounts,
  isCustomToken,
  showCommunity,
  isFromProfile,
}) => {
  const { isDesktop, isTablet, isMobile } = useStoreState((state) => state.media);
  const showRewardCommunity = isDesktop || isFromProfile;
  const fromProfileTablet = isTablet && isFromProfile;
  const marginTop = fromProfileTablet ? "32px" : "40px";

  return (
    <>
      <TableOfContributions padding={isFromProfile && isMobile && "0"} marginTop={isFromProfile && marginTop}>
        {!isMobile && (
          <ContributionsHeaderRow
            onSort={onSort}
            sortType={sortType}
            sortDirection={sortDirection}
            hide_amounts={hide_amounts}
            isCustomToken={isCustomToken}
            isDesktop={isDesktop}
            isTablet={isTablet}
            isMobile={isMobile}
            showCommunity={showCommunity}
            isFromProfile={isFromProfile}
            showRewardCommunity={showRewardCommunity}
            fromProfileTablet={fromProfileTablet}
          />
        )}
        {!loading && (
          <tbody>
            {data?.map((item) => (
              <ContributionsRow
                key={item.id}
                {...item}
                hide_amounts={hide_amounts}
                onCommunityClick={onCommunityClick}
                isSearching={isSearching}
                onProfileClick={onProfileClick}
                isCustomToken={isCustomToken}
                isDesktop={isDesktop}
                isTablet={isTablet}
                isMobile={isMobile}
                showCommunity={showCommunity}
                isFromProfile={isFromProfile}
                showRewardCommunity={showRewardCommunity}
                fromProfileTablet={fromProfileTablet}
              />
            ))}
          </tbody>
        )}
      </TableOfContributions>
      <LoaderTable loading={loading} />
    </>
  );
};

export default TableContributions;

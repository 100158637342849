const COLOR_RED = "#C2000C";
const COLOR_BLUE = "#0159DC";
const COLOR_BLACK = "#000000";
const COLOR_BLACKISH = "#1C2942";
const COLOR_100 = "#ACFF79";
const COLOR_200 = "#D9D9D9";
const COLOR_300 = "#FFFFFF";
const COLOR_700 = "#DCCB74";
const COLOR_800 = "#E9AE77";
const COLOR_900 = "#E38F7C";
const COLOR_1000 = "#DE8484";
const COLOR_1100 = "#DB86A4";
const COLOR_1200 = "#C9A052";
const COLOR_1300 = "#E59C96";
const COLOR_1400 = "#CC3AFF";
const COLOR_1500 = "#FF8B36";
const COLOR_1600 = "#4910C3";
const COLOR_1700 = "#E393FF";
const PRIMARY_500 = "#81C394";
const CTA_HOVER = "#5FB974";
const BORING_SECURITY = "#D75A00";
const SECONDARY500 = "#7EC0CE";
const SECONDARY300 = "#F9D760";
const SECONDARY700 = "#BB8900";
const SECONDARY500_2 = "#E2857E";
const ERROR = "#E06363";

export const brandColors = [
  PRIMARY_500,
  CTA_HOVER,
  BORING_SECURITY,
  COLOR_RED,
  COLOR_BLUE,
  SECONDARY500,
  SECONDARY300,
  SECONDARY700,
  COLOR_1200,
  COLOR_1300,
  SECONDARY500_2,
  COLOR_1400,
];

export const bannerColors = [
  COLOR_BLACK,
  COLOR_BLACKISH,
  PRIMARY_500,
  COLOR_1500,
  ERROR,
  COLOR_700,
  COLOR_800,
  COLOR_900,
  COLOR_1000,
  COLOR_1100,
  SECONDARY700,
  COLOR_1200,
  COLOR_1600,
  COLOR_1400,
  COLOR_1700,
  COLOR_100,
  COLOR_200,
  COLOR_300,
];

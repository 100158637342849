import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { TAGS } from "../useTagFilters";
import SelectView from "./SelectView";

const _sortOptions = [{ options: TAGS, label: TAGS[0].label }];

const useDelayUnmount = (isMounted, delayTime) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
};

const FilterMobile = ({ sortValue, setSortValue }) => {
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const shouldRender = useDelayUnmount(showOptions, 500);
  const openSelector = (item) => {
    setShowOptions(true);

    setOptions(item.options);
  };

  const hideSelector = () => {
    setShowOptions(false);
  };
  return (
    <>
      {_sortOptions.map((item, index) => {
        const selectedLabel = options.find((option) => option.value === sortValue)?.label;
        const label = selectedLabel === undefined || selectedLabel === "All Contributors" ? "All" : selectedLabel;
        return (
          <Select
            key={index}
            flex
            pointer
            color="01Primary700"
            alignItems="center"
            bgColor="transparent"
            onClick={() => openSelector(item)}
          >
            <Text bold>{label}</Text>
            <IconSvg icon="chevron-down" width="20px" />
          </Select>
        );
      })}

      {!!shouldRender && (
        <SelectView
          setSortValue={setSortValue}
          sortValue={sortValue}
          options={options}
          hideOptions={hideSelector}
          isMultiple={false}
          closed={!showOptions}
        />
      )}
    </>
  );
};

const Select = styled(Box)`
  height: 56px;
  width: 173px;
  padding: 0 1rem;
  border-radius: 12px;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors["01Primary55"]};
`;
export default FilterMobile;

import { useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import HttpWrapper from "../../../../redux/httpRequester";

const requester = new HttpWrapper("tags");

const useTagsByCommunityID = (community_id) => {
  const [tags, setTags] = useState([]);
  const { createTagRequest } = useStoreActions((actions) => actions.community);

  const getTagsRequest = () =>
    requester.get("", { community_id, type: "community_tag" }).then(({ tags }) => setTags(tags));

  const createTag = (name) => createTagRequest({ id: community_id, name }).then(getTagsRequest);

  useEffect(() => {
    if (community_id) {
      getTagsRequest();
    }
  }, [community_id]);

  return { tags, createTag };
};

export default useTagsByCommunityID;

import HeroIlustration from "@assets/bg-community-hero.svg";
import Giraffe from "@assets/dashboard-giraffe.svg";
import { MainContainer } from "@common/MainContainer";
import { Box, breakpoints, Button, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import useWeb3 from "../../hooks/useWeb3";

const LoggedOutHeader = () => {
  const { loginFlow, installMetamask, hasWeb3Provider } = useWeb3();
  const { authLoading } = useStoreState((state) => state.authentication);
  const { isMobile } = useStoreState((state) => state.media);
  const referral_code = sessionStorage.getItem("referral-code");

  const connectWallet = () => {
    if (!hasWeb3Provider) {
      installMetamask();
    } else {
      loginFlow(referral_code);
    }
  };

  return (
    <Wrapper relative flex direction="column">
      <MainContainer>
        <Content relative spacing="mx-a">
          <Box flex direction="column" justifyContent="center" alignItems={isMobile && "center"}>
            <Title color="02Primary0" typography="heading/x-large" spacing="mb-4" bold>
              Contribution Opportunities
            </Title>
            <Text color="02Primary500" typography="body/large" spacing="mb-6" sm-spacing="mb-5" extraBold>
              Every contribution earns crypto rewards, and an on-chain “thank you” note.
            </Text>
            <ConnectButton
              loading={authLoading}
              size="md"
              bold
              height="56px"
              borderRadius="40px"
              bgColor="02Primary0"
              color="02Primary500"
              borderColor="02Primary0"
              hoverBackground="02PrimaryGrey100"
              hoverBorderColor="02PrimaryGrey100"
              hoverColor="02Primary500"
              onClick={connectWallet}
              maxWidth={!isMobile && "199px"}
            >
              Connect Wallet
            </ConnectButton>
          </Box>

          <GiraffeImg src={Giraffe} />
        </Content>
      </MainContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  min-height: 300px;
  background: url(${HeroIlustration});
  background-repeat: no-repeat;
  background-position-x: center;

  &>div:first-child {
    max-height: 300px;
  }

  @media screen and (min-width: 1920px) {
    background-size: cover;
  }
  @media ${breakpoints.mobile} {
    text-align: center;
  }
`;

const Content = styled(Box)`
  flex: 1;
  display: flex;

  @media ${breakpoints.mobile} {
  }
`;

const Title = styled(Text)`
  position: relative;
  z-index: 2;
`;

const ConnectButton = styled(Button)`
  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

const GiraffeImg = styled.img`
  max-height: 301px;
  margin: auto;
  @media ${breakpoints.miniTablet} {
    display: none;
  }
  @media ${breakpoints.mobile} {
    display: none;
  }
`;

export default LoggedOutHeader;

import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import { useContext } from "react";
import styled from "styled-components";
import { FloatInput, IntegerInput } from "../common";
import { ListingEditorContext } from "../ListingEditorContext";

const RewardSelectionStep = () => {
  const { isEditing, listing } = useContext(ListingEditorContext);
  const { contributions_per_user, unlimited_contributions_per_user, reward_amount } = listing;
  const maximum = unlimited_contributions_per_user ? "unlimited" : contributions_per_user * reward_amount;

  return (
    <div>
      <Box spacing="mb-6" color="01Primary700">
        <Text bold spacing="mb-3" typography="body/medium">
          Contribution Value (Required):
        </Text>
        <Box flex maxWidth="397px" gap="1rem" alignItems="center">
          <FloatInput name="reward_amount" defaultValue={reward_amount} required />
          <Text typography="body/medium" inline bold>
            $THRIVE
          </Text>
        </Box>
      </Box>
      <Box spacing="mb-6" maxWidth="317px" color="01Primary700">
        <Text bold spacing="mb-3" typography="body/medium">
          Completions per user (Required)
        </Text>
        <IntegerInput
          name="contributions_per_user"
          spacing="mb-3"
          required
          defaultValue={contributions_per_user}
          disabled={isEditing || unlimited_contributions_per_user}
        />
        <input
          key={unlimited_contributions_per_user}
          type="checkbox"
          name="unlimited_contributions_per_user"
          defaultChecked={unlimited_contributions_per_user}
          disabled={isEditing}
        />
        <span>No limit</span>
      </Box>

      {!unlimited_contributions_per_user && <RewardBox maximum={maximum} />}
    </div>
  );
};

const RewardBox = ({ maximum = 0 }) => {
  return (
    <StyledBox color="01Primary700" spacing="p-5" bgColor="01Primary50" borderRadius="12px">
      <Text color="01Primary700">Max Rewards per User</Text>
      <Box flex alignItems="center" gap="6px" spacing="mb-4 mt-2" color="03Secondary700">
        <IconSvg icon="logo-gold-circle" width="17px" />
        <Text bold fontFamily="Outfit" size="20px" lHeight="24px">
          {parseFloat(maximum) || 0} $THRIVE
        </Text>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
`;

export default RewardSelectionStep;

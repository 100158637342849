import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("challenges");

const attributes = {
  challenge: null,
};

const actions = {
  commitTochallengeRequest: thunk(({ commitChallenge }, communityId) => {
    return requester.post(`${communityId}/commit`).then(({ challenge }) => {
      commitChallenge(challenge);
      return challenge;
    });
  }),
  commitChallenge: action((state, challenge) => {
    state.challenge = challenge;
  }),
};

const commitChallenge = {
  ...attributes,
  ...actions,
};

export default commitChallenge;

import { useState } from "react";
import CreateAdmins from "./CreateAdmins";
import EditAdmins from "./EditAdmins";

const AdminsSelectionStep = ({ communityValues, setState, isPersisted }) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const handleEmail = (event) => {
    if (error) {
      setError("");
    }
    setInputValue(event.target.value);
  };
  const addAdmin = () => {
    if (inputValue !== "") {
      setState({ ...communityValues, admin_emails: [...communityValues.admin_emails, inputValue] });
      setInputValue("");
    } else {
      setError("Email is required for adding administrator!");
    }
  };

  const removeAdmin = (value) => {
    setState({
      ...communityValues,
      admin_emails: communityValues.admin_emails.filter((email) => email !== value),
    });
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      addAdmin();
      event.preventDefault();
    }
  };
  return (
    <div>
      {!isPersisted ? (
        <CreateAdmins
          addAdmin={addAdmin}
          handleEmail={handleEmail}
          onKeyPress={onKeyPress}
          removeAdmin={removeAdmin}
          communityValues={communityValues}
          setState={setState}
          inputValue={inputValue}
          error={error}
        />
      ) : (
        <EditAdmins removeAdmin={removeAdmin} communityValues={communityValues} />
      )}
    </div>
  );
};

export default AdminsSelectionStep;

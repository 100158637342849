import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import currentUserChannel from "../../../websocket/channels/currentUserChannel";

const subscriptions = [];

const useWebSockets = () => {
  const user = useStoreState((State) => State.user);
  const toastActions = useStoreActions((actions) => actions.toasts);
  useEffect(() => {
    if (user.id) {
      subscriptions.push(currentUserChannel(user.id, toastActions));
    } else {
      while (subscriptions.length) {
        const subscription = subscriptions.pop();
        subscription.unsubscribe();
      }
    }
  }, [user.id]);
};

export default useWebSockets;

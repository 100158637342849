// app/javascript/channels/consumer.js
// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from "@rails/actioncable";

const URL = process.env.REACT_APP_WEBSOCKET_SERVER_URL;

let consumer;
const connectToServer = () => {
  return (consumer = consumer || createConsumer(URL));
};

export default connectToServer;

import { computed, thunk } from "easy-peasy";
import memoizerific from "memoizerific";
import HttpWrapper from "../httpRequester";
import { normalizeWallets } from "../normalizerSchema";

const requester = new HttpWrapper("thrive_api/addresses");
const CACHE_SIZE = 1000;

const attributes = {
  wallets: {},
};

const actions = {
  disconnectRequest: thunk((actions, id, { getStoreActions }) => {
    const { removeEntity } = getStoreActions().entities;

    // return requester.destroy(id).then(() => removeEntity({ id, entityType: "wallets" }));
    return requester.destroy(id);
  }),
  getBalance: thunk(({ normalize, getAddressBalance }, { id, address, chain_type = "polygon" }) => {
    getAddressBalance({ chain_type, address }).then((balance) => {
      const wallet = { id, chain_type, address, balance: balance || "" };

      return normalize([wallet]);
    });
  }),
  getAddressBalance: thunk((actions, { chain_type, address }) => {
    const url = `${chain_type}/${address}/balance`;
    return requester.get(url).then((res) => +res.balance);
  }),
  normalize: thunk((actions, wallets, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const result = normalizeWallets(wallets);

    setEntities(result);

    return result.entities.wallets;
  }),
  setWallets: thunk((actions, wallets, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    setEntities(normalizeWallets(wallets));
  }),
  getById: computed([(state, storeState) => storeState.entities.wallets], (wallets) => {
    return memoizerific(50)((id) => wallets[id], CACHE_SIZE);
  }),
  getOperationalWallet: thunk((actions, { chainType, accType }) => {
    const url = `${chainType}/operational/${accType}`;
    return requester.get(url).then((data) => data.address);
  }),
};

const wallets = {
  ...attributes,
  ...actions,
};

export default wallets;

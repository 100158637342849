import { action, actionOn, computed, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";
import { denormalizeEntity, normalizeCollection2 } from "../normalizerSchema";

const requester = new HttpWrapper("challenges");
const communitiesRequester = new HttpWrapper("communities");
const rewardRequester = new HttpWrapper("rewards");

const CHALLENGE = { current_step: {}, steps: [] };

const challengeResolver = (state, globalState) => ({
  entities: globalState.entities,
  id: state.id,
});

const currentChallengeResolver = (state, globalState) => ({
  entities: globalState.entities,
  id: state.currentID,
});

const attributes = {
  activeChallenges: [],
  previousChallenges: [],
  id: undefined,
  currentID: undefined,
  progress: {},
  challenge: computed([challengeResolver], ({ entities, id }) => {
    return denormalizeEntity(id, entities, "challenge") || CHALLENGE;
  }),
  currentChallenge: computed([currentChallengeResolver], ({ entities, id }) => {
    return denormalizeEntity(id, entities, "challenge");
  }),
};

const actions = {
  claimRewardVirtuallyRequest: thunk((actions, id) => {
    const url = `${id}/transfer_virtually`;
    return rewardRequester.patch(url);
  }),
  getChallengeDetailRequest: thunk(({ setID, normalize }, id) => {
    const url = `${id}/details`;

    return requester.get(url).then((data) => {
      const id = normalize({ data: [data.challenge], entityName: "challenge" })[0];
      setID(id);
      return data.challenge;
    });
  }),
  getCurrentChallengeRequest: thunk(({ setCurrentChallengeID, normalize }, id) => {
    const url = `${id}/current_challenge`;

    return communitiesRequester.get(url).then((data) => {
      const id = normalize({ data: [data.challenge], entityName: "challenge" })[0];
      setCurrentChallengeID(id);
    });
  }),
  getActiveChallengesRequest: thunk(({ setActiveChallenges }, communityId) => {
    return communitiesRequester.get(`${communityId}/active_challenges`).then(({ challenges }) => {
      setActiveChallenges(challenges);
    });
  }),
  getPreviousChallenges: thunk(({ setPreviousChallenges }, communityId) => {
    return communitiesRequester.get(`${communityId}/previous_challenges`).then(({ challenges }) => {
      setPreviousChallenges(challenges);
    });
  }),
  abandonChallenge: thunk((actions, id) => {
    const url = `${id}/leave`;

    return requester.post(url);
  }),
  setCurrentChallenge: action((state, challenge) => {
    state.currentChallenge = challenge;
  }),
  setActiveChallenges: action((state, challenges) => {
    state.activeChallenges = challenges;
  }),
  setPreviousChallenges: action((state, challenges) => {
    state.previousChallenges = challenges;
  }),
  setID: action((state, id) => {
    state.id = id;
  }),
  setCurrentChallengeID: action((state, id) => {
    state.currentID = id;
  }),
  setProgress: action((state, data) => {
    state.progress = data;
  }),
  getChallengeProgressRequest: thunk(({ setProgress }, { id, listingID }) => {
    const url = `${id}/progress/${listingID}`;

    return requester.get(url).then((data) => {
      setProgress(data.progress);
    });
  }),
  normalize: thunk((actions, { data, entityName }, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const result = normalizeCollection2(data, entityName);
    setEntities(result);

    return result.result;
  }),
};

const listeners = {
  onLogOut: actionOn(
    (_, actions) => actions.authentication.logout,
    (state) => {
      state.currentID = null;
    }
  ),
};

const challenges = {
  ...attributes,
  ...actions,
  ...listeners,
};

export default challenges;

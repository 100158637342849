import { Box, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const Header = ({ type, image, username }) => {
  return (
    <Box>
      <Title typography="heading/medium" bold>
        {type} User
      </Title>
      <Box height="1px" bgColor="02PrimaryGrey150" spacing="my-5" />

      <Box flex gap="24px" alignItems="center">
        <StyledAvatar src={image} alt="user" />
        <Text typography="body/large" extraBold>
          {username}
        </Text>
      </Box>

      <Box height="1px" bgColor="02PrimaryGrey150" spacing="my-5" />
    </Box>
  );
};

const Title = styled(Text)`
  text-transform: capitalize;
`;

const StyledAvatar = styled.img`
  width: 89px;
  height: 90px;
  border-radius: 6px;
`;
export default Header;

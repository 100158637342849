import { useStoreActions } from "easy-peasy";
import { useEffect, useRef } from "react";

const useGetBalance = (_wallets) => {
  const { getBalance } = useStoreActions((state) => state.wallets);
  const fetched = useRef([]);

  useEffect(() => {
    const fetching = fetched.current;
    const wallets = Array.isArray(_wallets) ? _wallets : [_wallets];
    const withoutBalance = wallets.filter(
      ({ balance, address }) => balance === undefined && !fetching.includes(address)
    );

    if (withoutBalance.length) {
      withoutBalance.forEach((wallet) => {
        getBalance(wallet);
        fetched.current.push(wallet.address);
      });
    }
  }, [_wallets]);
};

export default useGetBalance;

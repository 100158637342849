import { AddButton, OutsideBorder, RemoveIcon, StyledInput, StyledTag, WrapperInputs } from "./common";

const CreateAdmins = ({ communityValues, handleEmail, onKeyPress, removeAdmin, addAdmin, inputValue, error }) => {
  return (
    <div>
      <AddButton
        rightIcon="plus"
        borderRadius="40px"
        heightSize="56px"
        kind="outline"
        variant="hover"
        color="01Primary700"
        onClick={addAdmin}
        widthFull
      >
        Add Administrator
      </AddButton>
      <WrapperInputs>
        <OutsideBorder>
          <StyledInput
            name="email"
            value={inputValue}
            placeholder="Email address"
            onChange={handleEmail}
            onKeyPress={onKeyPress}
            invalid={!!error}
            alwaysShowValidation={!!error}
            validationText={error}
          />
        </OutsideBorder>
        {communityValues.admin_emails.map((admin, index) => {
          return (
            <OutsideBorder key={index}>
              <StyledTag border="secondary200" heightSize="56px" padding="16px" borderRadius="12px">
                {admin}
              </StyledTag>
              <RemoveIcon pointer onClick={() => removeAdmin(admin)} icon="x" color="01Primary700" width="16px" />
            </OutsideBorder>
          );
        })}
      </WrapperInputs>
    </div>
  );
};

export default CreateAdmins;

import Linkify from "react-linkify";
import styled from "styled-components";

export const textLinkify = (text) => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <Link target="blank" href={decoratedHref} key={key}>
          {decoratedText}
        </Link>
      )}
    >
      {text}
    </Linkify>
  );
};

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors["link"]};
`;

import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../Loader";
import useGitcoinRequirementCheck from "../hooks/useGitcoinRequirementCheck";
import useManageClaimingState from "../hooks/useManageClaimingState";
import useQuery from "../hooks/useQuery";
import BodySeason from "./BodySeason";
import HeroPart from "./Hero";

const SeasonPage = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const bodyRef = useRef(null);
  const eligibleModalOpened = useRef(null);
  const { getActiveSeasonRequest, getSeasonDetails, reset, eligibleForContributingRequest } = useStoreActions(
    (actions) => actions.season
  );
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { getPersonalStatus, resetContributorDetails } = useStoreActions((actions) => actions.contributors);
  const { user } = useStoreState((state) => state.user);
  const { confirmed } = useStoreState((state) => state.user.user);
  const selectedIndex = parseInt(useQuery().get("tabIndex")) || 0;
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedIndex);
  const { season } = useStoreState((state) => state.season);
  const { setCustomTheme } = useStoreActions((actions) => actions.theme);
  const { id } = useParams();
  const { raffle_id } = season;
  useGitcoinRequirementCheck(season.community_required_gitcoin_score);
  useManageClaimingState(season);

  const onRaffleDetails = () => navigate(`/raffles/${raffle_id}`);

  const scrollToBody = (e) => {
    e.stopPropagation();
    bodyRef.current.scrollIntoView({ behavior: "smooth" });
    setCurrentTabIndex(0);
  };

  const focusAboutTab = () => setCurrentTabIndex(1);

  useEffect(() => {
    getActiveSeasonRequest(id)
      .then(async () => {
        if (confirmed) {
          await getSeasonDetails(id);
        }
        setLoading(false);
      })
      .catch(() => navigate("/"));

    if (confirmed) {
      getPersonalStatus({ id, type: "seasons" });
    } else {
      resetContributorDetails();
    }

    return () => {
      reset();
      resetContributorDetails();
      setCustomTheme(false);
    };
  }, [id, confirmed]);

  useEffect(() => {
    if (season?.community_theme === "custom") {
      setCustomTheme(true);
    } else {
      setCustomTheme(false);
    }
  }, [season?.community_theme]);

  useEffect(() => {
    if (season?.eligible_contributors_restriction && !eligibleModalOpened.current) {
      if (!confirmed) {
        showModal({ modalName: "SeasonEligibilityModal", type: "unauthenticated", seasonId: season?.id });
        eligibleModalOpened.current = false;
      } else {
        const agreedKey = `agreed_${user?.id}_${season?.id}`;
        const agreed = localStorage.getItem(agreedKey);
        if (!agreed) {
          eligibleForContributingRequest(id).then((data) => {
            if (data.payload) {
              showModal({ modalName: "SeasonEligibilityModal", type: "eligible", seasonId: season?.id });
            } else {
              showModal({ modalName: "SeasonEligibilityModal", type: "ineligible", seasonId: season?.id });
            }
          });
        }
      }
    }
  }, [season?.eligible_contributors_restriction]);

  useEffect(() => {
    if (confirmed) {
      eligibleModalOpened.current = false;
    }
  }, [confirmed]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <HeroPart
        scrollToBody={scrollToBody}
        focusAboutTab={focusAboutTab}
        season={season}
        onRaffleDetails={onRaffleDetails}
      />
      <div ref={bodyRef}>
        <BodySeason
          currentTabIndex={currentTabIndex}
          setCurrentTabIndex={setCurrentTabIndex}
          season={season}
          onRaffleDetails={onRaffleDetails}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
  overflow-x: hidden;
`;

export default SeasonPage;

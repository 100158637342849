import { Box, breakpoints, IconSvg, ProgressBar, Text, Tooltip } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { VALID_SOCIAL_NETWORKS } from "../../../constants";
import useSocialProfilesInfo from "../../hooks/useSocialProfilesInfo";

const ConnectedNetworks = () => {
  const navigate = useNavigate();
  const { isMobile, isDesktop } = useStoreState((state) => state.media);
  const { connectionsPercent, availableActivitiesPercentage, connectionsCount } = useSocialProfilesInfo();
  const goToWays = () => navigate("/?tabIndex=2");

  return (
    <MainBox flex direction="column">
      <ConnectedNetworksWrapper direction={!isDesktop && "column"}>
        <Box>
          <Text typography={isDesktop ? "heading/large" : "heading/medium"} color="01Primary700" bold spacing="mb-4">
            Connected Networks
          </Text>

          <Text color="01Primary700" typography="body/medium" maxWidth="524px">
            We’ll verify your identity on each network you connect and automatically reward your contributions on that
            network.
          </Text>
        </Box>
        <CounterBox flex>
          <Box minWidth={isMobile ? "246px" : "387px"}>
            <ProgressBar
              percent={connectionsPercent}
              bgColor="03Primary500"
              height="8"
              bgColorUnfilled="01Primary700"
            />
            <Text typography={isMobile ? "body/small" : "body/medium"} bold spacing="mt-4+2">
              Connected Networks {connectionsCount}/{VALID_SOCIAL_NETWORKS.length}
            </Text>
          </Box>
          <Box flex direction="column" alignItems="flex-end">
            <Text
              color="03Primary500"
              size="56px"
              fontFamily="outfit"
              lineHeight="42px"
              typography={isMobile && "heading/x-large"}
            >
              {availableActivitiesPercentage}%
            </Text>
            <ActivitiesBox flex alignItems="center" gap="8px" spacing={isMobile ? "mt-1" : "mt-4+2"} relative>
              <ActivitiesText typography={isMobile ? "body/small" : "body/medium"} bold>
                Activities available
              </ActivitiesText>
              {!isMobile && (
                <StyledTooltip
                  direction="left"
                  message={`Each network you connect unlocks more ways to contribute.
              Connect all networks to unlock all ways to contribute across all Communities on ThriveCoin.`}
                >
                  <IconSvg icon="help-circle" width="18px" color="01Primary700" />
                </StyledTooltip>
              )}
            </ActivitiesBox>
          </Box>
        </CounterBox>
      </ConnectedNetworksWrapper>
      <WaysBox flex alignItems="center" gap="7px" onClick={goToWays}>
        <IconSvg icon="eye" color="03Primary500" />
        <StyledText typography="body/medium" color="03Primary500" bold>
          See all Ways to Contribute
        </StyledText>
      </WaysBox>
    </MainBox>
  );
};

const MainBox = styled(Box)`
  padding-bottom: 42px;
`;

const CounterBox = styled(Box)`
  align-items: flex-end;
  gap: 29px;
  @media ${breakpoints.tablet} {
    justify-content: space-between;
    width: 100%;
    gap: 0;
  }
  @media ${breakpoints.mobile} {
    margin-top: 24px;
    align-items: baseline;
  }
`;
const StyledText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;
const ConnectedNetworksWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media ${breakpoints.tablet} {
    align-items: flex-start;
  }
`;

const StyledTooltip = styled(Tooltip)`
  display: flex;

  & span {
    min-width: 300px;
  }
`;

const WaysBox = styled(Box)`
  margin-left: auto;
  @media ${breakpoints.mobile} {
    padding-top: 14px;
  }
`;

const ActivitiesText = styled(Text)`
  @media ${breakpoints.tablet} {
    white-space: nowrap;
  }
  @media ${breakpoints.mobile} {
    position: absolute;
    right: 14px;
    white-space: nowrap;
  }
`;

const ActivitiesBox = styled(Box)`
  @media ${breakpoints.mobile} {
    padding-top: 16px;
  }
`;
export default ConnectedNetworks;

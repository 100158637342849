import { customDarkTheme, customLightTheme, darkTheme, lightTheme } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

const customThemes = {
  light: customLightTheme,
  dark: customDarkTheme,
};

const darkModeMedia = window.matchMedia("(prefers-color-scheme: dark)");

const useSystemThemeDetector = () => {
  const getCurrentTheme = () => darkModeMedia.matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mqListener = (e) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = darkModeMedia;
    darkThemeMq.addEventListener("change", mqListener);
    return () => darkThemeMq.removeEventListener("change", mqListener);
  }, []);
  return isDarkTheme ? "dark" : "light";
};

const ThemeContainer = ({ children }) => {
  const systemTheme = useSystemThemeDetector();
  const [selectedTheme, setSelectedTheme] = useState("system");
  const { customTheme } = useStoreState((state) => state.theme);
  const _themes = customTheme ? customThemes : themes;

  const theme = selectedTheme === "system" ? _themes[systemTheme] : _themes[selectedTheme];

  const getTheme = () => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme) {
      setSelectedTheme(currentTheme);
    }
  };

  useEffect(() => {
    getTheme();
    window.addEventListener("theme-change", getTheme);
    return () => {
      window.removeEventListener("theme-change", getTheme);
    };
  }, []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export const changeTheme = (theme) => {
  localStorage.setItem("theme", theme);
  window.dispatchEvent(new Event("theme-change"));
};

export default ThemeContainer;

import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useContext } from "react";
import styled from "styled-components";
import RichEditor from "../../common/RichEditor";
import { SeasonEditorContext } from "../SeasonEditorContext";

const AboutSeason = () => {
  const { season, updateSeason } = useContext(SeasonEditorContext);
  const onChange = (val) => updateSeason("about", val);

  return (
    <Wrapper flex direction="column" grow={1}>
      <Text bold spacing="mb-4">
        About (Optional)
      </Text>
      <RichEditor value={season.about} onChange={onChange} />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 736px;

  @media ${breakpoints.tablet} {
    width: 505px;
  }

  @media ${breakpoints.mobile} {
    width: unset;
    margin: 0 16px;
  }
`;

export default AboutSeason;

import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";

const KEY = "modal-config";

const useContinueModalFlow = () => {
  const { showModal } = useStoreActions((actions) => actions.modals);
  useEffect(() => {
    const jsonParams = localStorage.getItem(KEY);
    localStorage.removeItem(KEY);

    if (jsonParams) {
      const params = JSON.parse(jsonParams);
      showModal(params);
    }
  }, []);
};

export const writeModalConfig = (config) => localStorage.setItem(KEY, JSON.stringify(config));

export default useContinueModalFlow;

import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { VALID_SOCIAL_NETWORKS, SOCIALS_NO_DISCORD } from "../../constants";

const hasNetwork =
  (networkName) =>
  ({ provider }) =>
    provider == networkName;

const getConnections = (checkFN) => {
  return SOCIALS_NO_DISCORD.reduce((result, network) => {
    const key = `${network}Connected`;
    result[key] = checkFN(network);
    return result;
  }, {});
};

const validProfileFilter = (profile) => VALID_SOCIAL_NETWORKS.includes(profile.provider);

const useSocialProfilesInfo = () => {
  const { getActiveListingsCountRequest } = useStoreActions((actions) => actions.contributionApps);
  const { getSocialProfiles } = useStoreActions((actions) => actions.authentication);
  const { countByApp } = useStoreState((state) => state.contributionApps);
  const user = useStoreState((state) => state.user.user);
  const { socialProfiles, available_percentage: availableActivitiesPercentage } = user;
  const connectionsCount = socialProfiles.filter(validProfileFilter).length;
  const connectionsPercent = (connectionsCount / VALID_SOCIAL_NETWORKS.length) * 100;
  const socialProfileConnected = (network) => {
    return socialProfiles?.find(hasNetwork(network)) || false;
  };
  const connections = getConnections(socialProfileConnected);
  const discourseConnections = {
    bankless_discourse: socialProfileConnected("bankless_discourse"),
    // particle_discourse: socialProfileConnected("particle_discourse"),
    apecoin_discourse: socialProfileConnected("apecoin_discourse"),
    aavegotchi_discourse: socialProfileConnected("aavegotchi_discourse"),
  };
  const networkNames = Object.keys(countByApp);

  useEffect(() => {
    getActiveListingsCountRequest();
    getSocialProfiles();
  }, []);
  return {
    connectionsPercent,
    ...connections,
    discourseConnections,
    countByApp,
    availableActivitiesPercentage,
    networkNames,
    connectionsCount,
    connectedNetworks: socialProfiles?.map(({ provider }) => provider) || [],
    networksCount: networkNames?.length,
  };
};

export default useSocialProfilesInfo;

import Hand from "@assets/profile/hand-big.svg";
import { Box, Button, Modal, Text, breakpoints } from "@thrivecoin/ui-components";
import { useState } from "react";
import styled from "styled-components";
import WalletCard from "../../Profile/WalletsTab/WalletCard";

const AdditionalWalletErrorModal = ({ hideModal, address, onClick }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    onClick().finally(() => setLoading(false));
  };

  return (
    <Modal maxWidth="684px" md-maxWidth="553px" open buttonCircle onClose={hideModal} borderRadius="12px">
      <Container>
        <Text typography="heading/large" color="02Primary500" spacing="mb-6">
          Verify Additional Wallet
        </Text>

        <Box>
          <HandImg src={Hand} />
          <WalletCard
            address={address}
            title="Additional Wallet"
            bgColorCard="02Secondary500"
            smallCard
            height="242px"
            width="391px"
            middleLineColor="error"
            isFromVerificationModal={true}
            hasError={true}
          />
        </Box>

        <Text typography="body/medium" color="error" spacing="mt-5" bold align="center" maxWidth="500px">
          This address already exists in our system. Please ensure you are set to a different wallet and connect again.
        </Text>

        <Button
          spacing="mt-6"
          type="kind"
          borderRadius="40px"
          width="100%"
          leftIcon="chain"
          color="02Primary0"
          onClick={handleClick}
          loading={loading}
          maxWidth="505px"
          modal={true}
        >
          Connect Again
        </Button>
      </Container>
    </Modal>
  );
};

const Container = styled(Box)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HandImg = styled.img`
  position: absolute;
  left: -1px;
  top: 110px;
  z-index: 9;

  @media ${breakpoints.mobile}{
    transform: translate(-34%, 34%);
    max-height: 157px;
  }
`;

export default AdditionalWalletErrorModal;

export default class ProviderManager {
  constructor() {
    this.connectors = [];
    this.options = {};
  }

  addConnector(connector) {
    this.connectors[connector.key] = connector.class;
    this.options[connector.key] = connector.options;
  }

  getConnector(key) {
    const options = this.options[key];
    return new this.connectors[key](options);
  }
}

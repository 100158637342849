import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { REDIRECTION_KEY, SHOULD_CONNECT_MORE, SHOULD_UPDATE_PROFILE } from "./hooks/useConnectSocialMedias";

const USES_CODE_PARAM = ["asana", "discord", "linkedin", "zoom", "twitter", "github"];

export const makeErrorHandler = (toast, callback) => (error) => {
  const response = error.response.data;
  const errors = response.data.errors;
  const content = errors.uid ? errors.uid[0] : response.message;
  toast({ content, timeout: 5000 });
  callback && callback();
};

const ConnectSocialProfilesPage = () => {
  const { provider } = useParams();
  const { connectSocialProfile } = useStoreActions((actions) => actions.authentication);
  const { dangerToast } = useStoreActions((actions) => actions.toasts);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const [querys] = useSearchParams();
  const code = querys.get("code");
  const error = querys.get("error_description");
  const message = `Something went wrong while trying to connect to ${capitalizeFirstLetter(provider)}`;
  const errorMessage = error || message;
  const navigate = useNavigate();
  const redirectionPage = localStorage.getItem(REDIRECTION_KEY) || "communities";
  const redirect = () => navigate(redirectionPage, { replace: true });
  const redirectWithModal = () => {
    const connectMore = localStorage.getItem(SHOULD_CONNECT_MORE) === "true";
    const updateProfile = localStorage.getItem(SHOULD_UPDATE_PROFILE) === "true";
    connectMore && showModal({ modalName: "ConnectSocialMediasModal", provider });
    updateProfile && showModal({ modalName: "UpdateProfileModal", provider, modalOrder: 11 });
    redirect();
  };

  const handleError = makeErrorHandler(dangerToast, redirect);

  useEffect(() => {
    if (USES_CODE_PARAM.includes(provider) && code) {
      connectSocialProfile({ provider, params: { code } }).then(redirectWithModal).catch(handleError);
    } else {
      dangerToast({ content: errorMessage, timeout: 5000 });
      redirect();
    }
  }, []);

  return <></>;
};

export default ConnectSocialProfilesPage;

import HeroIlustration from "@assets/bg-community-hero.svg";
import HeroIlustrationDesktop from "@assets/dashboard/header-bg-ilustrations.svg";
import { MainContainer } from "@common/MainContainer";
import { Box, breakpoints } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import styled from "styled-components";
import useSocialProfilesInfo from "../../hooks/useSocialProfilesInfo";
import MyConnectedNetworks from "./MyConnectedNetworks";
import MyContributions from "./MyContributions";
import OgBox from "./OgBox";

const useData = () => {
  const { stats } = useStoreState((state) => state.rewards);
  const { isMobile, isDesktop, isMiniTablet, isTablet } = useStoreState((state) => state.media);
  const { getStatsRequest } = useStoreActions((actions) => actions.rewards);
  const props = useSocialProfilesInfo();

  useEffect(() => {
    getStatsRequest();
  }, []);

  return {
    ...props,
    stats,
    isMobile,
    isDesktop,
    isMiniTablet,
    isTablet,
  };
};

const AuthenticatedHeader = () => {
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { stats, isMobile, isMiniTablet, isDesktop, isTablet, ...props } = useData();
  const connectNetworks = () => showModal({ modalName: "ConnectSocialMediasModal" });
  return (
    <Wrapper relative flex direction="column" color="02Primary700">
      <MainContainer>
        <InsideContainer flex justifyContent="space-between">
          <Content relative flex>
            <MyConnectedNetworks stats={stats} connectNetworks={connectNetworks} {...props} isMobile={isMobile} />
            {stats && stats.contributions_made > 1 && (
              <MyContributions stats={stats} isMobile={isMobile} isMiniTablet={isMiniTablet} />
            )}
          </Content>
          {!isMiniTablet && (isDesktop || isTablet) && <OgBox />}
        </InsideContainer>
      </MainContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  min-height: 150px;
  background: url(${HeroIlustrationDesktop});
  background-repeat: no-repeat;
  background-position-x: center;

  @media screen and (min-width: 1920px) {
    background-size: cover;
  }

  @media ${breakpoints.belowDesktop} {
    background: url(${HeroIlustration});
  }

  @media ${breakpoints.tablet} {
    padding-top: 22px;
    padding-bottom: 1px;
  }

  @media ${breakpoints.miniTablet} {
    background-size: cover;
    background-position-x: 38%;
  }

  @media ${breakpoints.mobile} {
    background-size: cover;
    background-position-x: 34%;
  }
`;

const Content = styled(Box)`
  display: inline-flex;
  z-index: 1;
  padding: 20px 145px 29px 24px;
  height: 100%;
  background: ${({ theme }) => theme.colors["headerGradient"]};
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.02));
  border-radius: 12px 12px 0px 0px;
  margin-top: auto;
  gap: 40px;
  @media ${breakpoints.tablet} {
    flex-direction: column;
    background: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    gap: 0;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    background: ${({ theme }) => theme.colors["headerGradient"]};
    filter: drop-shadow(0px 6.7574px 20.2722px rgba(0, 0, 0, 0.02));
    padding: 24px 16px;
    margin: 16px 0 0 0;
    width: 100%;
    gap: 24px;
  }
`;

const InsideContainer = styled(Box)`
  min-height: 150px;

  @media ${breakpoints.tablet} {
    background: ${({ theme }) => theme.colors["headerGradient"]};
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.02));
    border-radius: 12px 12px 0px 0px;
    align-items: flex-start;
    padding: 24px;
  }

  @media ${breakpoints.miniTablet} {
    padding: 24px;
  }
   @media ${breakpoints.mobile} {
    padding: 20px 16px;
  }
`;

export default AuthenticatedHeader;

import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("user_notifications");

const attributes = {
  notifications: [],
};

const actions = {
  setNotifications: action((state, notifications) => {
    state.notifications = notifications;
  }),
  getNotificationsRequest: thunk(({ setNotifications }) => {
    return requester.get("list").then(({ user_notifications }) => {
      setNotifications(user_notifications);
    });
  }),
  markAsReadRequest: thunk((actions, id) => {
    const url = `${id}/mark_as_seen`;

    return requester.post(url);
  }),
  markAllAsRead: thunk(({ getNotificationsRequest }) => {
    return requester.post("mark_all_as_seen").then(() => getNotificationsRequest());
  }),
};

const preferenaces = {
  ...attributes,
  ...actions,
};

export default preferenaces;

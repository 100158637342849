import { useStoreActions } from "easy-peasy";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../Loader";
import BodyRaffle from "./Body/index";
import HeroRaffle from "./Hero/index";

const RafflePage = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const contentRef = useRef(null);
  const { getRaffle, reset } = useStoreActions((actions) => actions.raffle);
  const scrollDown = () => {
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getRaffle({ id })
      .then(() => setLoading(false))
      .catch(() => navigate("/"));

    return () => {
      reset();
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <HeroRaffle scrollDown={scrollDown} />
      <div ref={contentRef}>
        <BodyRaffle />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-x: hidden;
`;

export default RafflePage;

import { Accordion, Box, breakpoints, IconSvg, Panel, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { changeTheme } from "../../utils/ThemeContainer";

const SwitchButton = ({ icon, selected, onClick }) => {
  return (
    <SwitchContainer flex selected={selected} pointer onClick={onClick}>
      <IconSvg icon={icon} width="16px" color="inherit" />
    </SwitchContainer>
  );
};

const Title = ({ icon }) => (
  <RoundedButton pointer flex>
    <IconSvg icon={icon} color="cta2" width="14px" />
  </RoundedButton>
);

const ThemeSwitcher = () => {
  const { themeName } = useContext(ThemeContext);
  const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem("theme") || "system");
  const { isTablet } = useStoreState((state) => state.media);
  const getTheme = () => {
    setSelectedTheme(localStorage.getItem("theme"));
  };

  const title = <Title icon={themeName === "dark" || themeName === "customDark" ? "moon" : "brightness"} />;

  useEffect(() => {
    window.addEventListener("theme-change", getTheme);
    return () => {
      window.removeEventListener("theme-change", getTheme);
    };
  }, []);

  return (
    <Box relative spacing={isTablet ? "mr-3" : "mr-4"}>
      <Accordion hideLine={true} hideIcon={true} title={title}>
        <Container spacing="pt-3 pb-4 pl-4" shadows bgColor="01Primary0" width="225px">
          <Text typography="body/small" color="01Primary700" spacing="mb-4">
            Theme Selector: {capitalizeFirstLetter(selectedTheme)}
          </Text>
          <Box flex gap="8px">
            <SwitchButton icon="brightness" selected={selectedTheme === "light"} onClick={() => changeTheme("light")} />
            <SwitchButton icon="moon" selected={selectedTheme === "dark"} onClick={() => changeTheme("dark")} />
            <SwitchButton icon="gear" selected={selectedTheme === "system"} onClick={() => changeTheme("system")} />
          </Box>
        </Container>
      </Accordion>
    </Box>
  );
};

const SwitchContainer = styled(Box)`
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme, selected }) => theme.colors[selected ? "03Primary700" : "01Primary300"]};
  border-radius: 50%;
  ${({ theme, selected }) => selected && `background-color: ${theme.colors["03Primary500"]};`}
  color: ${({ theme, selected }) => theme.colors[selected ? "01Primary0" : "01Primary700"]};
`;

const Container = styled(Panel)`
  position: absolute;
  padding: 1px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
  margin-top: 10px;
  z-index: 1;
  right: 0;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
  }

  &::before {
    right: 20px;
    top: -12px;
    border: 6px solid;
    border-color: transparent transparent ${({ theme }) => theme.colors["01Primary100"]}; transparent;
  }

  &::after {
    right: 21px;
	  top: -10px;
	  border: 5px solid;
  	border-color: transparent transparent ${({ theme }) => theme.colors["01Primary0"]} transparent;
  }
`;

const RoundedButton = styled(Box)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors["cta2"]};

  @media ${breakpoints.belowDesktop} {
    width: 29px;
    height: 29px;
  }
`;

export default ThemeSwitcher;

import { Button, OrderableList } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";
import TagItem from "./TagItem";

const Inputs = ({ communityTags, setCommunityTags, isMobile }) => {
  const { showToast } = useStoreActions((actions) => actions.toasts);

  const addTag = () => {
    const newTag = {
      _id: communityTags.length + 1,
      name: "",
      featured: false,
      _destroy: false,
      order: communityTags.length + 1,
    };
    setCommunityTags((prevTags) => [...prevTags, newTag]);
  };

  const handleOrderChange = (newOrderIds) => {
    const newTags = communityTags.map((tag) => ({
      ...tag,
      order: newOrderIds.indexOf(tag.id),
    }));

    setCommunityTags(newTags);
  };

  const filteredTags = communityTags?.filter((tag) => !tag._destroy);

  useEffect(() => {
    const filteredTagsFeatured = communityTags?.filter((tag) => tag.featured).length;
    const maxTags = 5;

    if (filteredTagsFeatured > maxTags) {
      showToast({
        content: `You have reached the maximum number of Featured Tags which is 5. Please unselect one tag and try again `,
        type: "warning",
        dismissTime: 5000,
      });
    }
  }, [communityTags]);

  return (
    <>
      <Button
        onClick={addTag}
        bold
        borderRadius="50px"
        width="100%"
        spacing={isMobile ? "mb-5" : "mb-6"}
        kind="outline"
        color="01Primary700"
        variant="03Primary400"
        rightIcon="plus"
      >
        Add Tag
      </Button>
      <OrderableList key={filteredTags.length} onChange={handleOrderChange}>
        {filteredTags?.map((item, index) => {
          return (
            <TagItem
              key={(item._id ?? item.id) + index}
              id={item._id ?? item.id}
              index={index}
              item={item}
              tags={communityTags}
              setTags={setCommunityTags}
              isMobile={isMobile}
            />
          );
        })}
      </OrderableList>
    </>
  );
};

export default Inputs;

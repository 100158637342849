import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MobileContributions from "./MobileContributions";
import NoRewards from "./NoRewards";
import TableOfContributions from "./TableOfContributions";

const PersonalContributions = () => {
  const navigate = useNavigate();
  const { getRewards } = useStoreActions((actions) => actions.rewards);
  const { rewards } = useStoreState((state) => state.rewards);
  const { isDesktop, isMobile } = useStoreState((state) => state.media);

  const onCommunityClick = (e) => {
    navigate(`/communities/${e.target.dataset.id}`);
  };

  useEffect(() => {
    getRewards();
  }, []);

  return (
    <Wrapper spacing="my-6+2" bgColor="01Primary0" color="02Primary700">
      <Text bold size="18px" lHeight="26px" spacing="mx-4" sm-spacing="mb-5">
        Your Personal Legend tells the story your personal contributions across Communities on ThriveCoin.
      </Text>
      {rewards.length === 0 && <NoRewards />}
      {rewards.length > 0 &&
        (isMobile ? (
          <MobileContributions contributions={rewards} onCommunityClick={onCommunityClick} />
        ) : (
          <TableOfContributions contributions={rewards} onCommunityClick={onCommunityClick} />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  border-radius: 40px;
  padding: 50px 40px;
  border: 1px solid ${({ theme }) => theme.colors.primary100};
  overflow-x: scroll;

  @media ${breakpoints.mobile} {
    border-radius: 0;
    padding: 1.5rem 0;
  }
`;

export default PersonalContributions;

import ToastService from "@utils/ToastService";
import { useContext } from "react";
import { Outlet, useLocation } from "react-router";
import { CommunityContext } from "./CommunityContext";
import Footer from "./Footer";
import Header from "./Header/index";
import useMediaQueries from "./hooks/useMediaQueries";
import { useModalRoutes } from "./modals/SignInFlowModal";
import ModalsManager from "./ModalsManager";

const HIDE_HEADER = ["/challenge_editor"];

const Layout = ({ children }) => {
  useModalRoutes();
  useMediaQueries();
  const location = useLocation();
  const { isCustomDomain } = useContext(CommunityContext);

  return (
    <>
      <ToastService />
      <ModalsManager />
      {!HIDE_HEADER.some((path) => location.pathname.includes(path)) && <Header />}
      {children}
      <Outlet />

      {(location.pathname === "/" || !!isCustomDomain) && <Footer />}
    </>
  );
};

export default Layout;

import useQuery from "../hooks/useQuery";
import { SeasonEditorProvider } from "./SeasonEditorContext";
import SeasonEditorLayout from "./SeasonEditorLayout";
import SeasonSteps from "./Steps";

const SeasonEditor = ({ edit, communityID, seasonID }) => {
  const _communityID = communityID || useQuery().get("community_id");

  return (
    <SeasonEditorProvider seasonID={seasonID} edit={edit} communityID={_communityID}>
      <SeasonEditorLayout>
        <SeasonSteps />
      </SeasonEditorLayout>
    </SeasonEditorProvider>
  );
};

export default SeasonEditor;

import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useRef } from "react";
import styled from "styled-components";

export const DEFAULT_MEDIA = {
  media: null,
  media_type: "",
};

const getFilename = (file) => {
  if (!file) return "";

  if (file.name) return file.name;

  const fileName = new URL(file).pathname.split("/").pop();
  return fileName;
};

const FileInput = ({ media, setMedia, mediaRestriction, label, subLabel, name, bold = true }) => {
  const fileAttribute = name.replace("_url", "");
  const file = media[fileAttribute];
  const { isMobile } = useStoreState((state) => state.media);
  const fileName = file?.name || getFilename(media[name]);
  const getExtension = fileName.split(".").pop();
  const getFirstCharacters = fileName.substring(0, 8);
  const mediaName = !!getExtension && !!getFirstCharacters ? getFirstCharacters + "..." + getExtension : null;
  const destroyName = `_destroy_${name}`;
  const alignItemsConfig = subLabel || isMobile ? "baseline" : "center";

  const inputRef = useRef();

  const removeMedia = () => {
    inputRef.current.value = "";
    const newMedia = { ...media };
    delete newMedia[name];
    delete newMedia[fileAttribute];
    delete newMedia[`${fileAttribute}_type`];

    setMedia({
      ...newMedia,
      [`_destroy_${fileAttribute}`]: true,
    });
  };

  const onFileChange = (event) => {
    const name = event.target.dataset.name;
    const file = event.target.files[0];
    const _file_type = file.type;
    const formattedFileType = _file_type.split("/")[0];
    const newMedia = { ...media };
    delete newMedia[`_destroy_${fileAttribute}`];

    setMedia({
      ...newMedia,
      [fileAttribute]: file,
      [name]: URL.createObjectURL(file),
      [`${fileAttribute}_type`]: formattedFileType,
    });
  };

  return (
    <Box flex direction={isMobile && "column"} gap="22px" alignItems={alignItemsConfig}>
      <Box gap={subLabel && "12px"} direction="column" flex>
        <Text typography="body/medium" color="01Primary700" bold={bold}>
          {label}
        </Text>
        <Text maxWidth="426px" typography="body/medium" color="01Primary700">
          {subLabel}
        </Text>
      </Box>
      <Container hasName={!!mediaName}>
        <WrapperInput for={name}>
          <Name> {!media[destroyName] && mediaName ? mediaName : "Choose file"}</Name>

          <input
            ref={inputRef}
            onChange={onFileChange}
            data-name={name}
            id={name}
            type="file"
            accept={mediaRestriction}
          />
        </WrapperInput>
        {!media[destroyName] && !!mediaName && (
          <IconSvg color="02Primary700" spacing="ml-a pl-2" icon="x" width="16px" onClick={removeMedia}>
            x
          </IconSvg>
        )}
      </Container>
    </Box>
  );
};

FileInput.defaultProps = {
  mediaRestriction: "image/*, video/*",
  label: "Community Logo - Custom URL (Optional)",
};

const Name = styled.div`
  max-width: 110px;
  overflow: hidden;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors["02Primary700"]};
`;

const WrapperInput = styled.label`
  cursor: pointer;

  input {
    position: absolute;
    left: 0;
    width: 105px;
    height: 33px;
    display: none;
  }
`;

const Container = styled.div`
  max-width: 142px;
  max-height: 33px;
  background: ${({ theme }) => theme.colors["grey100"]};
  border: 1px solid #e4e4ed;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 4px 12.5px;
  ${({ hasName }) => hasName && `  padding: 4px 10px 4px 6px; `}

  input {
    position: absolute;
    left: 0;
    width: 105px;
    height: 27px;
    display: none;
  }
`;

export default FileInput;

import { useStoreState } from "easy-peasy";
import AuthenticatedHeader from "./AuthenticatedHeader";
import LoggedOutHeader from "./LoggedOutHeader";

const DashboardHeader = () => {
  const { confirmed } = useStoreState((state) => state.user.user);

  if (confirmed) {
    return <AuthenticatedHeader />;
  }

  return <LoggedOutHeader />;
};

export default DashboardHeader;

import { Box, Button, IconSvg, Text } from "@thrivecoin/ui-components";
import { useEffect } from "react";
import styled from "styled-components";

const SelectView = ({ hideOptions, closed, options, selected,setSelected, onApply, isMultiple }) => {
  const onSubmit = () => onApply(selected);

  const onItemClick = (item) => {
    if (isMultiple) {
      if (selected.find((i) => i.label === item.label)) {
        const _selected = selected.filter((s) => s.label !== item.label);
        setSelected(_selected);
      } else {
        setSelected([...selected, item]);
      }
    } else {
      setSelected(item);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <Wrapper flex direction="column" justifyContent="flex-end" onClick={hideOptions}>
      <Content onClick={(e) => e.stopPropagation()} className={closed && "closing-content"}>
        <TopPart relative color="02Primary700">
          <Text>Sort by</Text>
          <CloseButton onClick={hideOptions} kind="text" variant="02Primary700" spacing="m-0 p-2">
            <IconSvg width="18px" icon="x" />
          </CloseButton>
        </TopPart>

        <ItemsContainer flex direction="column" sm-spacing="mx-4">
          {options.map((item, i) => {
            const selectedOpt = isMultiple
              ? selected.find((i) => i.label === item.label)
              : item.label === selected.label;
            return (
              <Item
                pointer
                key={i}
                onClick={() => onItemClick(item)}
                selected={selectedOpt}
                spacing="p-4 mt-4"
                borderRadius="12px"
                flex
                alignItems="center"
                gap="12px"
              >
                {isMultiple ? (
                  <Check flex selected={selectedOpt} justifyContent="center" alignItems="center">
                    {selectedOpt && <IconSvg icon="check" color="01Primary0" />}
                  </Check>
                ) : (
                  <Circle flex selected={selectedOpt} justifyContent="center" alignItems="center">
                    {selectedOpt && <Box width="10px" height="10px" borderRadius="50%" bgColor="hover" />}
                  </Circle>
                )}
                <Text color="02Primary700" extraBold={selectedOpt}>
                  {item.label}
                </Text>
              </Item>
            );
          })}
        </ItemsContainer>
        <Button
          onClick={onSubmit}
          size="lg"
          bold
          fullWidth
          height="56px"
          borderRadius="40px"
          spacing="mt-4 mx-4 mb-8+2"
          modal={true}
        >
          Apply
        </Button>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  position: absolute;
  inset: 0;
  background-color: rgba(51, 52, 61, 0.5);
  animation: animateOverlayIn 0.5s forwards;

  @keyframes animateOverlayIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  max-height: 80%;
  padding: 0 0 1.5rem;
  background-color: #fff;
  border-radius: 24px 24px 0 0;
  animation: animateIn 0.5s forwards;
  overflow-y: scroll;

  &.closing-content {
    animation: animateOut 0.5s forwards;
  }

  @keyframes animateIn {
    0% {
      transform: translate(0, 100%);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes animateOut {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 100%);
    }
  }
`;

const TopPart = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 25px 0 21px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
`;

const CloseButton = styled(Button)`
  cursor: pointer;
  z-index: 10;
  position: absolute;
  margin-left: auto !important;
  border: 1px solid ${({ theme }) => theme.colors.secondary50};
  border-radius: 50%;
  top: 1rem;
  right: 1rem;

  ${IconSvg} {
    width: 16px;
  }
`;

const Item = styled(Box)`
  ${({ selected, theme }) =>
    selected
      ? `
  border: 1px solid ${theme.colors.button};
    background-color: ${theme.colors.grey100};
  `
      : `
  border: 1px solid transparent;
  background-color: ${theme.colors.grey100};
  `}
`;

const Circle = styled(Box)`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  ${({ selected, theme }) =>
    selected
      ? `
    border: 1px solid ${theme.colors.hover};
    background-color: ${theme.colors.grey100};
  `
      : `
    border: 1px solid transparent;
    background-color: ${theme.colors.grey300};
  `}
`;
const Check = styled(Box)`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  ${({ selected, theme }) =>
    selected
      ? `
    background-color: ${theme.colors.hover};
  `
      : `
    border: 1px solid transparent;
    background-color: ${theme.colors["01Primary0"]};
  `}
`;
const ItemsContainer = styled(Box)`
  overflow-y: scroll;
  flex: 1;
`;

export default SelectView;

import { Button, IconSvg, Input, Tag } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const AddButton = styled(Button)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  svg {
    width: 20px;
    path {
      stroke: ${({ theme }) => theme.colors["01Primary700"]};
    }
  }
`;

export const WrapperInputs = styled.div`
  border-top: ${({ theme, hideBorderTop }) => hideBorderTop && `1px solid ${theme.colors["01Primary200"]}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  padding: 24px 0 0 0;
  margin: 32px 0;
`;

export const StyledInput = styled(Input)`
  input {
    margin-top: 16px;
    border-color: ${({ theme }) => theme.colors["01Primary300"]};
    background-color: transparent;
    padding: 16px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors["01Primary700"]};
    &::placeholder {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors["01Primary400"]};
    }
  }
  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors["02Primary700"]};
  }
`;

export const StyledTag = styled(Tag)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors["01Primary50"]};
`;

export const RemoveIcon = styled(IconSvg)`
  stroke-width: 1.5;
  position: absolute;
  top: 16px;
  right: 20px;
  fill: ${({ theme }) => theme.colors["01Primary700"]};
  stroke-width: 1.4px;
`;

export const OutsideBorder = styled.div`
  padding: 32px 16px 16px 16px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
  border-radius: 16px;
  margin-bottom: 32px;
  position: relative;
`;

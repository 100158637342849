import HeaderElement from "@common/HeaderElement";
import { breakpoints } from "@thrivecoin/ui-components";
import styled from "styled-components";

const ContributionsHeaderRow = ({
  onSort,
  sortType,
  sortDirection,
  hide_amounts,
  isCustomToken,
  isDesktop,
  showCommunity,
  isFromProfile,
  showRewardCommunity,
  fromProfileTablet,
}) => {
  return (
    <>
      <Row>
        {!isFromProfile && (
          <HeaderElement
            text="Contributor"
            name="contributor"
            onSort={onSort}
            sort={sortType === "contributor" ? sortDirection : null}
          />
        )}
        <HeaderElement
          text="Contribution"
          firstElement
          name="title"
          onSort={onSort}
          sort={sortType === "title" ? sortDirection : null}
        />
        {showRewardCommunity && (
          <>
            {showCommunity && <HeaderElement text="Community" padding={fromProfileTablet && "0"} />}
            {!hide_amounts && (
              <HeaderElement
                text={isCustomToken ? "End of Season Prize" : "Reward"}
                name="reward"
                onSort={onSort}
                sort={sortType === "reward" ? sortDirection : null}
              />
            )}
          </>
        )}
        <HeaderElement
          text="Date"
          name="date"
          onSort={onSort}
          minWidth={fromProfileTablet ? "96px" : "112px"}
          padding={fromProfileTablet && "12px 24px 12px 0"}
          sort={sortType === "date" ? sortDirection : null}
        />
        {isDesktop && <HeaderElement minWidth="215px" text="" />}
      </Row>
    </>
  );
};

const Row = styled.tr`
  th {
    padding-bottom: 14.88px;
    padding-left: 24px;
    @media ${breakpoints.belowDesktop} {
      padding-top: 12px;
      padding-bottom: 14.57px;
    }
  }
`;

export default ContributionsHeaderRow;

import { Box, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import styled from "styled-components";

const RemoveAdminModal = ({ hideModal, admin, goToCommunity }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const { community } = useStoreState((state) => state.community);
  const { id } = useStoreState((state) => state.user);
  const { removeAdminRequest, getAdministratorsRequest } = useStoreActions((actions) => actions.members);
  const { refresh } = useStoreActions((actions) => actions.authentication);

  const onClick = () => {
    removeAdminRequest({ communityId: community.id, memberId: admin.id }).then(() => {
      hideModal();
      if (id == admin.id) {
        refresh();
        goToCommunity();
      } else {
        getAdministratorsRequest({ communityId: community.id, keyword: "" });
      }
    });
  };

  const footerContent = (
    <ButtonsWrapper direction={isMobile && "column"} gap="1rem" flex>
      <Button
        size="lg"
        heightSize="56px"
        borderRadius="56px"
        semiBold
        onClick={hideModal}
        grow="1"
        kind="outline"
        borderColor="secondary200"
        variant="secondary200"
        color="02Primary700"
        modal={true}
      >
        No, I don't want to do that
      </Button>
      <Button
        size="lg"
        heightSize="56px"
        semiBold
        kind="outline"
        borderRadius="56px"
        onClick={onClick}
        color="destructive500"
        borderColor="destructive500"
        borderWidth="2px"
        grow="1"
        modal={true}
      >
        Yes, Remove as Admin
      </Button>
    </ButtonsWrapper>
  );

  return (
    <Modal open onClose={hideModal} leaf={true} maxWidth="684px" footerContent={footerContent}>
      <Wrapper>
        <Text bold type="header" size="28px" lineHeight="36px">
          Remove as Admin?
        </Text>
        <Text color="02Primary700" spacing="mb-6 mt-5">
          Are you sure you want remove adminstrator privileges from <Username>{admin.username}</Username>?
        </Text>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  text-align: center;
  margin-top: 17px;
`;

const ButtonsWrapper = styled(Box)`
  margin-bottom: 1.5rem;
`;

const Username = styled.span`
  font-weight: bold;
`;

RemoveAdminModal.defaultProps = {
  admin: {},
};

export default RemoveAdminModal;

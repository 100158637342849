import { Box, Input } from "@thrivecoin/ui-components";
import styled from "styled-components";

const InputGroup = ({ field, label, placeholder, required, InputClass = Input, ...props }) => {
  return (
    <StyledBox spacing="mb-6" sm-spacing="mx-4 mb-5">
      <Label htmlFor={field}>{label || field}</Label>
      <InputClass spacing="mt-3" name={field} placeholder={placeholder} required={required} {...props} />
    </StyledBox>
  );
};

const Label = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors["01Primary700"]};
`;

const StyledBox = styled(Box)`
  input {
    background-color: ${({ theme }) => theme.colors["01Primary50"]};
    &::placeholder {
      color: ${({ theme }) => theme.colors["01Primary400"]};
    }
  }
  textarea {
    background-color: ${({ theme }) => theme.colors["01Primary50"]};
    max-height: 130px;
    &::placeholder {
      color: ${({ theme }) => theme.colors["01Primary400"]};
    }
  }
`;

export default InputGroup;

import { Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import fireEvent from "../../../../utils/fireEvent";

const ColorsPalette = ({ bannerColor, title, subtitle, Colors, name }) => {
  const onClick = (event) => {
    event.preventDefault();
    const { color } = event.target.dataset;
    const element = document.getElementsByName(name)[0];
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
    nativeInputValueSetter.call(element, color);
    fireEvent(element, "change", true, false);
  };

  return (
    <>
      <Text spacing="mb-3" typography="body/medium" color="01Primary700" bold>
        {title}
      </Text>
      {subtitle && (
        <Text spacing="mb-5" typography="body/medium" color="01Primary700">
          {subtitle}
        </Text>
      )}
      <WrapperColors>
        <input type="text" name={name} hidden />
        {Colors?.map((color, index) => (
          <Color
            data-color={color}
            key={index}
            onClick={onClick}
            backgroundColor={color}
            borderColor={bannerColor === color ? "01Primary700" : color}
          />
        ))}
      </WrapperColors>
    </>
  );
};
const WrapperColors = styled.div`
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const Color = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  cursor: pointer;
  border: 5px solid transparent;
  background-color: ${(backgroundColor) => backgroundColor};
  border-color: ${({ borderColor, theme }) => theme.colors[borderColor] || borderColor};
`;

export default ColorsPalette;

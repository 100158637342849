import { Box, Input, Text, TextArea } from "@thrivecoin/ui-components";
import {
  FloatInput,
  InputCheckbox,
  InputGroup,
  InputHidden,
  InputSelect,
  IntegerInput,
  RichText,
  TextList,
} from "../../common";

const TYPE_CLASS_MAP = {
  text: Input,
  number: IntegerInput,
  decimal: FloatInput,
  textarea: TextArea,
  hidden: InputHidden,
  checkbox: InputCheckbox,
  select: InputSelect,
  json: TextList,
  richtext: RichText,
};

const Separator = (props) => <Box height="1px" spacing="my-6" bgColor="01Primary200" {...props} />;

function insertSeparator(array, Klass) {
  const newArray = [];

  for (let i = 0; i < array.length; i++) {
    newArray.push(array[i]);
    if (i < array.length - 1) {
      newArray.push(<Klass key={`separator-${i}`} />);
    }
  }

  return newArray;
}

const TemplateForm = ({ templateFields, setState, formData }) => {
  if (!templateFields.length) {
    return null;
  }

  let fields = templateFields.map(({ placeholder, options, value, name, type, required, key, disabled }) => {
    const labelSuffix = required ? "Required" : "Optional";
    const label = `${name} (${labelSuffix})`;
    return (
      <InputGroup
        key={key}
        field={key}
        label={type === "hidden" ? "" : label}
        fieldName={name}
        value={formData[key] || value}
        defaultChecked={formData[key] === undefined ? value : formData[key]}
        InputClass={TYPE_CLASS_MAP[type]}
        options={options}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        setState={setState}
      />
    );
  });

  fields = insertSeparator(fields, Separator);

  return (
    <Box spacing="mt-4" color="01Primary700">
      <Separator />
      <Text typography="heading/small" spacing="mb-6">
        Submission and Review
      </Text>
      {fields}
    </Box>
  );
};

export default TemplateForm;

import { action, thunk } from "easy-peasy";

/// ///////////Actions//////////////

const sortFN = ({ modalOrder: valueA }, { modalOrder: valueB }) => valueA - valueB;
const actions = {
  closeModals: action((state) => (state.modals = [])),
  showModal: thunk((actions, props, { getState }) => {
    const { modals } = getState();
    const modalNames = modals.map(({ modalName }) => modalName);
    if (modalNames.indexOf(props.modalName) === -1) {
      actions.pushModal(props);
    }
  }),
  pushModal: action((state, { modalName, modalOrder = 10, ...modalProps }) => {
    const data = { modalName, modalProps, modalOrder };
    const modals = [...state.modals, data].sort(sortFN);

    state.modals = modals;
  }),
  hideModal: action((state) => {
    state.modals.pop();
  }),
  hideModalByName: action((state, name) => {
    const modals = state.modals.filter(({ modalName }) => modalName !== name);
    state.modals = [...modals];
  }),
  closeOthers: thunk(({ setModals }, modalName, { getState }) => {
    const { modals } = getState();
    const newModals = modals.filter((item) => item.modalName === modalName);
    setModals(newModals);
  }),
  setModals: action((state, modals) => {
    state.modals = modals.sort(sortFN);
  }),
};

/// ///////////attributes//////////////
const attributes = {
  modals: [],
};

const modalsModel = {
  ...attributes,
  ...actions,
};

export default modalsModel;

import { TabsContainer } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import { useParams } from "react-router";
import Contributors from "../../Contributors/index";
import RaffleFAQ from "../../Raffle/Body/RaffleFAQ/RaffleFAQ";
import RafflePrizes from "../../Raffle/Body/RafflePrizes";
import useColors from "../useColors";
import AboutSeason from "./AboutSeason";
import Contributions from "./Contributions";
import WaysToContribute from "./WaysToContribute/index";

const selectedTabIndex = (tab, headers) => {
  if (tab === "WaysToContribute") {
    if (headers.includes("WaysToContribute")) {
      return 1;
    }
    return 0;
  }
  return tab;
};

const DEFAULT_TAB_CONFIG = {
  tabClasses: [AboutSeason, Contributors, Contributions],
  headers: ["About Season", "Contributors ", "Contributions"],
};

const COMPLETED_TOKEN_SEASON = {
  tabClasses: [AboutSeason, Contributors, Contributions],
  headers: ["About Season", "Contributors ", "Contributions"],
};

const ACTIVE_TOKEN_SEASON = {
  tabClasses: [WaysToContribute, AboutSeason, Contributors, Contributions],
  headers: ["Ways to contribute", "About Season", "Contributors ", "Contributions"],
};

const COMPLETED_RAFFLE_SEASON = {
  tabClasses: [RafflePrizes, RaffleFAQ, Contributors],
  headers: ["Raffle Prizes", "Rules & FAQ", "Contributors"],
};

const ACTIVE_RAFFLE_SEASON = {
  tabClasses: [WaysToContribute, Contributors, Contributions, RafflePrizes, RaffleFAQ],
  headers: ["Ways to contribute", "Contributors", "Contributions", "Raffle Prizes", "Rules & FAQ"],
};

const SEASONS_TAB_CONFIG = {
  custom_token: {
    active: { ...ACTIVE_TOKEN_SEASON },
    closed: { ...COMPLETED_TOKEN_SEASON },
    draft: { ...ACTIVE_TOKEN_SEASON },
    upcoming: { ...ACTIVE_TOKEN_SEASON },
    completed: { ...COMPLETED_TOKEN_SEASON },
    claim_opens_soon: { ...COMPLETED_TOKEN_SEASON },
    open_to_claim: { ...COMPLETED_TOKEN_SEASON },
  },
  raffle: {
    active: { ...ACTIVE_RAFFLE_SEASON },
    closed: { ...COMPLETED_RAFFLE_SEASON },
    completed: { ...COMPLETED_RAFFLE_SEASON },
    draft: { ...ACTIVE_RAFFLE_SEASON },
    upcoming_raffle: { ...COMPLETED_RAFFLE_SEASON },
    upcoming: { ...ACTIVE_RAFFLE_SEASON },
  },
};

const getConfig = (reward_system, status) => {
  return (SEASONS_TAB_CONFIG[reward_system] || {})[status] || DEFAULT_TAB_CONFIG;
};

const BodySeason = ({ currentTabIndex, setCurrentTabIndex, season, onRaffleDetails }) => {
  const { id } = useParams();
  const { getActiveSeasonListingsRequest } = useStoreActions((actions) => actions.season);
  const { reward_system, status } = season || {};
  const { tabClasses, headers } = getConfig(reward_system, status);
  const { theme } = useColors(season);

  const onChangeTab = (index) => setCurrentTabIndex(index);
  const tabIndex = selectedTabIndex(currentTabIndex, headers);

  const tabProps = {
    id,
    onRaffleDetails,
    group: "season",
    getListings: getActiveSeasonListingsRequest,
    raffle_id: season?.raffle_id,
    seasonPage: true,
    raffleCompleted: season?.status === "raffle_completed",
    seasonId: season.id,
    statsColor: theme.statsColor,
  };

  return (
    <TabsContainer
      tabProps={tabProps}
      selectedIndex={tabIndex}
      tabClasses={tabClasses}
      headers={headers}
      onChangeIndex={onChangeTab}
      className="tabsContainer"
      selectedTabBgColor={theme.tabBgColor}
      selectedTabColor={theme.tabColor}
      bgTagContainer="surfaces"
    />
  );
};

export default BodySeason;

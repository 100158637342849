import TabWrapper from "@common/TabWrapper";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import TableContributions from "../../../CommunityPage/Challenges/Contributions/TableContributions";
import TableTab from "../../../CommunityPage/Challenges/Contributions/TableTab";
import useSort from "../../../hooks/useSort";
import { SEASON_CONTRIBUTION_SORT_OPTIONS } from "../../../SortOptions";

const sortOrder = [
  "# of Contributions (highest to lowest)",
  "# of Contributions (lowest to highest)",
  "Rewards (highest to lowest)",
  "Rewards (lowest to highest)",
  "Contribution (A-Z)",
  "Contribution (Z-A)",
  "Contributor (A-Z)",
  "Contributor (Z-A)",
];

const sortArray = (array) => array.sort((a, b) => sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label));

const modalProps = {
  sortOptions: sortArray(SEASON_CONTRIBUTION_SORT_OPTIONS),
  backLabel: "Back to Season Page",
  filterLabel: "Contributions",
};

const SeasonContributions = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { season, contributionsSeason, contributionsSeasonPagination } = useStoreState((state) => state.season);
  const { getContributionsSeason, getActiveSeasonRequest } = useStoreActions((actions) => actions.season);
  const title = season.title ? `Contributions to the ${season.title} Season` : "";
  const sortProps = useSort(getContributionsSeason, "date");
  const rewards_token = season.rewards_token?.toUpperCase();
  const isCustomToken = season.reward_system == "custom_token";

  const onCommunityClick = (e) => navigate(`/communities/${e.target.dataset.id}`);

  const onProfileClick = (e) => {
    const user_id = e.target.dataset.userid;
    navigate(`/${user_id}/profile`);
  };

  const rowProps = {
    onCommunityClick,
    onProfileClick,
    rewards_token,
    isCustomToken,
  };

  useEffect(() => {
    id && getActiveSeasonRequest(id);
  }, [id]);

  return (
    <TableTab
      title={title}
      titleSearch={true}
      sortOptions={modalProps.sortOptions}
      TableComponent={TableContributions}
      data={contributionsSeason}
      pagination={contributionsSeasonPagination}
      sortProps={sortProps}
      rowProps={rowProps}
      modalProps={modalProps}
      isContributions={true}
      WrapperKlass={TabWrapper}
    />
  );
};

export default SeasonContributions;

import { normalize } from "@utils/socialUtils";
import { useStoreActions, useStoreState } from "easy-peasy";
import useConnectSocialMedias from "../../../hooks/useConnectSocialMedias";
import useWeb3 from "../../../hooks/useWeb3";

const checkSocialMedia = (profiles, types) => types.filter((type) => profiles.some((obj) => obj.provider === type));

const useWaysToContribute = () => {
  const { loginFlow, installMetamask, hasWeb3Provider } = useWeb3();
  const referral_code = sessionStorage.getItem("referral-code");
  const { user } = useStoreState((state) => state.user);
  const { socialProfiles } = user;
  const { showModal } = useStoreActions((actions) => actions.modals);
  const connectionMethods = useConnectSocialMedias(true);

  const networkConnections = (type, networks) => {
    const { provider } = normalize(type) || {};
    let providers = [];
    if (networks && networks.length > 0) {
      providers = networks.map((obj) => obj.identifier);
    }
    if (provider) {
      providers.push(provider);
    }
    const networksCount = providers.length;
    const _connectedNetworks = checkSocialMedia(socialProfiles, providers);
    const connectedNetworks = _connectedNetworks.length;
    const socialProfileConnected = networksCount > 0 ? networksCount === connectedNetworks : user.confirmed;

    return { networks: providers, connectedNetworks, socialProfileConnected };
  };

  const readMore = (id) => showModal({ modalName: "ActivityDetailsModal", id });

  const onRefresh = () => {};

  const onSignUp = () => (hasWeb3Provider ? loginFlow(referral_code) : installMetamask());

  const manuallyComplete = (activity, callback) =>
    showModal({ modalName: "ConfirmContributionModal", activity, callback });

  const connectSocialMedias = (app) => {
    const { displayName, provider } = normalize(app);

    if (provider.includes("discourse")) {
      return connectionMethods.discourse(displayName, provider);
    }

    if (provider) {
      return connectionMethods[provider]();
    }

    showModal({ modalName: "ConnectSocialMediasModal" });
  };

  const connectMultiNetworks = (networks) => {
    showModal({ modalName: "ConnectMultiNetworks", networks });
  };

  return {
    onRefresh,
    onSignUp,
    networkConnections,
    readMore,
    connectSocialMedias,
    manuallyComplete,
    connectMultiNetworks,
  };
};

export default useWaysToContribute;

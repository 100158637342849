import { action } from "easy-peasy";

const attributes = {
  message: "",
  type: "",
};

const actions = {
  setSuccess: action((state, message) => {
    state.message = message;
    state.type = "success";
  }),
  setError: action((state, message) => {
    state.message = message;
    state.type = "error";
  }),
  hideNotice: action((state) => {
    state.message = "";
    state.type = "";
  }),
};

export default {
  ...attributes,
  ...actions,
};

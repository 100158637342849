import React from "react";
import styled from "styled-components";

const Switcher = ({ checked, onChange }) => {
  const toggle = (e) => {
    onChange(e.target.checked);
  };

  return (
    <StyledLabel>
      <input type="checkbox" checked={checked} onChange={toggle} />
      <StyledSpan checked={checked} />
    </StyledLabel>
  );
};

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 54px;
  min-width: 54px;
  height: 27px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const StyledSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  transition: 0.4s;
  background-color: ${({ checked, theme }) => (checked ? theme.colors.button : theme.colors["01Primary100"])};

  &:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    border-radius: 50%;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;

    ${({ checked }) =>
      checked &&
      `
     transform: translateX(25px);
     `}
  }
`;

export default Switcher;

import { useEffect, useState } from "react";
import HttpWrapper from "../../../../redux/httpRequester";
const requester = new HttpWrapper("contribution_apps");

let urls;

const useGetDiscourseUrl = (provider) => {
  const [fetched, setFetched] = useState(Boolean(urls));

  useEffect(async () => {
    if (!fetched) {
      const response = await requester.get("/discourse_forums");
      urls = response.discourse_forums;
      setFetched(true);
    }
  }, []);

  return fetched ? urls[provider] : "";
};

export default useGetDiscourseUrl;

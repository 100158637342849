import { Box, breakpoints, TabsContainer, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import HeroBanner from "./HeroBanner";
import PersonalContributions from "./PersonalContributions";

const TABS = [PersonalContributions];
const headers = ["Your Personal Contributions"];

const Legend = () => {
  let tabClasses = TABS;

  const extraHeaderElement = (
    <Tab flex alignItems="center" hieght="34px">
      <Text color="02Primary700">Relics Collected (Coming soon)</Text>
    </Tab>
  );

  return (
    <Box bgColor="#E9E9E9">
      <HeroBanner />
      {/* <Progress /> */}
      <Content spacing="mx-a mt-6+2">
        <TabsContainer tabClasses={tabClasses} headers={headers} extraHeaderElement={extraHeaderElement} />
      </Content>
    </Box>
  );
};

const Content = styled(Box)`
  width: min(1302px, 100% - 32px);
  margin-bottom: 80px;
  @media ${breakpoints.mobile} {
    max-width: unset;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }
`;

const Tab = styled(Box)`
  padding: 0 10px;
  opacity: 0.5;
  white-space: nowrap;
`;

export default Legend;

import { useEffect } from "react";
import ReactGA from "react-ga4";
import { formatUrl } from "./useTracking";

const useVirtualPageView = ({ page = "", pathname }) => {
  useEffect(() => {
    virtualPageView({ page, pathname });
  }, [page]);
};

export const virtualPageView = ({ page = "", pathname: _pathname }) => {
  const pathname = _pathname || location.pathname;
  const _page = formatUrl(pathname + page);
  ReactGA.send({ hitType: "pageview", page: _page });
};

export default useVirtualPageView;

import { Box, Loader } from "@thrivecoin/ui-components";

const LoaderTable = ({ loading }) => {
  return (
    <>
      {loading && (
        <Box width="100%" spacing="m-a p-9+6" flex alignItems="center" justifyContent="center">
          <Loader />
        </Box>
      )}
    </>
  );
};

export default LoaderTable;

import { Box, breakpoints } from "@thrivecoin/ui-components";
import React, { useContext } from "react";
import styled from "styled-components";
import { SeasonEditorContext } from "../SeasonEditorContext";
import AboutSeason from "./About";
import SeasonDetails from "./Details";
import SeasonOverview from "./Overview";
import SeasonPrizes from "./Prizes";

const STEPS = [SeasonDetails, AboutSeason, SeasonOverview, SeasonPrizes];

const SeasonSteps = () => {
  const { currentStep } = useContext(SeasonEditorContext);

  const Klaas = STEPS[currentStep] || React.Fragment;

  return (
    <Wrapper flex grow={1} direction="column">
      <Klaas />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  align-items: center;

  @media ${breakpoints.mobile} {
    align-items: unset;
  }
`;

export default SeasonSteps;

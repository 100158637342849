import removeEmptyFields from "@utils/removeEmptyFields";
import { action, computed, thunk } from "easy-peasy";
import memoizerific from "memoizerific";
import toFormdata from "../../components/utils/toFormData";
import HttpWrapper from "../httpRequester";
import { deNormalizeCollection, denormalizeEntity } from "../normalizerSchema";

const rewardRequester = new HttpWrapper("rewards");
const walletsRequester = new HttpWrapper("additional_wallets");
const requester = new HttpWrapper("users");

const CACHE_SIZE = 1000;

const DEFAULT_PAGINATION = {
  page: 1,
  total: 1,
  per_page: 20,
};

const StateResolver = (state, storeState) => ({ id: storeState.authentication.userID, entities: storeState.entities });
const walletResolver = (state, storeState) => ({ address: state.user?.address, entities: storeState.entities });
const rolesResolver = (state) => ({
  isThriveAdmin: state.isThriveAdmin,
  roles_by_community: state.user.roles_by_community,
});

const USER = { wallets: [], thrive_coin_roles: [], socialProfiles: [], additionalWallets: [], roles_by_community: {} };

const attributes = {
  id: undefined,
  hasLoggedIn: false,
  notificationSettings: {},
  isThriveAdmin: computed([(state) => state.user.thrive_coin_roles], (roles) => roles.includes("admin")),
  isAdmin: computed([(state) => state.user.roles_by_community], (rolesObject) => {
    const roles = Object.values(rolesObject);
    return roles.includes("admin") || roles.includes("owner");
  }),
  isAnyAdmin: computed(
    [(state) => ({ isAdmin: state.isAdmin, isThriveAdmin: state.isThriveAdmin })],
    ({ isAdmin, isThriveAdmin }) => isAdmin || isThriveAdmin
  ),
  user: computed([StateResolver], ({ id, entities }) => {
    const denormalizedUser = denormalizeEntity(id, entities, "user") || {};
    return { ...USER, ...denormalizedUser };
  }),
  wallets: computed([walletResolver], ({ address, entities }) => {
    //TODO: Revisit this once the user can have multiple wallets
    if (!address) {
      return [];
    }

    //TODO: When the address is not yet fetched, it doesnt exist on the entities store
    const result = deNormalizeCollection([address], entities, "wallet") || [{ address }];
    return result[0] ? result : [{ id: address, address }];
  }),
  justLoggedOut: computed(({ id, hasLoggedIn }) => {
    return id == undefined && hasLoggedIn;
  }),
  referredUsers: [],
  referredUsersPagination: { ...DEFAULT_PAGINATION, total: 0 },
  freshAccount: computed((state) => {
    const created_at = state.user.created_at;
    if (!created_at) return false;

    const currentTime = new Date().getTime();
    const createdTime = new Date(created_at).getTime();
    const difference = currentTime - createdTime;
    const minutes = difference / (1000 * 60);
    return minutes < 10;
  }),
};

const actions = {
  setReward: action((state, reward) => {
    state.reward = reward;
  }),
  isAdminOfCommunity: computed([rolesResolver], ({ isThriveAdmin, roles_by_community }) => {
    return memoizerific(50)((id) => {
      const role = roles_by_community[id];
      return isThriveAdmin || role == "owner" || role == "admin";
    }, CACHE_SIZE);
  }),
  isOwnerOfCommunity: computed([rolesResolver], ({ isThriveAdmin, roles_by_community }) => {
    return memoizerific(50)((id) => {
      const role = roles_by_community[id];
      return isThriveAdmin || role == "owner";
    }, CACHE_SIZE);
  }),
  getTotalRewards: thunk(({ setReward }) => {
    return rewardRequester.get("total_amount").then((data) => {
      setReward(data.total_amount);
      return data;
    });
  }),
  updateProfileRequest: thunk((actions, { username, description, image, image_url }) => {
    const profileData = removeEmptyFields({ username, description, image, image_url });
    const data = toFormdata("user", profileData);
    return requester.patch("current/update_profile", data);
  }),
  updateProfileImageRequest: thunk((actions, file) => {
    const url = `upload_image`;
    const fileData = new FormData();

    if (file && file !== "") {
      fileData.append("image", file, file.name);
    }

    return requester.post(url, fileData);
  }),

  updateNotificationEmailRequest: thunk((action, notifications_email) => {
    return requester.patch("current/update_notifications_email", { notifications_email });
  }),
  updateNotificationSettings: thunk((action, params) => {
    return requester.patch("current/notification_setting", params);
  }),
  verifyNotificationEmailRequest: thunk((action, confirmation_code) => {
    return requester.patch("current/verify_notifications_email", { confirmation_code });
  }),
  getNotificationSettings: thunk(({ setNotificationSettings }) => {
    return requester.get("current/notification_setting").then(({ setting }) => {
      setNotificationSettings(setting);
    });
  }),
  getExtraWalletsRequest: thunk((_, __, { getStoreActions, getStoreState }) => {
    return walletsRequester.get("/list").then(({ additional_wallets: additionalWallets }) => {
      const { normalizeCollection } = getStoreActions().entities;
      const { userID } = getStoreState().authentication;
      const updatedUser = { id: userID, additionalWallets };
      normalizeCollection({ data: [updatedUser], entityName: "user" });
    });
  }),
  getReferredUsers: thunk(({ setRefferedUsers }, { page, keyword }) => {
    return requester
      .get("current/referred_users", { page, keyword })
      .then(({ users, pagination }) => setRefferedUsers({ users, pagination }));
  }),
  setNotificationSettings: action((state, settings) => {
    state.notificationSettings = settings;
  }),
  setRefferedUsers: action((state, { users, pagination }) => {
    state.referredUsers = users;
    state.referredUsersPagination = pagination;
  }),
  setID: action((state, id) => {
    state.hasLoggedIn = true;
    state.id = id;
  }),
  removeID: action((state) => {
    state.id = undefined;
  }),
};

const preferenaces = {
  ...attributes,
  ...actions,
};

export default preferenaces;

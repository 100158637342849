import Divider from "@common/Divider";
import SocialButton from "@common/SocialButton";
import { Box, Button, Text } from "@thrivecoin/ui-components";
import { normalize } from "@utils/socialUtils";
import useConnectSocialMedias from "../../hooks/useConnectSocialMedias";
import { writeModalConfig } from "../../hooks/useContinueModalFlow";

const SelectANetworkStep = ({ gotoSelectDiscourse, manualFill, goToDiscourse, useDiscourse, ...props }) => {
  const Content = useDiscourse ? DiscourseButtons : SocialButtons;

  return (
    <Box>
      <Text typography="heading/medium" bold spacing="mb-4">
        Do you want to want to auto-fill your profile information?
      </Text>
      <Text typography="body/medium" spacing="mb-6">
        You may select a network from which to auto-fill your profile information. We will only gather your username,
        photo, and bio (if available).
      </Text>
      <Box flex direction="column" gap="1.5rem">
        <Content goToDiscourse={goToDiscourse} gotoSelectDiscourse={gotoSelectDiscourse} {...props} />
        <Divider spacing="my-4" />
        <Button borderRadius="40px" onClick={manualFill} modal={true}>
          I will fill it in manually
        </Button>
      </Box>
    </Box>
  );
};

const DiscourseButtons = ({ discourseConnections, goToDiscourse }) => {
  const discourseKeys = Object.keys(discourseConnections);
  const configs = discourseKeys.map(normalize);
  const onClick = (event) => {
    const { name, provider } = event.target.dataset;
    goToDiscourse(name, provider);
  };

  return configs.map(({ provider, displayName }) => (
    <SocialButton key={provider} onClick={onClick} data-name={displayName} data-provider={provider} network="discourse" modal={true}>
      ${displayName}
    </SocialButton>
  ));
};

const SocialButtons = ({ gotoSelectDiscourse, connectedNetworks, onConnected }) => {
  const socialMediaConnectors = useConnectSocialMedias(false, false);
  const discordConnected = connectedNetworks.includes("discord");
  const twitterConnected = connectedNetworks.includes("twitter");

  const onClick = (event) => {
    const { connected, provider } = event.target.dataset;
    if (connected === "true") {
      onConnected(provider);
      return;
    }
    writeModalConfig({ modalName: "UpdateProfileFlowModal", provider });
    socialMediaConnectors[provider]();
  };

  return (
    <>
      <SocialButton
        network="discord"
        data-provider="discord"
        onClick={onClick}
        data-connected={discordConnected}
        isConnected={discordConnected}
      >
        Auto-fill from Discord
      </SocialButton>
      <SocialButton
        network="twitter"
        data-network="twitter"
        onClick={onClick}
        data-connected={twitterConnected}
        isConnected={twitterConnected}
      >
        Auto-fill from Twitter
      </SocialButton>
      <SocialButton network="discourse" onClick={gotoSelectDiscourse}>
        Auto-fill from Discourse
      </SocialButton>
    </>
  );
};

export default SelectANetworkStep;

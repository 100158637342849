import { useEffect } from "react";
import { getInstance as _getInstance } from "../web3wrapper";
import connectorList from "./connectors/connectorList.js";
import ProviderManager from "./providerManager";

let instance = _getInstance();
let providerManager;
const KEY = "_thrivecoin.connector";
const setConnectorName = (name) => localStorage.setItem(KEY, name);
const removeConnectorName = () => localStorage.removeItem(KEY);
export const getConnectorName = () => localStorage.getItem(KEY);

export const getInstance = () => instance;

export const getProvider = async () => {
  if (instance.provider) return instance.provider;

  return instance.login();
};

export const useSetUpWeb3Provider = () => {
  useEffect(() => {
    providerManager = new ProviderManager();
    connectorList.forEach((connector) => {
      providerManager.addConnector(connector);
    });

    reconnectWalletProvider();
  }, []);
};

export async function reconnectWalletProvider(promptForConnection){
  return getConnector().then(({ isLoggedIn, connectorName }) => {
    if (promptForConnection) {
      return doConnect(connectorName); //login regardless of having a connected wallet
    } else {
      return isLoggedIn && doConnect(connectorName); //login when we have a connected account on the provider
    }
  });
}

export async function getConnector() {
  const connectorName = getConnectorName();
  if (connectorName) {
    const walletConnector = providerManager.getConnector(connectorName);
    const isLoggedIn = await walletConnector.isLoggedIn();
    return { isLoggedIn, connectorName };
  }

  return false;
}

export async function doConnect(connectorName, rememberProvider = true, useAsProvier = true) {
  const walletConnector = providerManager.getConnector(connectorName);
  const localProvider = await walletConnector.connect();

  if (localProvider) {
    rememberProvider && setConnectorName(connectorName);
    useAsProvier && _getInstance().setProvider(localProvider);
  }

  return localProvider;
}

export async function doLogout() {
  const { connectorName } = await getConnector();
  if (connectorName) {
    const walletConnector = providerManager.getConnector(connectorName);
    await walletConnector.logout();
    await _getInstance().removeProvider();
    removeConnectorName();
  }
}

export default useSetUpWeb3Provider;

import { Box, breakpoints } from "@thrivecoin/ui-components";
import { useContext } from "react";
import styled from "styled-components";
import PreviewCommunityPage from "../CommunityPage/PreviewCommunity";
import { CommunityEditorContext } from "./CommunityEditorContext";
import Steps from "./Steps";

const CommunityEditorLayout = () => {
  const { previewMode } = useContext(CommunityEditorContext);

  return (
    <Box color="01Primary700">
      <WrapperCard previewMode={previewMode}>
        <Steps />
      </WrapperCard>
      {previewMode && <PreviewCommunityPage />}
    </Box>
  );
};

const WrapperCard = styled(Box)`
  background: ${({ theme }) => theme.colors["01Surface"]};
  border-radius: 12px 12px 0 0;
  ${({ previewMode }) => !previewMode && "height: calc(100vh - 64px);"}
  transition: all 3s ease;
  ${({ previewMode }) =>
    !previewMode &&
    `
  overflow-y: auto;
  `}
  z-index: 11;

  @media ${breakpoints.mobile} {
    background: ${({ theme }) => theme.colors["background"]};
    width: 100vw;
    height: calc(100vh - 58px);
    border-radius: 0;
  }
`;

export default CommunityEditorLayout;

import { Box, IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";

const ToggleButton = ({ expanded, setExpanded }) => {
  const icon = expanded ? "chevron-up" : "chevron-down";
  const toggle = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  return (
    <CustomButton onClick={toggle}>
      <IconSvg icon={icon} width="22px" color="02Primary0" />
    </CustomButton>
  );
};

const CustomButton = styled(Box)`
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors["02Primary0"]};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ToggleButton;

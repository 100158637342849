/* eslint-disable max-len */
import InputGroup from "@common/InputGroup";
import { Box, Text } from "@thrivecoin/ui-components";
import { useContext } from "react";
import styled from "styled-components";
import RichEditor from "../../../common/RichEditor";
import { CommunityEditorContext } from "../../CommunityEditorContext";
import SocialLinks from "./SocialLinks";

const AboutSelectionStep = ({ communityValues, setState }) => {
  const { socialsArray, setSocialsArray } = useContext(CommunityEditorContext);
  const handleChange = (name) => (value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <form className="listing-form">
      <InputGroup
        field="website"
        label="Website link (Optional)"
        placeholder="E.g. https://www.thrivecoin.com/"
        defaultValue={communityValues.social_links?.website}
      />
      <Divider sm-spacing="mx-4 my-5" />
      <SocialLinks socialsArray={socialsArray} setSocialsArray={setSocialsArray} />
      <Divider sm-spacing="mx-4 my-5" />
      <Box sm-spacing="mx-4">
        <Text typography="heading/small" spacing="mb-6" sm-spacing="mb-5">
          About Community
        </Text>
        {inputFields.map((field) => (
          <>
            <Text bold spacing="mb-4">
              {field.label} (Optional)
            </Text>
            <Box height="165px" flex direction="column" spacing="mb-6">
              <RichEditor
                placeholder={field.placeholder}
                value={communityValues[field.name]}
                onChange={handleChange(field.name)}
              />
            </Box>
          </>
        ))}
      </Box>
    </form>
  );
};

const inputFields = [
  // {
  //   name: "about",
  //   label: "About (Optional)",
  //   placeholder: "",
  // },
  {
    name: "origin",
    label: "Origin ",
    placeholder:
      "E.g. We are obsessed with leveraging web3 tools to better serve humanity. We created this community those equally excited in web3’s potential.",
  },
  {
    name: "members",
    label: "Members ",
    placeholder:
      "This is a highly distributed group who wants to contribute to using web3 technology to improve lives. You may be web3 native, curious, or new to the space.",
  },
  {
    name: "vision",
    label: "Vision ",
    placeholder:
      "We believe that web3 offers an opportunity for people to create a truly decentralized ecosystem, and one in which equity can be achieved across the world.",
  },
  {
    name: "impact",
    label: "Impact ",
    placeholder:
      "By May 31, 2022, 1000 Community members will have finished a challenge, and 200 Community members will have shared feedback on their experience.",
  },
  {
    name: "get_involved",
    label: "Getting in touch and involved ",
    placeholder:
      "If you’d like to learn more, offer feedback, or explore the broader ThriveCoin Community, please get in touch: social@thrivecoin.com.",
  },
];

const Divider = styled(Box)`
  height: 1px;
  margin: 2rem 0;
  background: ${({ theme }) => theme.colors["01Primary200"]};
`;

export default AboutSelectionStep;

import { Box, breakpoints, Input, Text } from "@thrivecoin/ui-components";
import getFormData from "@utils/getFormData";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import { PaddedButton } from "../common";
import GoogleButton from "../GoogleButton";

const initialState = { email: "", password: "" };

const SignInStep = ({ goToStep, closeModal }) => {
  const [state, setState] = useState(initialState);
  const [validationText, setValidationText] = useState(false);
  const { isMobile } = useStoreState((state) => state.media);
  const { logInRequest } = useStoreActions((actions) => actions.authentication);
  const goToForgotPasswordStep = () => goToStep(STEP_INDEXES.ForgotPasswordStep);
  const disabled = !state.email || !state.password;

  const onSubmit = (event) => {
    event.preventDefault();
    const data = getFormData(event.currentTarget);

    logInRequest(data)
      .then((user) => {
        if (!user.confirmed) {
          return goToStep(STEP_INDEXES.VerificationStep);
        }
        closeModal();
      })
      .catch(() => {
        setValidationText("Sorry, the sign in details you entered are incorrect.  ");
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setValidationText("");
    setState({ ...state, [name]: value });
  };

  useVirtualPageView({ pathname: "/sign-in" });

  return (
    <Page
      onSubmit={onSubmit}
      onChange={onChange}
      validationText={validationText}
      {...state}
      goToForgotPasswordStep={goToForgotPasswordStep}
      disabled={disabled}
      isMobile={isMobile}
    />
  );
};

const Page = ({ email, password, onSubmit, onChange, validationText, goToForgotPasswordStep, disabled, isMobile }) => (
  <PageContainer>
    <StyledHeader>Sign in to your account</StyledHeader>
    <form onSubmit={onSubmit} onChange={onChange}>
      <div>
        <StyledInput
          spacing={isMobile ? "mb-4" : "mb-6"}
          invalid={validationText}
          name="email"
          placeholder="Email"
          value={email}
          required
        />
      </div>
      <Container>
        <StyledInput
          spacing={isMobile ? "mb-6" : "mb-5"}
          name="password"
          placeholder="Password"
          type="password"
          value={password}
          invalid={validationText}
          validationText={validationText}
          required
        />
      </Container>

      <div>
        <PaddedButton type="submit" spacing="mb-4" disabled={disabled} modal={true}>
          SIGN IN
        </PaddedButton>
      </div>
    </form>
    <div>
      <GoogleButton spacing="mb-6" />
    </div>
    <Text>
      Forgot Password?{"  "}
      <Text inline color="primary500" semiBold onClick={goToForgotPasswordStep}>
        Click here
      </Text>
    </Text>
  </PageContainer>
);

const Footer = ({ goToStep }) => {
  const onClick = () => goToStep(STEP_INDEXES.CreateAccountStep);
  return (
    <BorderedBox align="center" spacing="p-6" bgColor="tertiary50">
      No account yet?{" "}
      <Text inline color="primary500" semiBold onClick={onClick}>
        Sign Up
      </Text>
    </BorderedBox>
  );
};

const PageContainer = styled(Box)`
  @media ${breakpoints.tablet} {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 426px;
    max-width: 426px;
  }

  @media ${breakpoints.mobile} {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 66px 1rem 0;
  }
`;

const Container = styled.div`
  text-align: left;
  font-size: 14px;
`;

const StyledInput = styled(Input)`
  input {
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    font-size: 16px;
    line-height: 18px;
    color: #1f2029;
    border-color: ${({ theme }) => theme.colors.secondary100};
    border-radius: 8px;
    padding: 19px 17px;

    &::placeholder {
      color: #8083a8;
      font-size: 16px;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  }
`;

const StyledHeader = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #1f2029;
  margin-bottom: 40px;

  @media ${breakpoints.mobile} {
    text-align: left;
    margin-bottom: 2rem;
  }
`;

const BorderedBox = styled(Box)`
  border-top: 1px solid #e4e4ed;
`;

SignInStep.Footer = Footer;

export default SignInStep;

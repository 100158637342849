import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { formatDate } from "../../utils/formatting";
import { LinkThankYou } from "../CommunityPage/Challenges/common";

const HeaderElement = ({ text, firstElement }) => {
  return (
    <HeaderItem>
      <Text bold textAlign={firstElement && "left"}>
        {text}
      </Text>
    </HeaderItem>
  );
};

const DataRow = ({ contribution, community, amount, date, receipt_link, processing, onCommunityClick }) => {
  return (
    <tr>
      <DataElement>
        <Text>{contribution}</Text>
      </DataElement>
      <DataElement>
        {!!community && (
          <Text
            pointer
            bold
            underline
            data-id={community.id}
            onClick={onCommunityClick}
            textAlign="center"
            color="#4F99B9"
          >
            {community?.name}
          </Text>
        )}
      </DataElement>
      <DataElement>
        <Box flex alignItems="center" justifyContent="center" gap="6px">
          <IconSvg icon="logo-gold" width="17px" />
          <Text extraBold color="accent550">
            {+amount} $THRIVE
          </Text>
        </Box>
      </DataElement>
      <DataElement>
        <Text textAlign="center">{formatDate(date)}</Text>
      </DataElement>
      <DataElement>
        {!!receipt_link && (
          <LinkThankYou target="blank" href={receipt_link}>
            View Thank You Note
          </LinkThankYou>
        )}
        {!!processing && (
          <Text typography="body/medium" textAlign="center" color="02Primary700">
            Receipt processing
          </Text>
        )}
      </DataElement>
    </tr>
  );
};

const TableOfContributions = ({ contributions, onCommunityClick }) => {
  return (
    <Table>
      <tr>
        <HeaderElement text="CONTRIBUTION" firstElement />
        <HeaderElement text="COMMUNITY" />
        <HeaderElement text="REWARD" />
        <HeaderElement text="REWARD DATE" />
        <HeaderElement text="REWARD RECEIPT" />
      </tr>
      <Line bgColor="tertiary300" />
      <TBody>
        {contributions.map((item) => (
          <DataRow key={item.id} {...item} onCommunityClick={onCommunityClick} />
        ))}
      </TBody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  margin-top: 46px;
  position: relative;
  white-space: nowrap;
  overflow-x: scroll;
`;

const HeaderItem = styled.th`
  padding: 24px 16px;
`;

const Line = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
`;

const TBody = styled.tbody`
  width: 100%;
  & > tr:nth-of-type(even) {
    background-color: #fafafa;
  }
`;

const DataElement = styled.td`
  padding: 26px 16px;
  ${({ pointer }) => pointer && `cursor: pointer;`}
`;

export default TableOfContributions;

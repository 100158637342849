import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";
const requester = new HttpWrapper("contribution_apps");

const attributes = {
  countByApp: {},
};

const actions = {
  getActiveListingsCountRequest: thunk(({ setCountyApp }) => {
    return requester.get("active_listings_count").then(({ contribution_apps }) => {
      const data = contribution_apps.map(Object.values).reduce((accumulator, values) => {
        const [key, value] = values;
        accumulator[key] = value;
        return accumulator;
      }, {});
      setCountyApp(data);
    });
  }),
  setCountyApp: action((state, data) => {
    state.countByApp = data;
  }),
};

const activities = {
  ...attributes,
  ...actions,
};

export default activities;

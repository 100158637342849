// @ts-ignore
import ConnectionConfigs from "@utils/ChainConnections";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import BaseConnector from "./BaseConnector";

const chainIDs = Object.keys(ConnectionConfigs)
  .map((key) => parseInt(ConnectionConfigs[key].chainId))
  .filter((val) => val != 1);

const methods = [
  "eth_accounts",
  "personal_sign",
  "eth_chainId",
  "eth_requestAccounts",
  "eth_sendTransaction",
  "wallet_addEthereumChain",
  "wallet_switchEthereumChain",
  "wallet_watchAsset",
];

const events = ["chainChanged", "accountsChanged", "connect", "session_event", "display_uri", "disconnect"];

// source = https://walletconnect.com/explorer?
const explorerRecommendedWalletIds = [
  // "wallet",
  "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // Metamask
  "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927", // "ledger",
  // "tokenary", not found
  "802a2041afdaf4c7e41a2903e98df333c8835897532699ad370f829390c6900f", // "infinity",
  "34c19e0afafeb86ffa75df1c04445b8840450217e79d30abc6def9aa537fb7d6", // "wallet 3",
  "6464873279d46030c0b6b005b33da6be5ed57a752be3ef1f857dc10eaf8028aa", // "secuX",
  "2c81da3add65899baeac53758a07e652eea46dbb5195b8074772c62a77bbf568", // "ambire",
  "5864e2ced7c293ed18ac35e0db085c09ed567d67346ccb6f58a0327a75137489", // "fireblocks",
  "c587c2601ccfc456cb7d4d9bb34f12f0fd11ad49faeeb4602860e239b5397843", // "vision",
  // "nft",
  "21b071705a9b9de1646e6a3a0e894d807d0fa4a208e88fc003ee056021f208e1", // "apolloX",
  "c40b9bcef32fa6ce4e0df98be1420628bbc4957646f742380fe618fcb4ab74f1", // "riceWallet",
  "19418ecfd44963883e4d6abca1adeb2036f3b5ffb9bee0ec61f267a9641f878b", // "kryptoGO",
  "1f711d32b1df0f84741fafa2ad1d81599b01297cc7d22d153272cb3ef4232f19", // "sequence",
  "4d0cf1b635a59175b4ad6d6522b0b748ee920b1f8c32030fa704c00926efdf3e", // "paper",
  // "punkWallet",
  "ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18", // "zerion",
  "47bb07617af518642f3413a201ec5859faa63acb1dd175ca95085d35d38afb83", // "keyring",
  "87eecbca66faef32f044fc7c66090fc668efb02e2d17dda7bf095d51dff76659", // "crossmint",
  "38c16286d8c924bb05f01056a8862357e80fbdbad7570dc40aed95a57c3e4de1", // "ShapeShift",
];

const OPTIONS = {
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID, // required
  chains: ["1"], // required
  optionalChains: chainIDs,
  methods,
  events,
  showQrModal: true, // requires @walletconnect/modal
  qrModalOptions: {
    explorerRecommendedWalletIds,
  },
};

export const doWalletConnect = async () => {
  try {
    const provider = await EthereumProvider.init(OPTIONS);

    await provider.enable();
  } catch (e) {
    console.error(e);
    return;
  }
};

export default class WalletConnect extends BaseConnector {
  async connect() {
    let provider;
    try {
      provider = await EthereumProvider.init(OPTIONS);
      await provider.enable();
    } catch (e) {
      console.error(e);
      return;
    }
    provider.connectorName = "walletconnect";
    return provider;
  }

  logout() {
    if (localStorage) {
      localStorage.removeItem("walletconnect");
    }
    return;
  }
}

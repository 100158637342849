import NoRewardImage from "@assets/no-challenges.svg";
import { Box, Button, Text } from "@thrivecoin/ui-components";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const NoRewards = () => {
  const navigate = useNavigate();

  const onExplore = () => {
    navigate("/communities");
  };

  return (
    <Wrapper flex direction="column" alignItems="center" bgColor="tertiary50" borderRadius="12px" color="02Primary700">
      <Text typography="Heading/Medium" spacing="mb-4">
        You have not yet completed any contributions
      </Text>
      <Text spacing="mb-6">It’s time to contribute, support your Community, and earn some $THRIVE!</Text>
      <Button
        size="lg"
        bold
        kind="outline"
        borderColor="success500"
        rightIcon="right-long"
        iconWidth="20px"
        color="02Primary700"
        borderRadius="40px"
        onClick={onExplore}
      >
        Explore ThriveCoin Community contribution opportunities
      </Button>
      <Image src={NoRewardImage} />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  margin-top: 35px;
  padding-top: 60px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary100};
`;

const Image = styled.img`
  width: 190px;
  height: 141px;
  margin-top: 54px;
  margin-bottom: 59px;
`;

export default NoRewards;

import { Box } from "@thrivecoin/ui-components";
import styled from "styled-components";

const CheckBox = ({ spacing, ...props }) => {
  return (
    <Box spacing={spacing}>
      <StyledLabel>
        <input type="checkbox" {...props} />
        <span />
      </StyledLabel>
    </Box>
  );
};

const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 20px;
  margin-top: 2px;
  cursor: pointer;
  user-select: none;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & > span {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    background-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};

    :after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  & input:checked ~ span {
    background-color: ${({ theme }) => theme.colors["03Primary500"]};

    :after {
      display: block;
    }
  }

  & span:after {
    left: 8px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export default CheckBox;

import { computed } from "easy-peasy";
import { denormalizeEntity } from "../normalizerSchema";

export const showResolver = (state, storeState) => ({
  id: state.showID,
  entities: storeState.entities,
});

export const showComputed = (modelName, initialValue = {}) =>
  computed([showResolver], ({ id, entities }) => {
    return denormalizeEntity(id, entities, modelName) || initialValue;
  });

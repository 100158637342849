import { breakpoints, Modal } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import useSocialProfilesInfo from "../../hooks/useSocialProfilesInfo";
import ConnectDiscourse from "./ConnectDiscourse";
import ConnectStep from "./ConnectStep";

const STEP_CLASSES = [ConnectStep, ConnectDiscourse];

const ConnectSocialMediasModal = ({ hideModal, initialStep = 0 }) => {
  const props = useSocialProfilesInfo();
  const [step, setStep] = useState(initialStep);
  const Klass = STEP_CLASSES[step];
  const goToStep = (index) => setStep(index);
  const { isMobile } = useStoreState((state) => state.media);
  const borderRadius = isMobile ? "0 !important" : false;
  const paddingBody = isMobile ? "40px 16px" : "32px 40px 40px";

  return (
    <StyledModal
      open
      paddingBody={paddingBody}
      onClose={hideModal}
      fullScreen={isMobile}
      borderRadius={borderRadius}
      maxWidth="764px"
    >
      <Klass {...props} isMobile={isMobile} goToStep={goToStep} />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  ${Modal.Body} {
    max-height: 90%;
    @media ${breakpoints.mobile} {
      max-height: 100%;
    }
    & > div:first-child {
      height: 100%;
      overflow: auto;
    }
  }
`;

export default ConnectSocialMediasModal;

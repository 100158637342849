const projectKey = process.env.REACT_APP_USER_SNAP_KEY;
export const inject = () => {
  if (!projectKey) {
    return;
  }

  window.onUsersnapCXLoad = function (api) {
    api.init();
  };

  var script = document.createElement("script");
  script.defer = 1;
  script.src = `https://widget.usersnap.com/global/load/${projectKey}?onload=onUsersnapCXLoad`;
  document.getElementsByTagName("head")[0].appendChild(script);
};

import { useEffect, useState } from "react";
import HttpWrapper from "../../../../redux/httpRequester";
const requester = new HttpWrapper("listings");

const useListingTemplates = () => {
  const [templates, setState] = useState([]);

  useEffect(() => {
    requester.get("/templates").then(({ templates }) => setState(templates));
  }, []);

  return { templates };
};

export default useListingTemplates;

import { Box, breakpoints, Button, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useColors from "../../useColors";

const Donations = ({ earnings_status, donated_to, donation_options, rewards_token, ...season }) => {
  const { id } = useParams();
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { donateEarnings } = useStoreActions((actions) => actions.season);
  const donatedTo = donation_options.find((org) => org.id === donated_to);

  const { theme } = useColors(season);

  const openDonateModal = () => {
    showModal({
      modalName: "DonateModal",
      donated_to,
      donation_options,
      earnings_status,
      rewards_token,
      donateEarnings,
      seasonId: id,
    });
  };

  let content = (
    <Box flex direction="column" gap="1rem">
      <Button
        color="black"
        size="lg"
        bold
        height="56px"
        borderRadius="40px"
        borderColor="02Primary0"
        hoverBackground="02Primary0"
        onClick={openDonateModal}
        variant="02Primary0"
      >
        Donate My Earnings
      </Button>
      <Box flex gap="12px">
        <Box minWidth="14px">
          <IconSvg icon="alert-triangle" width="14px" height="14px" color="headerText" />
        </Box>
        <Text typography="body/small" bold spacing="mr-2" color="headerText">
          If you prefer not to donate and claim your earnings yourself, you will need to return to this page shortly
          after the end of the season.
        </Text>
      </Box>
    </Box>
  );

  if (earnings_status === "pending_donation") {
    content = (
      <Box flex direction="column">
        <Box flex gap="10px" alignItems="flex-start" spacing="mb-4">
          <Box minWidth="14px">
            <IconSvg icon="alert-triangle" width="14px" height="14px" color="headerText" />
          </Box>
          <Text bold color="headerText">
            Your season earnings will be automatically donated to <OrgName>{donatedTo.name}</OrgName> at the end of the
            season.
          </Text>
        </Box>
        <Button
          size="lg"
          bold
          kind="outline"
          height="56px"
          borderRadius="40px"
          onClick={openDonateModal}
          variant="02Primary0"
        >
          Edit Donation Selection
        </Button>
      </Box>
    );
  }

  return <Wrapper bgColor="headerBackground">{content}</Wrapper>;
};

const Wrapper = styled(Box)`
  display: flex;
  padding: 30px 23px;
  border-radius: 12px 12px 0 0;

  @media ${breakpoints.mobile} {
    padding: 18px 18px 24px;
  }
`;

const OrgName = styled.span`
  color: ${({ theme }) => theme.colors["02Primary0"]};
`;

Donations.defaultProps = {
  donation_options: [],
};

export default Donations;

import PeopleCelebratingImage from "@assets/raffle/people-celebrating.svg";
import SparkleLeftImg from "@assets/raffle/sparkle-left.svg";
import SparkleRightImg from "@assets/raffle/sparkle-right.svg";
import { Box, breakpoints, Button, Countdown, IconSvg, Text } from "@thrivecoin/ui-components";
import numToWord from "@utils/numToWord";
import { useStoreState } from "easy-peasy";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "../../../utils/formatting";
import Title from "./Title";
import { MainContainer } from "@common/MainContainer";

const PendingSection = ({ isMobile, raffle, isDesktop }) => {
  return (
    <>
      <Text bold={!isMobile} extraBold={isMobile} typography="body/medium" spacing={isMobile ? "mb-4" : "mb-6"}>
        {raffle.community_name} will be raffling off {raffle.prizes_count && numToWord(raffle.prizes_count)} exclusive
        prizes to contributors to the DAO this season.
      </Text>
      {(isDesktop || isMobile) && (
        <Box
          flex
          direction={isMobile && "column"}
          gap="17px"
          alignItems={isMobile ? "baseline" : "center"}
          spacing={"mb-6"}
        >
          <Text bold typography="body/small">
            Countdown to Raffle:
          </Text>
          <Countdown countdown={raffle.draw_date} />
        </Box>
      )}
      {isDesktop && (
        <Text typography="body/medium">
          Prizes will be drawn during a live event on {formatDate(raffle.draw_date)}, and winners will be announced on
          this page.{" "}
        </Text>
      )}
    </>
  );
};

const LiveSection = ({ watchRaffle, raffle }) => {
  return (
    <BoxLive alignItems="center" justifyContent="center" direction="column">
      <StyledButton
        bold
        size="lg"
        grow={1}
        height="56px"
        borderRadius="50px"
        rightIcon="arrow-up-right"
        onClick={watchRaffle}
      >
        Watch the Raffle LIVE
      </StyledButton>

      <Text typography="body/small" spacing="mt-3" color="02Primary500">
        Raffle started {moment(raffle.draw_date).fromNow()}
      </Text>
    </BoxLive>
  );
};

const CompletedSection = ({ isMobile, raffle, scrollDown }) => {
  return (
    <>
      <Box gap="6px" justifyContent={isMobile && "center"} flex>
        <Text typography="body/medium" color="02Primary500">
          Raffle Date:
        </Text>
        <Text typography="body/medium" color="02Primary500" bold>
          {formatDate(raffle.draw_date)}
        </Text>
      </Box>
      {isMobile && (
        <Button
          size="lg"
          bold
          color="01Primary0"
          borderRadius="50px"
          height="56px"
          rightIcon="down-long"
          iconWidth="10px"
          width="327px"
          spacing="mx-a mt-5"
          onClick={scrollDown}
        >
          Check The Results
        </Button>
      )}
    </>
  );
};

const TopSection = ({ raffle, scrollDown }) => {
  const navigate = useNavigate();
  const { isMobile, isDesktop } = useStoreState((state) => state.media);
  const isPending = raffle.status === "pending";
  const isLive = raffle.status === "live";
  const isCompleted = raffle.status === "completed";

  const onBack = () => navigate(`/seasons/${raffle.season_slug}`);

  const watchRaffle = () => (window.location.href = raffle.external_url);

  return (
    <Wrapper minHeight={isPending ? "366px" : "382px"} flex direction="column">
       <MainContainer>
      <Container relative>
        {isCompleted && <LeftSparkle src={SparkleLeftImg} alt="sparkle" />}
        <LeftSide color="02Primary500">
          <Box pointer flex alignItems="center" gap="8px" spacing="mb-6+2" sm-spacing="mb-5" onClick={onBack}>
            <IconSvg icon="left-long" size="14px" />
            <Text size="15px" lHeight="20px" bold>
              Back to Season Page
            </Text>
          </Box>

          <Title raffle={raffle} />

          {isPending && <PendingSection raffle={raffle} isDesktop={isDesktop} isMobile={isMobile} />}

          {isLive && <LiveSection raffle={raffle} watchRaffle={watchRaffle} />}

          {isCompleted && <CompletedSection raffle={raffle} isMobile={isMobile} scrollDown={scrollDown} />}
        </LeftSide>
        {!isCompleted && raffle.image_url && <ImageStyled src={raffle.image_url} alt="people" />}
        {isCompleted && <ImageStyledPeople src={PeopleCelebratingImage} alt="people" />}
        {isCompleted && <RightSparkle src={SparkleRightImg} alt="sparkle" />}
      </Container>
       </MainContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  background: linear-gradient(200.3deg, #e0fbe7 -5.83%, #bbd0c0 142.44%);
  padding: 33px 0 0 0;
  position: relative;
  min-height: ${({ minHeight }) => minHeight && minHeight};
  margin: 0px auto;
  padding: 0 1rem;
  overflow: hidden;

  @media ${breakpoints.tablet} {
    padding: 2rem 0 29px 0;
  }

  @media ${breakpoints.mobile} {
    padding: 2rem 1rem;
    min-height: unset;
  }
`;

const Container = styled(Box)`
  gap: 25px;
  @media (max-width: 1003px) {
    grid-template-columns: auto;
  }

  @media ${breakpoints.tablet} {
    padding: 0 32px;
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

const ImageStyled = styled.img`
  position: absolute;
  right: 74px;
  top: 44px;
  max-width: 398px;
  z-index: 9;

  @media ${breakpoints.tablet} {
    right: -26px;
  }

  @media ${breakpoints.mobile} {
    display: none;
  }
`;

const ImageStyledPeople = styled.img`
  position: absolute;
  right: 74px;
  bottom: -115px;
  z-index: 9;

  @media ${breakpoints.tablet} {
    right: -26px;
    bottom: -193px;
  }

  @media ${breakpoints.mobile} {
    position: relative;
    right: unset;
    bottom: unset;
  }
`;

const LeftSide = styled(Box)`
  @media ${breakpoints.tablet} {
    max-width: 600px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 16px;
  line-height: 26px;
  border: 1px solid #81c394;
  min-width: 295px;
  &:hover {
    background-color: ${({ theme }) => theme.colors["03Primary300"]};
  }

  @media ${breakpoints.mobile} {
    border-radius: 50px;
  }
`;

const BoxLive = styled(Box)`
  display: inline-flex;
`;

const LeftSparkle = styled.img`
  position: absolute;
  bottom: 72px;
  left: -156px;

  @media ${breakpoints.tablet} {
    right: unset;
    left: 490px;
    bottom: -98px;
  }
`;

const RightSparkle = styled.img`
  position: absolute;
  bottom: 130.75px;
  right: -120px;

  @media ${breakpoints.tablet} {
    right: unset;
    left: 307px;
    top: 0;
    bottom: unset;
  }
`;

export default TopSection;

import { Box, Loader, Text } from "@thrivecoin/ui-components";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const options = {
  threshold: 0.1, // Trigger when 10% of the element is visible
};

const LoadMore = ({ pagination, loadMore, extraText, marginTop = "73px" }) => {
  const contentRef = useRef(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const unloaded = pagination.total - pagination.page * pagination.per_page;
  const currentPage = +pagination.page;
  const nextPage = useRef(currentPage + 1);

  const onLoadMore = () => {
    setLoadingMore(true);
    loadMore(nextPage.current).finally(() => setLoadingMore(false));
  };

  useEffect(() => {
    nextPage.current = currentPage + 1;
  }, [pagination.page]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !loadingMore) {
          onLoadMore();
        }
      });
    }, options);

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, [loadingMore, onLoadMore]);

  if (currentPage * pagination.per_page >= pagination.total) {
    return "";
  }

  return (
    <Box ref={contentRef} width="100%" spacing="mt-6" flex alignItems="center" justifyContent="center">
      {loadingMore ? (
        <Loader />
      ) : (
        <ClickToLoad unloaded={unloaded} marginTop={marginTop} onLoadMore={onLoadMore} extraText={extraText} />
      )}
    </Box>
  );
};

const ClickToLoad = ({ unloaded, marginTop, onLoadMore, extraText }) => {
  if (unloaded == 0) return "";

  return (
    <Content
      marginTop={marginTop}
      onClick={onLoadMore}
      pointer
      typography="heading/small"
      underline
      color="01Primary700"
    >
      Load more {extraText} ({unloaded})
    </Content>
  );
};

const Content = styled(Text)`
  margin-top: ${({ marginTop }) => marginTop};
  font-weight: 500;
  text-align: center;
`;

export default LoadMore;

import { Box, breakpoints, Button, DropDown, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

import DISCORD from "@assets/discord-circle.svg";
import DISCOURSE from "@assets/discourse-circle.svg";
import SNAPSHOT from "@assets/snapshot-circle.svg";
import TWITTER from "@assets/twitter-circle.svg";

const OPTIONS = [
  { value: "twitter", label: "Twitter", image: TWITTER },
  { value: "discord", label: "Discord", image: DISCORD },
  { value: "discourse", label: "Discourse", image: DISCOURSE },
  { value: "snapshot", label: "Snapshot", image: SNAPSHOT },
];

const SocialLinks = ({ socialsArray, setSocialsArray }) => {
  const selectedSocials = socialsArray.map((item) => item.id);
  const availableOptions = OPTIONS.filter(({ value }) => !selectedSocials.includes(value));
  const socialsToDisplay = socialsArray.filter(({ id }) => id !== "website");

  const possibleOptions = (id) => {
    const selected = OPTIONS.find(({ value }) => value == id);
    return selected ? [selected, ...availableOptions] : availableOptions;
  };

  const onSelectChange = (val, index) => {
    const updatedSocials = [...socialsArray];
    const prevSelected = updatedSocials.findIndex((item) => item.id === val.value);
    if (prevSelected > -1) {
      updatedSocials[prevSelected] = { ...updatedSocials[prevSelected], id: "empty" };
    }
    updatedSocials[index] = { ...updatedSocials[index], id: val.value };
    setSocialsArray(updatedSocials);
  };

  const onInputChange = (e) => {
    const { value, dataset } = e.target;
    const updatedSocials = [...socialsArray];
    updatedSocials[dataset.id].value = value;
    setSocialsArray(updatedSocials);
  };

  const addSocial = () => {
    const newSocials = [...socialsArray, { id: "", value: "" }];
    setSocialsArray(newSocials);
  };

  const removeSocial = (e) => {
    const index = +e.target.dataset.id;
    const updatedSocials = [...socialsArray];
    updatedSocials.splice(index, 1);
    setSocialsArray(updatedSocials);
  };

  return (
    <Box flex direction="column">
      {socialsToDisplay.map((item, index) => (
        <Wrapper key={(item.id || index) + availableOptions.length}>
          <Text bold spacing="mb-3">
            Social Media Links (Optional)
          </Text>
          <Box flex gap="1rem">
            <DropDown
              bgColor="01Primary100"
              value={item?.id}
              valueKey="value"
              autocomplete={false}
              label="label"
              options={possibleOptions(item.id)}
              image="image"
              placeholder="Select"
              showImage
              onChange={(val) => onSelectChange(val, index)}
            />
            <Input value={item.value} placeholder="Social Media Link" data-id={index} onChange={onInputChange} />
          </Box>
          <RemoveBox data-id={index} onClick={removeSocial}>
            <IconSvg icon="x" width="16px" />
          </RemoveBox>
        </Wrapper>
      ))}
      {socialsArray.length < 4 && (
        <Button rightIcon="plus" onClick={addSocial} kind="outline" borderRadius="40px" sm-spacing="mx-4">
          Add Social Profile(s)
        </Button>
      )}
    </Box>
  );
};

const Wrapper = styled(Box)`
  position: relative;
  padding: 1rem 1rem;
  margin: 0 -1rem 1.5rem;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary200"]};

  @media ${breakpoints.mobile} {
    margin: 0 1rem 1.5rem;
  }
`;

const RemoveBox = styled(Box)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  & > * {
    pointer-events: none;
  }
`;

export default SocialLinks;

import ReactGA from "react-ga4";

const trackEvent = ({ category, action, label, value }) =>
  ReactGA.event({
    category,
    action,
    label,
    value,
  });

export const trackEventCategory = (category) => {
  trackEvent({
    category,
    action: "action",
  });
};

export const trackShare = (label) => {
  ReactGA.event({
    category: "share",
    action: "click",
    label,
  });
};

export const cardExpanded = (opened, name) => {
  ReactGA.event({
    category: "challenge",
    action: opened ? "open" : "close",
    label: name,
  });
};

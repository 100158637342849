import { Box, breakpoints, Input, Text } from "@thrivecoin/ui-components";
import getFormData from "@utils/getFormData";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import useWeb3 from "../../hooks/useWeb3";
import { PaddedButton } from "../common";

const initialState = {
  email: "",
  password: "",
  validationText: "",
};

const PASSWORDERROR =
  "Your password must contain at least 8 characters, including at least one uppercase and lowercase letter, and at least one number.";

const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

export const arePasswordsInvalid = (password, password_confirmation) => {
  if (password !== password_confirmation) {
    return "Please make sure your passwords match.";
  }

  if (!passwordRegex.test(password)) {
    return PASSWORDERROR;
  }
};

const SignInStep = ({ goToStep, closeModal }) => {
  const { connect, hasWeb3Provider } = useWeb3();
  const { address } = useStoreState((state) => state.user.user);
  const [state, setState] = useState({ ...initialState, email: address });
  const { password, email } = state;
  const invalid = !state.password || !passwordRegex.test(state.password) || !state.email;
  const [validationText, setValidationText] = useState(false);
  const { logInRequest } = useStoreActions((actions) => actions.authentication);

  const onSubmit = (event) => {
    event.preventDefault();
    const data = getFormData(event.currentTarget);

    logInRequest(data)
      .then((user) => {
        if (!user.confirmed) {
          return goToStep(STEP_INDEXES.VerificationStep);
        }
        closeModal();
      })
      .catch(({ response }) => {
        setValidationText(response.data.message);
      });
  };

  const onChange = (event) => {
    const { id, value } = event.target;
    setValidationText("");
    setState({ ...state, [id]: value });
  };

  useEffect(() => {
    setState({ ...state, email: address });
  }, [address]);

  useEffect(() => {
    if (hasWeb3Provider) {
      connect()
        .then((addresses) => console.log(addresses))
        .catch((e) => console.log(e));
    }
  }, []);

  useVirtualPageView({ pathname: "/sign-in" });

  return (
    <Container>
      <Text typography="Heading/Large" spacing="mb-6">
        Sign in to your account
      </Text>
      <form onSubmit={onSubmit} onChange={onChange}>
        <Box spacing="mb-5" md-spacing="mb-5" sm-spacing="mb-4">
          <StyledInput
            id="email"
            required
            name="email"
            value={email}
            placeholder="Your wallet address"
            validationAbsolute
          />
        </Box>
        <Box spacing="mb-5" md-spacing="mb-5" sm-spacing="mb-6">
          <StyledInput
            id="password"
            name="password"
            placeholder="Your passcode"
            type="password"
            value={password}
            required
            invalid={!!validationText}
            validationText={validationText}
          />
        </Box>
        <div>
          <PaddedButton type="submit" typography="Body/Medium" disabled={invalid} modal={true}>
            Sign in
          </PaddedButton>
        </div>
      </form>
    </Container>
  );
};

const Container = styled(Box)`
  flex: 1;
  text-align: center;
  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    max-width: 426px;
  }
  @media ${breakpoints.mobile} {
    padding: 0 1rem;
    padding-top: 66px;
    text-align: left;
  }
`;

const BorderedBox = styled(Box)`
  border-top: 1px solid #e4e4ed;
`;

const StyledInput = styled(Input)`
  input {
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    font-size: 16px;
    line-height: 18px;
    color: #1f2029;
    border-color: ${({ theme }) => theme.colors.secondary100};
    border-radius: 8px;
    padding: 19px 17px;

    &::placeholder {
      color: #8083a8;
      font-size: 16px;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  }
`;

const Footer = ({ goToStep }) => {
  const onClick = () => goToStep(STEP_INDEXES.CreateAccountStep);
  return (
    <BorderedBox align="center" spacing="p-6" bgColor="tertiary50" color="02Primary700" bold>
      No secret phrase?{" "}
      <Text typography="Body/Medium" inline color="#88CFDE" onClick={onClick} bold>
        {" "}
        Get Started
      </Text>
    </BorderedBox>
  );
};

SignInStep.Footer = Footer;

export default SignInStep;

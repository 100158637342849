import { ethers } from "ethers";

// const address = "0x983110309620D911731Ac0932219af06091b6744"; // brantly.eth has an image

const getENSData = async (address) => {
  let image_url, username;

  try {
    const provider = new ethers.providers.Web3Provider(window.web3.currentProvider);
    const xaddress = ethers.utils.getAddress(address);
    username = await provider.lookupAddress(xaddress);
    image_url = await getImageUrl(provider, username);
  } catch (error) {
  }
  if (username) {
    return { username, image_url };
  }
};

const getImageUrl = async (provider, name) => {
  let imageUrl;
  try {
    const resolver = await provider.getResolver(name);
    const contentHash = resolver.getContentHash();
    if (contentHash) {
      const ensAddress = await provider.getEnsAddress(contentHash);
      const registry = await provider.getRegistry(ensAddress);
      imageUrl = await registry.fetchImage(name);
    }
  } catch (error) {}
  return imageUrl;
};

export default getENSData;

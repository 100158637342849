import image from "@assets/empty-result.svg";
import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const MaintenancePage = () => (
  <Container rows="1fr 20%">
    <Box flex alignItems="center" direction="column" spacing="px-5 mt-4">
      <IconSvg icon="thrivecoin-logo" width="244px" />
      <Title size="46px" bold fontFamily="outfit" maxWidth="621px" lineHeight="55.44px" align="center" spacing="mb-6">
        ThriveCoin is currently down for planned maintenance
      </Title>
      <Text color="secondary400" spacing="mb-6">
        We expect to be back soon. Thanks for your ptience!
      </Text>
      <img src={image} />
    </Box>
    <Box bgColor="#AADAE4" flex justifyContent="center" alignItems="end">
      <Text color="01Primary0" spacing="mb-4">
        Copyright 2022 - All rights reserved. ThriveCoin Inc.
      </Text>
    </Box>
  </Container>
);

const Title = styled(Text)`
  @media ${breakpoints.mobile} {
    font-size: 28px;
    line-height: 36px;
  }
`;

const Container = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr 20%;
  background-color: #fffef1;

  img {
    max-width: 500px;
  }

  ${IconSvg} {
    margin-bottom: 92px;
  }
`;

export default MaintenancePage;

import useSetUpWeb3Provider from "../utils/web3/useSetUpWeb3Provider";
import { CommunityProvider } from "./CommunityContext";
import useContinueModalFlow from "./hooks/useContinueModalFlow";
import useCustomDomEvents from "./hooks/useCustomDomEvents";
import useCustomMetrics from "./hooks/useCustomMetrics";
import useReferralCode from "./hooks/useReferralCode";
import useScrollToTop from "./hooks/useScrollToTop";
import useSignInParam from "./hooks/useSignInParam";
import useTracking from "./hooks/useTracking";
import useWebSockets from "./hooks/useWebSockets";
import Router from "./Router";

const ChallengeApp = () => {
  useTracking();
  useCustomMetrics();
  useScrollToTop();
  useWebSockets();
  useCustomDomEvents();
  useContinueModalFlow();
  useSetUpWeb3Provider();
  useSignInParam();
  useReferralCode();

  return (
    <CommunityProvider>
      <Router />
    </CommunityProvider>
  );
};

export default ChallengeApp;

import { MainContainer } from "@common/MainContainer";
import { Box, breakpoints, TabsContainer } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useMemo, useState } from "react";
import styled from "styled-components";
import QualifedContributors from "./QualifedContributors";
import RaffleEntries from "./RaffleEntries";
import RaffleFAQ from "./RaffleFAQ/RaffleFAQ";
import RafflePrizes from "./RafflePrizes";
import RaffleRules from "./RaffleRules";
const selectedTabIndex = (tab, headers) => {
  if (tab === "RafflePrizes") {
    if (headers.includes("RafflePrizes")) {
      return 1;
    }
    return 0;
  }
  return tab;
};

const RaffleFAQTab = () => {
  return (
    <MainContainer>
      <Container>
        <Wrapper>
          <RaffleFAQ />
        </Wrapper>
      </Container>
    </MainContainer>
  );
};

const RaffleRulesTab = () => {
  return (
    <MainContainer>
      <Container>
        <Wrapper>
          <RaffleRules />
        </Wrapper>
      </Container>
    </MainContainer>
  );
};

const BodyRaffle = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { confirmed } = useStoreState((state) => state.user.user);
  const { raffle } = useStoreState((state) => state.raffle);

  const isSeasonActive = raffle?.season_status === "active";
  const raffleCompleted = raffle?.status === "completed";

  const RaffleEntriesTab = () => <RaffleEntries raffleCompleted={raffleCompleted} />;

  const { tabClasses, headers } = useMemo(() => {
    let tabClasses = [RafflePrizes, RaffleFAQTab, RaffleRulesTab];

    let headers = ["Raffle Prizes", "Raffle FAQ", "Raffle Rules"];

    if (!isSeasonActive) {
      headers.push("Qualified Contributors");
      tabClasses.push(QualifedContributors);
      if (confirmed) {
        headers.splice(1, 0, { tab: "My Raffle Entries", icon: "raffle-entries" });
        tabClasses.splice(1, 0, RaffleEntriesTab);
      }
    }

    return { tabClasses, headers };
  }, [isSeasonActive, confirmed]);

  const onChangeTab = (index) => setCurrentTabIndex(index);
  const tabIndex = selectedTabIndex(currentTabIndex, headers);

  return (
    <WrapperTab>
      <TabsContainer
        className="tabsContainer"
        selectedIndex={tabIndex}
        tabClasses={tabClasses}
        headers={headers}
        onChangeIndex={onChangeTab}
      />
    </WrapperTab>
  );
};

const WrapperTab = styled.div`
  padding-left: 0;
  position: relative;

  & > div > div {
    background: ${({ theme }) => theme.colors.background};
    width: 100%;

    @media ${breakpoints.mobile} {
      background: ${({ theme }) => theme.colors["01Primary0"]};
    }

    & > div:first-child {
      margin: 0 auto;
      padding: 27px 0 28px 0;

      @media ${breakpoints.tablet} {
        padding: 27px 0 28px 32px;
      }
    }
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  padding-bottom: 63px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors["01Surface"]};

  @media (max-width: 1003px) {
    grid-template-columns: auto;
  }

  @media ${breakpoints.tablet} {
    background: ${({ theme }) => theme.colors["background"]};
    padding: 0;
  }

  @media ${breakpoints.mobile} {
    background: ${({ theme }) => theme.colors["background"]};
    display: flex;
    flex-direction: column;
    padding: 0 0 24px;
  }
`;

const Wrapper = styled(Box)`
  background: ${({ theme }) => theme.colors["01Surface"]};
  border-radius: 12px;
  padding: 60px 74px 60px 59px;
  @media ${breakpoints.tablet} {
    padding: 0 32px;
    background: ${({ theme }) => theme.colors["background"]};
  }

  @media ${breakpoints.mobile} {
    background: ${({ theme }) => theme.colors["background"]};
    padding: ${({ padding }) => (padding ? padding : "16px")};
  }
`;

export default BodyRaffle;

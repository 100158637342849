import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { formatDate } from "../../utils/formatting";

const Contribution = ({ index, contribution, date, community, amount, receipt_link, processing, onCommunityClick }) => {
  return (
    <Wrapper
      flex
      justifyContent="space-between"
      borderTop={index === 0}
      spacing="px-4 py-4+1"
      bgColor={(index + 1) % 2 !== 0 && "#FAFAFA"}
      color="02Primary700"
      gap="11px"
    >
      <LeftPart>
        <Text>
          <b>Contribution: </b>
          {contribution}
        </Text>
        <Text spacing="my-6">
          <b>Community: </b>
          {!!community && (
            <Link data-id={community?.id} onClick={onCommunityClick}>
              {community?.name}
            </Link>
          )}
        </Text>
        <Box flex alignItems="center">
          <Text extraBold>Reward: </Text>
          <StyledIcon icon="logo-gold-circle" width="16px" />
          <Text extraBold color="accent550">
            {+amount} $THRIVE
          </Text>
        </Box>
      </LeftPart>
      <Box width="120px" flex direction="column" alignItems="flex-end" justifyContent="space-between">
        <Text>{formatDate(date)}</Text>
        {!!receipt_link && (
          <a target="blank" href={receipt_link}>
            <Text underline bold pointer textAlign="right" color="#4F99B9">
              View Thank You Note
            </Text>
          </a>
        )}
        {!!processing && (
          <Text typography="body/medium" textAlign="right" color="02Primary700">
            Receipt processing
          </Text>
        )}
      </Box>
    </Wrapper>
  );
};

const MobileContributions = ({ contributions, onCommunityClick }) => {
  return contributions.map((item, index) => (
    <Contribution key={item.id} index={index} onCommunityClick={onCommunityClick} {...item} />
  ));
};

const Wrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  ${({ borderTop, theme }) => borderTop && `border-top: 1px solid ${theme.colors["01Primary200"]};`}
`;

const LeftPart = styled(Box)`
  flex: 1;
`;

const Link = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #4f99b9;
  font-weight: 600;
`;

const StyledIcon = styled(IconSvg)`
  margin-left: 6px;
  margin-right: 4px;
`;

export default MobileContributions;

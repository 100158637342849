import InjectedConnector from "./Injected";

export default class MetamaskConnector extends InjectedConnector {
  getProvider() {
    return getMetamaskProvider();
  }

  async connect() {
    let provider = this.getProvider();
    if (provider) {
      try {
        await provider.request({ method: "eth_requestAccounts" });
      } catch (e) {
        console.error(e);
        if (e.code === 4001) return;
      }
    }
    return provider;
  }

  async isLoggedIn() {
    const provider = this.getProvider();
    if (!provider) return false;
    if (provider.selectedAddress) return true;
    await new Promise((r) => setTimeout(r, 400));
    return !!provider.selectedAddress;
  }
}

export const getMetamaskProvider = () => {
  let provider = window.ethereum;
  if (provider?.providers?.length) {
    provider.providers.forEach((_provider) => {
      if (_provider.isMetaMask) provider = _provider;
    });
  }

  return provider;
};

import { Box, ProgressBar, Text } from "@thrivecoin/ui-components";
import { STEP_INDEXES } from ".";
import { trackEventCategory } from "../../hooks/useTrackEvents";
import useWeb3 from "../../hooks/useWeb3";
import { PaddedButton } from "../common";

const AddNetworkStep = ({ goToStep }) => {
  const goNext = () => goToStep(STEP_INDEXES.AddNetworkStep2);
  const { addNetwork, providerName } = useWeb3();
  const addPolygonNetwork = () => {
    trackEventCategory("UO_RF_Mumbai_Add");
    addNetwork().then(goNext);
  };

  return (
    <Box sm-spacing="mt-6 px-4">
      <Text typography="heading/large" spacing="mb-4" sm-spacing="mb-5" color="02Primary500">
        See your ThriveCoin rewards in {providerName}
      </Text>

      <Text typography="body/medium" spacing="mb-5" color="02Priamry500">
        ThriveCoin ($THRIVE) tokens are sent through a testnet node of Polygon Network called “Mumbai”.
      </Text>

      <PaddedButton
        size="lg"
        height="56px"
        rightIcon="plus"
        typography="Body/Medium"
        semiBold
        spacing="mb-5"
        onClick={addPolygonNetwork}
        modal={true}
      >
        Add Mumbai Network to MetaMask
      </PaddedButton>

      <Text spacing="mb-4" typography="body/medium" align="center" color="02Primary500">
        Please note: You may see RPC warnings when adding the network because we are using a custom configuration to
        ensure high up-time. See our <a href="#">FAQs</a> for more info.
      </Text>
    </Box>
  );
};

const Footer = () => (
  <Box height="98px" bgColor="02Primary0" flex justifyContent="center">
    <Box flex justifyContent="center" alignItems="center" width="278px" gap="16px">
      <ProgressBar percent="50" bgColor="#81C394" />
      <Text semiBold color="02Primary500">
        1/2
      </Text>
    </Box>
  </Box>
);

AddNetworkStep.Footer = Footer;
AddNetworkStep.maxWidth = "684px";
AddNetworkStep.spacing = "px-7+2 pt-7+6 pb-6+2";

export default AddNetworkStep;

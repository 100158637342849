import styled from "styled-components";

import { Box, IconSvg, MarkdownText, Text } from "@thrivecoin/ui-components";

const Condition = ({ conditionKey, icon, condition, conditionDescription, link, iconWidth, gap, moveLeft }) => {
  const isPersonalCode = conditionKey === "embedding_personal_code";
  const textContent = isPersonalCode ? condition : `${condition}: ${conditionDescription?.toString()}`;

  return (
    <Box wrap flex alignItems="center" gap={gap} md-spacing="mr-a" >
      <IconSvg icon={icon} width={iconWidth} color="02Primary500" spacing={moveLeft} />
      <ConditionText bold typography="body/small" color="02Primary500">
        {link ? (
          <Link target="_blank" href={link}>
            {textContent}
          </Link>
        ) : (
          <MarkdownText linkTarget="_blank">{textContent}</MarkdownText>
        )}
      </ConditionText>
    </Box>
  );
};

const ConditionText = styled(Text)`
  flex: 1;
  display: flex;
  gap: 4px;
  & p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  & a {
    color: ${({ theme }) => theme.colors.link};
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors["01Secondary400"]};
`;

export const makeConditionElements = (conditions, completed) =>
  Object.keys(conditions)
    .filter((key) => CONFIG[key])
    .map((key) => (
      <Condition
        key={key}
        {...CONFIG[key]}
        conditionKey={key}
        completed={completed}
        conditionDescription={conditions[key]}
        link={conditions.repo_link || conditions.community_link}
      />
    ));

const CONFIG = {
  space: {
    icon: "atom",
    condition: "Space",
    gap: "14px",
    label: "Space",
  },
  discord_server: {
    icon: "discord",
    condition: "Server",
    iconWidth: "18px",
    gap: "10px",
  },
  repo: {
    icon: "github",
    condition: "Repo",
    iconWidth: "16px",
  },
  twitter_handle: {
    icon: "twitter",
    condition: "Twitter handle",
    iconWidth: "16px",
  },
  community: {
    icon: "discourse",
    condition: "Community",
    iconWidth: "14px",
    gap: "13px",
  },
  likes_count: {
    icon: "heart-filled",
    condition: "Number of likes",
    iconWidth: "18px",
    gap: "11px",
  },
  badge_name: {
    icon: "medal-filled",
    condition: "Badge name",
    iconWidth: "18px",
    gap: "11px",
  },
  badge_level: {
    icon: "medal-filled",
    condition: "Badge level",
    iconWidth: "18px",
    gap: "11px",
  },
  badge_category: {
    icon: "medal-2",
    condition: "Badge category",
    iconWidth: "18px",
    gap: "11px",
  },
  number_of_badges: {
    icon: "file-certificate",
    condition: "Number of badges",
    iconWidth: "18px",
    gap: "11px",
  },
  retweets_count: {
    icon: "twitter-square",
    condition: "Number of retweets",
    iconWidth: "14px",
    gap: "13px",
  },
  accepted_prs: {
    icon: "check-to-slot",
    condition: "Number of accepted PRs",
    iconWidth: "21px",
    gap: "9.5px",
  },
  role: {
    icon: "astronaut",
    condition: "Role",
    iconWidth: "16px",
  },
  channel: {
    icon: "square-right",
    condition: "Channel",
    iconWidth: "16px",
  },
  post_contains: {
    icon: "file-lines",
    condition: "Post contains",
    iconWidth: "14px",
    gap: "13px",
  },
  post_recency: {
    icon: "list-timeline",
    condition: "Post recency",
    iconWidth: "18px",
    gap: "11px",
  },
  replies_count: {
    icon: "reply",
    condition: "Number of replies",
    iconWidth: "18px",
    gap: "11px",
  },
  conditional_verification: {
    icon: "clipboard-check",
    condition: "Conditional Verification",
    iconWidth: "14px",
    gap: "13px",
  },
  final_verification: {
    icon: "badge-check",
    condition: "Final Verification",
    iconWidth: "18px",
    gap: "11px",
  },
  verification_speed: {
    icon: "clock",
    condition: "Verification Speed",
    iconWidth: "18px",
    gap: "8px",
  },
  embedding_personal_code: {
    icon: "key",
    condition: "Embedding personal code",
    iconWidth: "18px",
    gap: "8px",
  },
};

Condition.defaultProps = {
  iconWidth: "14px",
  gap: "12px",
};

export default Condition;

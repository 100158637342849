import { action, computed, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";
import {
  deNormalizeCollection,
  denormalizeContributionClaims,
  denormalizeContributions,
  normalizeContributionClaims,
  normalizeContributions,
} from "../normalizerSchema";

const requester = new HttpWrapper("activity_app/activities");
const profilesRequester = new HttpWrapper("profiles");
const usersRequester = new HttpWrapper("users");

const pendingResolver = (state, storeState) => ({ ids: state.pendingIDs, entities: storeState.entities });
const contributionsResolver = (state, storeState) => ({
  ids: state.contributionIDs,
  entities: storeState.entities,
  userContributionIDs: state.userContributionIDs,
});

const attributes = {
  userContributionIDs: {},
  pendingIDs: [],
  contributionIDs: [],
  contributionsPublicUser: [],
  pending: computed([pendingResolver], ({ ids, entities }) => {
    return denormalizeContributionClaims(ids, entities) || [];
  }),
  contributions: computed([contributionsResolver], ({ ids, entities }) => {
    return denormalizeContributions(ids, entities) || [];
  }),
};

const computedProps = {
  userContributions: computed([contributionsResolver], ({ userContributionIDs, entities }) => {
    return (userID) => {
      const ids = userContributionIDs[userID];
      const data = deNormalizeCollection(ids, entities, "contribution") || [];
      return data;
    };
  }),
};

const actions = {
  createContributionRequest: thunk((data, { id, user_id, amount }) => {
    const url = `${id}/create_contribution`;

    return requester.post(url, { contribution: { user_id, amount } });
  }),
  getContributionsRequest: thunk(({ normalizeContributions, setContributionIDs }, { id, keyword }) => {
    const url = `${id}/contributors`;

    return requester.post(url, { keyword }).then(({ contributors }) => {
      const ids = normalizeContributions(contributors);
      setContributionIDs(ids);
    });
  }),
  setContributionIDs: action((state, ids) => {
    state.contributionIDs = ids;
  }),
  rejectClaimRequest: thunk((data, { id, contribution_claim_id, rejection_reason, reviewer_comments }) => {
    const url = `${id}/reject_contribution_claim`;

    return requester.patch(url, { contribution_claim_id, rejection_reason, reviewer_comments });
  }),
  approveClaimRequest: thunk((data, { id, contribution_claim_id }) => {
    const url = `${id}/approve_contribution_claim`;

    return requester.patch(url, { contribution_claim_id });
  }),
  getPendingRequest: thunk(({ normalizeClaims, sendPendingIDs }, { id, keyword }) => {
    const url = `${id}/pending_claims`;

    return requester.post(url, { keyword }).then(({ claims }) => {
      const ids = normalizeClaims(claims);
      sendPendingIDs(ids);
    });
  }),
  sendPendingIDs: action((state, ids) => {
    state.pendingIDs = ids;
  }),
  normalizeClaims: thunk((actions, objects, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const result = normalizeContributionClaims(objects);

    setEntities(result);

    return result.result;
  }),
  normalizeContributions: thunk((actions, objects, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const result = normalizeContributions(objects);

    setEntities(result);

    return result.result;
  }),

  getPublicUserContributionsRequest: thunk(({ setUserContributionIDs }, { user_id }, { getStoreActions }) => {
    const { normalizeCollection } = getStoreActions().entities;
    const url = `/${user_id}/contributions`;

    return profilesRequester.get(url).then(({ contributions }) => {
      const ids = normalizeCollection({ data: contributions, entityName: "contribution" });
      setUserContributionIDs({ user_id, ids });
    });
  }),
  currentUserContributionsRequest: thunk(({ setUserContributionIDs }, { user_id,  keyword = "", sort_type = "date", sort_direction = "desc"  }, { getStoreActions }) => {
    const params = { sort_type, sort_direction };
    if (keyword) {
      params.keyword = keyword;
    }
    const { normalizeCollection } = getStoreActions().entities;
    const url = `/current/contributions`;

    return usersRequester.get(url, params).then(({ contributions }) => {
      const ids = normalizeCollection({ data: contributions, entityName: "contribution" });
      setUserContributionIDs({ user_id, ids });
    });
  }),
  setUserContributionIDs: action((state, { user_id, ids }) => {
    state.userContributionIDs[user_id] = ids;
  }),
};

const contributions = {
  ...attributes,
  ...computedProps,
  ...actions,
};

export default contributions;

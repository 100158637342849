import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import Switcher from "./Switcher";

const SocialNotifications = ({ socialName, enabled, toggle }) => (
  <Wrapper spacing="pb-6 mb-6+2">
    <Text spacing="mb-5" typography="heading/medium">
      {socialName}
    </Text>
    <Content flex>
      <Text>
        ThriveCoin can publicly @mention me on {socialName} when key events related to my in-app activity occur
      </Text>
      <Switcher checked={enabled} onChange={toggle} />
    </Content>
  </Wrapper>
);

const Wrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
`;

const Content = styled(Box)`
  gap: 48px;
  justify-content: space-between;

  @media ${breakpoints.tablet} {
    gap: 63px;
  }
  @media ${breakpoints.mobile} {
    gap: 17px;
  }
`;

export default SocialNotifications;

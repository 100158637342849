import TabWrapper from "@common/TabWrapper";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CommunityContext } from "../../../CommunityContext";
import useSort from "../../../hooks/useSort";
import { CONTRIBUTION_SORT_OPTIONS } from "../../../SortOptions";
import TableContributions from "./TableContributions";
import TableTab from "./TableTab";

const sortOrder = [
  "Newest to oldest",
  "Oldest to newest",
  "Reward (highest to lowest)",
  "Reward (lowest to highest)",
  "Contribution (A-Z)",
  "Contribution (Z-A)",
  "Contributor (A-Z)",
  "Contributor (Z-A)",
];

const sortArray = (array) => array.sort((a, b) => sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label));

const modalProps = {
  sortOptions: sortArray(CONTRIBUTION_SORT_OPTIONS),
  backLabel: "Back to Community Page",
  filterLabel: "Contributions",
};

const Contributions = () => {
  const navigate = useNavigate();
  const { communityId } = useContext(CommunityContext);
  const { getCommunityRewards } = useStoreActions((actions) => actions.rewards);
  const { communityRewards, communityRewardsPagination } = useStoreState((state) => state.rewards);
  const { isMobile } = useStoreState((state) => state.media);
  const { community } = useStoreState((state) => state.community);
  const sortProps = useSort(getCommunityRewards, "date", "community_id", communityId);
  const title = communityRewards && `Community contributions to ${community?.name}`;
  const hide_amounts = community.hide_amounts;

  const onCommunityClick = (e) => navigate(`/communities/${e.target.dataset.id}`);

  const onProfileClick = (e) => {
    const user_id = e.target.dataset.userid;
    navigate(`/${user_id}/profile`);
  };

  const rowProps = {
    onCommunityClick,
    hide_amounts,
    onProfileClick,
  };

  const justifyTitle = isMobile ? "space-between" : "center";

  return (
    <TableTab
      title={title}
      titleSearch
      justifyTitle={justifyTitle}
      sortOptions={modalProps.sortOptions}
      TableComponent={TableContributions}
      data={communityRewards}
      pagination={communityRewardsPagination}
      sortProps={sortProps}
      rowProps={rowProps}
      modalProps={modalProps}
      isContributions={true}
      WrapperKlass={TabWrapper}
    />
  );
};

export default Contributions;

function getFormData(form) {
  return Array.apply(0, form.elements)
    .filter((element) => element.name)
    .map(getKeyValue)
    .reduce(combine, {});
}

const getKeyValue = (element) => ({ [element.name]: element.type === "checkbox" ? element.checked : element.value });

const combine = (accumulator, next) => Object.assign(accumulator, next);

export default getFormData;

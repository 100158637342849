import { useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import HttpWrapper from "../../../redux/httpRequester";
import { getInstance } from "../../utils/web3wrapper";
import { walletOwnershipMessage } from "./useWeb3";

const requester = new HttpWrapper("additional_wallets");
const userRequester = new HttpWrapper("users");

const useAdditionalWalletAPI = () => {
  const [fetched, setFetched] = useState(false);
  const { getNonce } = useStoreActions((actions) => actions.authentication);
  const { getExtraWalletsRequest } = useStoreActions((state) => state.user);
  const _web3 = getInstance();

  const addWallet = async (address, web3 = _web3) => {
    const nonce = await getNonce(address);
    const message = walletOwnershipMessage(nonce, address);
    const signature = await web3.getUserSignature(message, address);

    return requester.post("", { additional_wallet: { address, signature } }).then(getExtraWalletsRequest);
  };

  const addCurrentAddress = () => {
    const address = _web3.selectedAddress();
    return addWallet(address);
  };

  const disconnectWallet = (id) => {
    return requester.delete(`/${id}`).then(getExtraWalletsRequest);
  };

  const walletExists = (address) => userRequester.post("/exists", { address });

  useEffect(() => {
    getExtraWalletsRequest().finally(() => setFetched(true));
  }, []);

  return { addWallet, disconnectWallet, walletExists, addCurrentAddress, fetched };
};

export default useAdditionalWalletAPI;

import { Box, Button, Input, Modal, MultiSelect, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
const allowedStatuses = ["active", "completed", "upcoming", "open_to_claim", "upcoming_raffle", "claim_opens_soon"];

const ExportDataModal = ({ hideModal, id }) => {
  const [loading, setLoading] = useState(false);
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const { filteredSeasons } = useStoreState((state) => state.season);
  const { getFilteredSeasons } = useStoreActions((actions) => actions.season);
  const { createDataExport } = useStoreActions((actions) => actions.community);

  const fetchData = () => getFilteredSeasons({ id, status: allowedStatuses }).finally(() => setLoading(false));

  const transformedData = [
    { value: "all", label: "All Seasons" },
    ...filteredSeasons.map((item) => ({
      value: item.id,
      label: item.label || `SEASON ${item.order_number}`,
      id: item.id,
    })),
  ];

  const [selectedOptions, setSelectedOptions] = useState([{ value: "all", label: "All Seasons", id: "" }]);

  const [email, setEmail] = useState("");
  const onItemClick = (option) => {
    if (option.value === "all") {
      setSelectedOptions([{ value: "all", label: "All", id: "" }]);
    } else {
      const allOptionIndex = selectedOptions.findIndex((i) => i.value === "all");
      const updatedSelectedOptions = [...selectedOptions];
      if (allOptionIndex !== -1) {
        updatedSelectedOptions.splice(allOptionIndex, 1);
      }
      if (updatedSelectedOptions.find((i) => i.value === option.value)) {
        setSelectedOptions(updatedSelectedOptions.filter((selectedOption) => selectedOption.value !== option.value));
      } else {
        setSelectedOptions([...updatedSelectedOptions, option]);
      }
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const getData = () => {
    const season_ids = selectedOptions.filter((season) => season.id !== "").map((season) => season.id);
    const config = { season_ids: season_ids };
    return {
      config: config,
      community_id: id,
      email: email,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data_export = getData();
    const data = { data_export };
    return createDataExport(data)
      .then(({ message }) => {
        showToast({
          content: message,
          type: "success",
          dismissTime: 5000,
        });
        hideModal();
      })
      .catch(onError);
  };

  const onError = ({ response }) => {
    const content = response.data.message;
    let message = content;
    if (typeof content === "object") {
      message = Object.values(content)[0];
    }
    dangerToast({ content: message, dismissTime: 5000 });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [id]);

  return (
    <Modal open onClose={hideModal} maxWidth={"679px"} paddingBody="64px 77px 62px 77px">
      <Box flex direction="column" alignItems="center" justifyContent="center">
        <Text typography="heading/medium" color="02Primary500" bold spacing="mb-4">
          Export Data (CSV)
        </Text>

        <StyledText flex direction="column" justifyContent="center" spacing="mb-5">
          <Text typography="body/medium" color="02Primary500">
            Please choose which data you wish to include in your CSV file.
          </Text>
          <Text typography="body/medium" color="02Primary500">
            An email will be send to you with a download link.
          </Text>
        </StyledText>
        <StyledMultiSelect>
          <MultiSelect
            hideX
            height="56px"
            options={transformedData}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            onItemClick={onItemClick}
          />
        </StyledMultiSelect>
        <StyledInput
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          bgColor="02PrimaryGrey50"
          required
        />

        <Box flex gap="16px" width="100%" spacing="mt-5">
          <Button onClick={hideModal} kind="outline" variant="white" borderRadius="50px" width="100%" modal>
            Cancel
          </Button>
          <Button borderRadius="50px" width="100%" onClick={handleSubmit} modal>
            Send Data Request
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const StyledText = styled(Box)`
  text-align: center;
`;

const StyledMultiSelect = styled.div`
  width: 100%;
  margin-bottom: 24px;
  & > div {
    width: 100%;
    background: ${({ theme }) => theme.colors["02PrimaryGrey50"]};

    & > div:first-child {
      color: ${({ theme }) => theme.colors["02Primary500"]};
      background: ${({ theme }) => theme.colors["02PrimaryGrey50"]};
      border: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey300"]};
      border-radius: 12px;
      &:hover {
         border-color: ${({ theme }) => theme.colors["inputHovers"]};
        outline: none;
      }

      svg {
        color: ${({ theme }) => theme.colors["02Primary500"]};
      }
    }

    & > div {
      border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
    }
    & > div:nth-child(2) {
      width: 100%;
      background: white;
      border: 1px solid transparent;
      max-height: 212px;
      margin-top: 4px;
      & > div {
        color: ${({ theme }) => theme.colors["02Primary500"]};
        button {
          border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
          &:hover {
            background: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
          }
        }
      }
    }
  }
`;

const StyledInput = styled(Input)`
  input {
    color: ${({ theme }) => theme.colors["02Primary500"]};
    border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
  }
`;
export default ExportDataModal;

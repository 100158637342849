import { Box } from "@thrivecoin/ui-components";
import { useEffect, useRef } from "react";
import PinBox from "./PinBox";

export const toNumber = (value) => value.replace(/[^0-9]/g, "");

const PinCode = ({ onCompleted, digits = 0, validationText }) => {
  const totalDigits = parseInt(digits);
  const references = useRef([]);
  const onComplete = () => {
    const values = references.current.map(({ value }) => value).filter(Boolean);
    if (values.length === totalDigits) {
      const value = values.join("");
      onCompleted(value);
    }
  };

  const elements = Array(totalDigits)
    .fill("")
    .map((_, index) => (
      <PinBox
        ref={(ref) => (references.current[index] = ref)}
        type="number"
        key={index}
        index={index}
        onComplete={onComplete}
        references={references}
        validationText={validationText}
      />
    ));

  useEffect(() => {
    const onPaste = (event) => {
      event.preventDefault();
      const value = toNumber(event.clipboardData.getData("text"));
      const totalElements = references.current.length;

      if (value.length >= totalElements) {
        references.current.forEach((element, index) => {
          element.value = value[index];
        });
        onComplete();
      }
      return false;
    };
    window.addEventListener("paste", onPaste);
    return () => window.removeEventListener("paste", onPaste);
  }, []);

  return (
    <Box flex justifyContent="center">
      {elements}
    </Box>
  );
};

export default PinCode;

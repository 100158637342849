import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const communityRequester = new HttpWrapper("communities");
const seasonRequester = new HttpWrapper("seasons");
const usersRequester = new HttpWrapper("users");

const replaceString = (str) => {
  return str.replace("community", "season").replace("Community", "Season");
};

const getKeyByRank = (type) => {
  let paginationKey = "communityContributorsPagination";
  let dataKey = "communityContributors";

  if (type === "season") {
    paginationKey = replaceString(paginationKey);
    dataKey = replaceString(dataKey);
  }

  return {
    paginationKey,
    dataKey,
  };
};

const DEFAULT_PAGINATION = {
  page: 1,
  total: 1,
  per_page: 20,
};

const attributes = {
  communityContributors: [],
  seasonContributors: [],
  topFiveCommunityContributors: [],
  topTenCommunityContributors: [],
  topFiveSeasonContributors: [],
  topTenSeasonContributors: [],
  contributorDetails: null,
  communityContributorsPagination: DEFAULT_PAGINATION,
  seasonContributorsPagination: DEFAULT_PAGINATION,
};

const actions = {
  getContributors: thunk(
    (
      { setContributors, loadMoreContributors, setContributorsPagination },
      {
        id,
        sort_type = "amount",
        sort_direction = "desc",
        keyword = "",
        page = 1,
        type = "community",
        filter_top_ten = null,
        filter_top_hundred = null,
        user_id = null,
      }
    ) => {
      const params = { sort_type, sort_direction, page, filter_top_ten, filter_top_hundred, user_id };
      const requester = type === "community" ? communityRequester : seasonRequester;

      if (keyword) {
        params.keyword = keyword;
      }
      return requester.get(`${id}/contributors`, params).then(({ contributors, pagination }) => {
        if (page > 1) {
          loadMoreContributors({ contributors, type });
        } else {
          setContributors({ contributors, type });
        }
        setContributorsPagination({ pagination, type });
      });
    }
  ),
  getPersonalStatus: thunk(({ setContributorDetails }, { id, type }) => {
    return usersRequester
      .get(`current/${type}/${id}/contributor_details`)
      .then(({ contributor_details }) => setContributorDetails(contributor_details));
  }),
  setContributors: action((state, { contributors, type }) => {
    const { dataKey } = getKeyByRank(type);
    state[dataKey] = contributors;
  }),
  loadMoreContributors: action((state, { contributors, type }) => {
    const { dataKey } = getKeyByRank(type);
    state[dataKey] = [...state[dataKey], ...contributors];
  }),
  setContributorsPagination: action((state, { pagination, type }) => {
    const { paginationKey } = getKeyByRank(type);
    state[paginationKey] = pagination;
  }),
  resetContributorDetails: action((state) => {
    state.contributorDetails = null;
  }),
  setContributorDetails: action((state, details) => {
    state.contributorDetails = details;
  }),
};

const contributors = {
  ...attributes,
  ...actions,
};

export default contributors;

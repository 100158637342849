import { Button, IconSvg } from "@thrivecoin/ui-components";
import styled from "styled-components";

const FilterButton = ({ onSearch, icon, active, activeColor }) => {
  return (
    <FilterButtonWrapper
      activeColor={active ? "02Primary0" : "01Primary700"}
      active={active}
      onClick={onSearch}
      kind="outline"
      variant={!active ? "button" : "error"}
      heightSize="44px"
      color="black"
      minWidth="44px"
    >
      <IconSvg icon={icon} width="12px" activeColor={activeColor} />
    </FilterButtonWrapper>
  );
};

const FilterButtonWrapper = styled(Button)`
  padding: 0;
  ${({ active }) => active && ` background-color: #E2857E !important; `}
  svg {
    ${({ theme, activeColor }) => `fill: ${theme.colors[activeColor]}; color: ${theme.colors[activeColor]};`}
    path {
      ${({ theme, activeColor }) => `fill: ${theme.colors[activeColor]}; color: ${theme.colors[activeColor]};`}
    }
  }
`;

FilterButton.defaultProps = {
  icon: "filter",
  activeColor: "#33343D",
};

export default FilterButton;
